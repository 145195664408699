import { Box } from '@mui/material'
import React from 'react'
import noData from "../assets/images/no-data.gif";



function PageNotFound() {
    return (
        <Box className='text-center no-data-found'>
            <img src={noData} />
            <h4 className='font-18 text-gray'>Page Not Found</h4></Box>
    )
}

export default PageNotFound
