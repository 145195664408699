import $ from 'jquery';

export default class API {
    constructor(APIURL) {
        this.APIURL = APIURL;
    }
    Call(data, methosName, callback) {
        var FullAPIURl = this.APIURL + methosName;
        if (data !== "") {
            $.ajax({
                type: "POST",
                url: FullAPIURl,
                data: data,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (msg) {
                    callback(msg);
                },
                error: function (e) {
                     try{
                        callback(e);
                     } catch(e){}
              }

            });
        }
        else {
            $.ajax({
                type: "POST",
                url: FullAPIURl,
                data: "{}",
                dataType: "json",
                success: function (msg) {
                    callback(msg);
                },
                error: function (e) {
                    console.log("Network Error !\n Please check your data connection ! Try again");
                }
            });
        }
    }

	
	 
  
}
