import React from 'react'
import Button from '@mui/material/Button';
import { Box } from '@mui/material'

import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import PeopleIcon from '@mui/icons-material/People';
import { Link } from 'react-router-dom';
import CreateRequestModal from './CreateRequestModal';


function StepRequests() {
  return (
    <Box>

      <Box className='d-flex justify-content-between flex-wrap my-2'>
        <h2 className='title'>3. Request information or documents</h2>
        <Box>
          <Button variant="contained" className='btn-blue me-3'>
            Load from Templates
          </Button>

          {/* <Button variant="contained" className='btn-blue'>
            Create Request
          </Button> */}
          <CreateRequestModal />

        </Box>
      </Box>

      <Box className="table-responsive">
        <table className='table table-striped'>
          <thead>
            <tr>
              <th>Title</th>
              <th className='text-end'>Type</th>
              <th className='text-end'>Contacts</th>
            </tr>
          </thead>
          <tbody>

            {Array(5).fill("").map(() => {
              return <>
                <tr>
                  <td>Title Name</td>
                  <td className='text-end'>
                    <VisibilityIcon />
                  </td>
                  <td className='text-end'>
                    <Button>
                      <PeopleIcon />
                    </Button>
                    <Button>
                      <EditIcon />
                    </Button>
                    <Button>
                      <FileCopyIcon />
                    </Button>
                    <Button>
                      <DeleteIcon />
                    </Button>
                  </td>
                </tr>
              </>
            })}
          </tbody>
        </table>
      </Box>
    </Box>

  )
}

export default StepRequests