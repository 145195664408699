import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleOpenContactModal } from '../redux/docuSlice';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Grid, Button, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CreateContect from '../pages/CreateContect';
import CreateCompany from '../pages/CreateCompany';
import { AddContect_Redux, Json_UpdateContact_Redux1, portal_Redux } from '../redux/ak_api_helper';
import { useLocalStorage } from '../custom-hook/useLocalStorage';
import { toast } from 'react-toastify';
import { Title } from '@mui/icons-material';
import { getClientmanagerobj_Redux } from '../redux/akSlice';

export default function ContactModal({ open }) {
  const dispatch = useDispatch();
  const { allContact } = useSelector(state => state.ak);
  const { getContactEmailID } = useSelector(state => state.ak);
  const { bussiness, sources, mangers, companyStatus } = useSelector((state) => state.ak);
  const { roles } = useSelector(state => state.ak);
  const reqObj = useLocalStorage('required');

  const [formData, setFormData] = React.useState({
    'Add1': "",
    'Add2': "",
    'Add3': "",
    'AssignedManager': 1,
    'CActive': "Yes",
    'CCode': "",
    // CCode:'',
    // email:'',
    'Country': "",
    'Email': reqObj.Email,
    'FirstName': "",
    'LastName': "",
    'ManagerName': "",
    'Mobile': "",
    'PostCode': "",
    'email':'',
    'Role': "",
    'Salutation': "",
    'Tel': "",
    'Town': "",
    'accid': reqObj.agrno,
    'agrno': reqObj.agrno,
    'greeting': "",
    'maincontact': false,
    'note': "",
    'password': reqObj.password,
    'Company':"",
    'Title': "",
  });
  const [getEmailContact, setgetEmailContact] = React.useState({});
  const isEmpty = Object.keys(getEmailContact).length === 0; 
   const [manager, setManager] = useState(null);
  const [rolestest, setrolestest] = useState(null);
  // const [sources1, setsources1] = useState(null);
  const [contactStatus, setcontactStatus] = useState(null);
  // console.log("allContact",getEmailContact);
  useEffect(() => {
    const foundEmailContact = allContact.Table?.find(
      (EmailContact) => EmailContact["E-Mail"] == getContactEmailID
    );
    if (foundEmailContact) {
      setgetEmailContact(foundEmailContact || {});

      const foundManager = mangers.find(
        (manager) =>
          manager.UserId ==
          (foundEmailContact && foundEmailContact["Assigned Manager"]
            ? foundEmailContact["Assigned Manager"]
            : " ")
      );
      // Set the manager object in the state
      setManager(foundManager || { UserName: "" });

      const foundsetrolestest = roles.find(
        (setrolestest) =>
          setrolestest.RoleName ==
          (foundEmailContact && foundEmailContact.Role
            ? foundEmailContact.Role
            : " ")
      );
      // Set the setrolestest object in the state
      setcontactStatus(foundsetrolestest || { RoleName: "" });

      const foundcontactStatus = companyStatus.find(
        (contactStatus) =>
          contactStatus.StatusName ==
          (foundEmailContact && foundEmailContact.StatusName
            ? foundEmailContact.StatusName
            : " ")
      );
      // Set the contactStatus object in the state
      setcontactStatus(foundcontactStatus || { StatusName: "" });

      try {
        if (getEmailContact) {
          setFormData({
            Add1: foundEmailContact["Address 1"] || "",
            Add2: foundEmailContact["Address 2"] || "",
            Add3: foundEmailContact["Address 3"] || "",
            AssignedManager: foundEmailContact["Assigned Manager"] || 1,
            CActive: foundEmailContact.Active || "Yes",
            CCode: foundEmailContact.CCode || "",
            Country: foundEmailContact.Country || "",
            Email: reqObj.Email,
            FirstName: foundEmailContact["First Name"] || "",
            LastName: foundEmailContact["Last Name"] || "",
            ManagerName: "",
            Mobile: foundEmailContact.Mobile || "",
            PostCode: foundEmailContact.Postcode || "",
            email: foundEmailContact["E-Mail"] || "",
            Role: foundEmailContact.Role || "",
            Salutation: contactStatus.StatusName || "",
            Tel: foundEmailContact.Tel || "",
            Town: foundEmailContact.Town || "",
            accid: foundEmailContact.OriginatorNo || reqObj.agrno,
            agrno: foundEmailContact.OriginatorNo || reqObj.agrno,
            greeting: foundEmailContact.Greeting || "",
            maincontact: foundEmailContact["Main Contact"] || false,
            note: foundEmailContact.Note || "",
            password: reqObj.password,
            Company: foundEmailContact["Company Name"] || "",
            Title: foundEmailContact.Title || "",
          });
        }
      } catch (err) {
        console.log("trycatch", err);
      }
    }else{
      console.log("No contact found with the given email ID.");
    }
  }, [allContact, getContactEmailID]);
  
  const [openNewModal, setOpenNewModal] = React.useState(false);
  const handleClose = () => {
    dispatch(handleOpenContactModal(false));
  };

  const handleOpenNewModal = () => {
    setOpenNewModal(true);
  };

  const handleCloseNewModal = () => {
    setOpenNewModal(false);
  };

  
 
 
  const obj1={ 
    accid: reqObj.agrno, 
    email: reqObj.Email, 
    password: reqObj.password, 
    PresetMemorableData: true, 
    IssueReminders: true, 
    ExcludeMessageLink: true, 
    KeepSignedIn: false, 
    AllowUpload: false, 
    ChangeProfile: true, 
    LoggedIn: false, 
    Blocked: false, 
    emailAddress: formData.email, 
    // ccode: clientIddata ? clientIddata : "", 
    ccode :formData.CCode ? formData.CCode :'',
    clientName:formData.Company,
    // clientName: clientNames ? clientNames : "", 
    
  }

   let contactData = {
        agrno: reqObj.agrno,
        Email: reqObj.Email,
        password: reqObj.password,
        FirstName: formData.FirstName
          ? formData.FirstName
          : "",
        LastName: formData.LastName ? formData.LastName : "",
        Add1: formData.Add1
          ? formData.Add1
          : "",
        Add2: formData.Add2
          ? formData.Add2
          : "",
        Add3: formData.Add3
          ? formData.Add3
          : "",
        Town: formData.Town
          ? formData.Town
          : "",
        PostCode: formData.PostCode
          ? formData.PostCode
          : "",
        Country: formData.Country
          ? formData.Country
          : "United Kingdom",
        ManagerName:
          formData.FirstName + " " + formData.LastName,
        Role: formData.Role ? formData.Role : "",
        Tel: formData.Tel
          ? formData.Tel
          : "",
        Mobile: formData.Mobile
          ? formData.Mobile
          : "",
        greeting: formData.greeting
          ? formData.greeting
          : "",
        Contactemail: formData.email ? formData.email : "",
        note: formData.note ? formData.note : "",
        emailupdate: formData.email ? formData.email : "",
        CActive: formData.CActive === false ? "Yes" : "Yes",
        AssignedManager: formData.AssignedManager
          ? formData.AssignedManager
          : -1,
        maincontact: formData.maincontact
          ? formData.maincontact
          : false,
        CCode:getEmailContact.OriginatorNo ?getEmailContact.OriginatorNo : "-1",
        Salutation: formData.Title ? formData.Title : "",
        accid: reqObj.agrno,
      };
      const handleUpdateContact = () => {
        dispatch(Json_UpdateContact_Redux1(contactData));
       };

  // const handleCreateContact = () => {
  //   dispatch(AddContect_Redux(formData)).then(() => {
  //     // console.log("fghgfhgfhgfhgh",obj1);
  //     // dispatch(portal_Redux(obj1));
  //     toast.success("Contact added successfully"); 
  //     setTimeout(() => {
  //       dispatch(portal_Redux(obj1)).then(() => {
  //         toast.success("Portal Create  successfully"); 
  //       });

  //     }, 3000);
    
  //     handleClose();
  //   }).catch(error => {
  //     // Handle any errors if the AddContect_Redux action fails
  //     console.error('Failed to add contact:', error);
  //     toast.error("Contact added successfully"); 
  //   });
  // };
  

  const handleCreateContact = () => {
    // Check if mandatory fields are filled
    if (!formData.FirstName || !formData.LastName || !formData.Email || !formData.Company) {
      toast.error("Please fill in all required fields ");
      return;
    }
  
    // Proceed to create contact if validation passes
    dispatch(AddContect_Redux(formData))
      .then(() => {
        toast.success("Contact added successfully"); 
        setTimeout(() => {
          dispatch(portal_Redux(obj1)).then(() => {
          //  toast.success("Portal Create successfully"); //when uncomments this line when proper response comes from server .
          });
        }, 3000);
        handleClose();
      })
      .catch(error => {
        console.error('Failed to add contact:', error);
        toast.error("Failed to add contact");
      });
  };
  

  
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='custom-modal'
      >
        <Box className="d-flex align-items-center justify-content-between modal-head">
          <Box className="clearfix">
            <DialogTitle>Create Contact</DialogTitle>
          </Box>
          <Button onClick={handleClose} autoFocus>
            <CloseIcon />
          </Button>
        </Box>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              <CreateContect formData={formData} setformData={setFormData} manager={manager} rolestest={rolestest} />
          </DialogContentText>

          <DialogActions className='pt-4 px-0'>
            <Button onClick={handleClose} className='btn-red' variant="outlined">Cancel</Button>
            {/* <Button onClick={handleCreateContact} autoFocus variant="outlined" className='btn-blue'>
              Create Contact
            </Button> */}
            {isEmpty ? (
        <Button 
          onClick={handleCreateContact}
          autoFocus 
          variant="outlined" 
          className='btn-blue'>
          Create Contact
        </Button>
      ) : (
        <Button 
          onClick={handleUpdateContact}
          autoFocus 
          variant="outlined" 
          className='btn-blue'>
          Update Contact
        </Button>
      )}
          </DialogActions>
        </DialogContent>
      </Dialog>

      {/* <Dialog
        open={openNewModal}
        onClose={handleCloseNewModal}
        aria-labelledby="new-dialog-title"
        aria-describedby="new-dialog-description"
      >
        <Box className="d-flex align-items-center justify-content-between modal-head">
          <Box className="clearfix">
            <DialogTitle id="new-dialog-title">New Modal Title</DialogTitle>
          </Box>
          <Button onClick={handleCloseNewModal} autoFocus>
            <CloseIcon />
          </Button>
        </Box>

        <DialogContent>
          <DialogContentText id="new-dialog-description">
            <Grid sx={{ marginLeft: '100px' }}>

           <Typography>Viwe Contects</Typography>
            </Grid>
          </DialogContentText>

          <DialogActions className='pt-4 px-0'>
            <Button onClick={handleCloseNewModal} className='btn-blue' variant="outlined">Close</Button>
          </DialogActions>
        </DialogContent>
      </Dialog> */}
    </div>
  );
}
