import React, { useEffect } from 'react'
import { GetClientContactDetailasProfile, GetPortalContactDetailsByEmail } from '../../redux/ak_api_helper'
import { useDispatch } from 'react-redux'

export default function ClientHome() {
  
  const dispatch = useDispatch();
  React.useEffect  (() =>{
    const obj1 = {
      accId: localStorage.getItem('accIdClient') ? localStorage.getItem('accIdClient') : '',
      username: localStorage.getItem('EmailClient') ? localStorage.getItem('EmailClient') : '',
      sPassword: localStorage.getItem('passwordClient') ? localStorage.getItem('passwordClient')  : ''
    }

    dispatch(GetPortalContactDetailsByEmail(obj1))
    dispatch(GetClientContactDetailasProfile(obj1))
  },[])

  return (
    <div><h1>Home Page</h1></div>
  )
}
