// import React from 'react';
// import Breadcrumbs from '@mui/material/Breadcrumbs';
// import Typography from '@mui/material/Typography';
// import Link from '@mui/material/Link';
// import Stack from '@mui/material/Stack';
// import NavigateNextIcon from '@mui/icons-material/NavigateNext';

// function handleClick(event) {
//     event.preventDefault();
//     console.info('You clicked a breadcrumb.');
// }

// function CustomBreadCrumbs() {

//     const breadcrumbs = [
//         <Link underline="hover" key="1" color="inherit" href="/" onClick={handleClick}>
//             Invites
//         </Link>,
//         <Link
//             underline="hover"
//             key="2"
//             color="inherit"
//             href="/material-ui/getting-started/installation/"
//             onClick={handleClick}
//         >
//             Create Invite
//         </Link>,
//         <Typography key="3" color="text.primary">
//             Breadcrumb
//         </Typography>,
//     ];


//     return (
//         <Breadcrumbs separator="-" aria-label="breadcrumb">
//             {breadcrumbs}
//         </Breadcrumbs>
//     )
// }

// export default CustomBreadCrumbs

import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
}

function CustomBreadCrumbs({ items }) {

    const breadcrumbs = items?.map((item, index) => {
        if (item.href) {
            return (
                <Link
                    underline="hover"
                    key={index}
                    color="inherit"
                    href={item.href}
                    onClick={handleClick}
                >
                    {item.label}
                </Link>
            );
        } else {
            return (
                <Typography key={index} color="text.primary">
                    {item.label}
                </Typography>
            );
        }
    });

    return (
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {breadcrumbs}
        </Breadcrumbs>
    );
}

export default CustomBreadCrumbs;
