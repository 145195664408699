import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ad_GetAllContacts:[],
  ad_GetAllClientList:[]
};

const adityaReducer = createSlice({
  name: "ak_Slice",
  initialState,
  reducers:{
    GetAllContacts_reducer: (state, action) => {      
      state.ad_GetAllContacts= action.payload     
    },
    GetAllClientList_reducer: (state, action) => {      
      state.ad_GetAllClientList= action.payload     
    },
  }
});

export const {
  GetAllContacts_reducer,
  GetAllClientList_reducer
} = adityaReducer.actions;
export default adityaReducer.reducer;
