import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';

const initialState = {
    openInviteModal: false,
    openCreateContactModal: false,
    openContactModal: false,
    contactModalData: {},
    test_api_helper: [],
    docuRequestDataClient: [],
    docuRequestDataClientLoding: true,
    docuRequestDataClientAll: [],
    AddDSRequest_Response: [],
    GetForwardUserList: [],
    FolderDataList: [],
    PortalDocReqTemplatesLiset: [],
    getAllFoldersList: [],
    getAllContactsList: [],
    setClientsByFolder: [],
    GetClientsByFolderList: [],
    GetClientCardDetailsList: [],
    PortalRequestDocuments: [],
    documentRequests: [],
    PortalUsersRequestedDocs: [],
    PortalUsersRequestedDocsLoding: true,
    ApproveRequestedDocument: [],
    DisapproveRequestedDocument: [],
    GetSectionsByFolderList: [],
    ComposePortalMgsObj: [],
    ExplorerSearchDocList: [],
    setRequestedDocumentAsByteArray: [],
    AttachmentTab: [],
    RequestDocumnets: {},
    GetUsersEmailSignature: "",
    ActionDate: null,
    ExpireDate: null,
    PortalSectionData: [],
    PortalToData: [],
    PortalCCData: [],
    Approval: { id: 1, "name": "Yes" },
    Priority: { id: 1, "name": "High" },
    GUID: "",
    AccountUsersContactsByClient: [],
    FilterRequest: [],
    fetchDocumentRequest: [],
    getAllSentMessages: [],
    getAllSentMessagesLoding: true,
    getAllReceivedMessages: [],
    getAllReceivedMessagesLoding: true,
    getRetractedMessages: [],
    GetMessageAttachments: [],
    getRetractedMessagesLoding: true,
    PortalUsersRequestedDocs2: [],
    NewRequest: false,
    NewMessage: false,
};

const DocuSlice = createSlice({
    name: 'Docu_Request',
    initialState,
    reducers: {
        handleOpenInviteModal: (state, action) => {
            state.openInviteModal = action.payload;
        },
        handleOpenCreateContactModal: (state, action) => {
            state.openCreateContactModal = action.payload;
        },
        setTestApiHelper: (state, action) => {
            state.test_api_helper = action.payload;
        },
        setContactModalData: (state, action) => {
            state.contactModalData = action.payload;
        },
        setAddDSRequest_Redux: (state, action) => {
            state.AddDSRequest_Response = action.payload;
        },
        setGetForwardUserList_Redux: (state, action) => {
            state.GetForwardUserList = action.payload;
        },
        setFolderData_Redux: (state, action) => {
            state.FolderDataList = action.payload;
        },
        setPortalDocReqTemplatesLiset_Redux: (state, action) => {
            state.PortalDocReqTemplatesLiset = action.payload;
        },
        handleOpenContactModal: (state, action) => {
            state.openContactModal = action.payload;
        },
        fetchdocuRequestDataClient: (state, action) => {
            state.docuRequestDataClient = action.payload;
            state.docuRequestDataClientLoding = false;
        },
        fetchdocuRequestDataClientAll: (state, action) => {
            state.docuRequestDataClientAll = action.payload;
            state.docuRequestDataClientLoding = false;
        },

        setGetAllFolders_Redux: (state, action) => {
            state.getAllFoldersList = action.payload;
        },
        setGetClientsByFolder_Redux: (state, action) => {
            state.GetClientsByFolderList = action.payload;
        },
        setGetClientCardDetails_Redux: (state, action) => {
            state.GetClientCardDetailsList = action.payload;
        },
        setAllContactsRedux: (state, action) => {
            state.getAllContactsList = action.payload;
        },
        setClientsByFolder_Redux: (state, action) => {
            state.setClientsByFolder = action.payload;
        },
        setPortalRequestDocuments_Redux: (state, action) => {
            state.PortalRequestDocuments = action.payload;
        },
        setDocumentRequests_Redux: (state, action) => {

            state.documentRequests = action.payload;
        },
        setPortalUsersRequestedDocs_Redux: (state, action) => {
            state.PortalUsersRequestedDocs = action.payload;
            state.PortalUsersRequestedDocsLoding = false;
        },
        setGetSectionsByFolder_Redux: (state, action) => {
            state.GetSectionsByFolderList = action.payload;
        },
        setJson_ExplorerSearchDoc_Redux: (state, action) => {
            state.ExplorerSearchDocList = action.payload;
        },


        setComposePortalMgsObj_Redux: (state, action) => {
            state.ComposePortalMgsObj = action.payload;
        },

        setApproveRequestedDocument_Redux: (state, action) => {
            state.ApproveRequestedDocument = action.payload;
        },
        setDisapproveRequestedDocument_Redux: (state, action) => {
            state.DisapproveRequestedDocument = action.payload;
        },
        setRequestedDocumentAsByteArray_Redux: (state, action) => {
            state.setRequestedDocumentAsByteArray = action.payload;
        },
        setAttachementTab_Redux: (state, action) => {
            state.AttachmentTab = action.payload;
        },
        setRequestDocument_Redux: (state, action) => {
            state.RequestDocumnets = action.payload;
        },

        setActionDate_Redux: (state, action) => {
            state.ActionDate = action.payload;
        },
        setExpireDate_Redux: (state, action) => {
            state.ExpireDate = action.payload;
        },
        setPortalSectionData: (state, action) => {
            state.PortalSectionData = action.payload;
        },
        setPortalToData: (state, action) => {
            state.PortalToData = action.payload;
        },
        setPortalCCData: (state, action) => {
            state.PortalCCData = action.payload;
        },
        setPriority: (state, action) => {
            state.Priority = action.payload;
        },
        setApproval: (state, action) => {
            state.Approval = action.payload;
        },
        setGetUsersEmailSignature_Redux: (state, action) => {
            state.GetUsersEmailSignature = action.payload;
        },
        setGUID_Redux: (state, action) => {
            state.GUID = action.payload;
        },
        setGetAccountUsersContactsByClient_Redux: (state, action) => {
            state.AccountUsersContactsByClient = action.payload;
        },
        setFilterRequest_Redux: (state, action) => {
            state.FilterRequest = action.payload;
        },
        setfetchDocumentRequest: (state, action) => {
            state.fetchDocumentRequest = action.payload;
        },
        setAllSentMessages_Redux: (state, action) => {
            state.getAllSentMessages = action.payload;
            state.getAllSentMessagesLoding = false;
        },
        setAllReceivedMessages_Redux: (state, action) => {
            state.getAllReceivedMessages = action.payload;
            state.getAllReceivedMessagesLoding = false;
        },
        setRetractedMessages_Json_Redux: (state, action) => {
            state.getRetractedMessages = action.payload;
            state.getRetractedMessagesLoding = false;
        },
        setGetMessageAttachments_Redux: (state, action) => {
            state.GetMessageAttachments = action.payload;
        },
        setPortalUsersRequestedDocs2_Redux: (state, action) => {
            state.PortalUsersRequestedDocs2 = action.payload;
            state.PortalUsersRequestedDocsLoding = false;
        },
        setNewRequest_Redux: (state, action) => {
            state.NewRequest = action.payload;
        },
        setNewMessage_Redux: (state, action) => {
            state.NewMessage = action.payload;
        },

    }
});

export const { handleOpenInviteModal, handleOpenContactModal, handleOpenCreateContactModal,
    setContactModalData, setTestApiHelper, setAddDSRequest_Redux,
    setGetForwardUserList_Redux, setFolderData_Redux, setPortalDocReqTemplatesLiset_Redux,
    setGetAllFolders_Redux, setAllContactsRedux, setClientsByFolder_Redux,
    fetchdocuRequestDataClient, setGetClientsByFolder_Redux, setGetClientCardDetails_Redux,
    setGetSectionsByFolder_Redux,
    setPortalRequestDocuments_Redux, setDocumentRequests_Redux, setPortalUsersRequestedDocs_Redux,
    fetchdocuRequestDataClientAll,
    setComposePortalMgsObj_Redux,
    setJson_ExplorerSearchDoc_Redux,
    setApproveRequestedDocument_Redux,
    setDisapproveRequestedDocument_Redux,
    setAttachementTab_Redux,
    setRequestDocument_Redux,
    setfetchDocumentRequest,
    setGetUsersEmailSignature_Redux,
    setActionDate_Redux,
    setExpireDate_Redux,
    setRequestedDocumentAsByteArray_Redux,
    setPortalSectionData,
    setPortalToData,
    setPortalCCData,
    setApproval,
    setPriority,
    setGUID_Redux,
    setGetAccountUsersContactsByClient_Redux,
    setFilterRequest_Redux,
    setAllSentMessages_Redux,
    setAllReceivedMessages_Redux,
    setRetractedMessages_Json_Redux,
    setGetMessageAttachments_Redux,
    setPortalUsersRequestedDocs2_Redux,
    setNewRequest_Redux,
    setNewMessage_Redux,
} = DocuSlice.actions

export default DocuSlice.reducer