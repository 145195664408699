import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Grid,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import DocuTextField from "../../../cmp-utils/DocuTextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import LanguageIcon from "@mui/icons-material/Language";
import HttpsIcon from "@mui/icons-material/Https";
import EmailIcon from "@mui/icons-material/Email";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { toast } from "react-toastify";
import { Json_GetAgreementListDetails_Redux1 } from "../../../redux/ak_api_helper";
import { useDispatch, useSelector } from "react-redux";

function ComponySetting({ onCompanyDataChange }) {
  const [value, setValue] = React.useState("1");
  const [avatarSrc, setAvatarSrc] = useState("/static/images/avatar/1.jpg");
  const dispatch = useDispatch();
  const { AgreementListDetails } = useSelector((state) => state.ak);
  console.log("Aggrement List Detailas", AgreementListDetails);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const selecFiles = () =>{

  // }

  useEffect(() => {
    dispatch(Json_GetAgreementListDetails_Redux1(obj1));
  }, []);

  useEffect(() => {
    if (AgreementListDetails) {
      return onCompanyDataChange(AgreementListDetails.vAgreementNo);
    }
  }, [AgreementListDetails, onCompanyDataChange]);

  const obj1 = {
    agrno: localStorage.getItem("agrno")
      ? localStorage.getItem("agrno")
      : "0003",
    Email: localStorage.getItem("Email")
      ? localStorage.getItem("Email")
      : "patrick@docusoft.net",
    password: localStorage.getItem("password")
      ? localStorage.getItem("password")
      : "UGF0cmljazEyMy4=",
  };

  const selecFiles = (event) => {
    const file = event.target.files[0];

    console.log("FIle Sizes", file.size);
    if (file && file.size <= 2097152) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setAvatarSrc(e.target.result);
      };

      reader.readAsDataURL(file);
    } else {
      toast.error("Please select an image with Size is less than 2Mb");
    }
  };
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  {
    console.log("send reminver", AgreementListDetails.ReminderInDays);
  }

  const base64String = AgreementListDetails.Logo; // Replace with your Base64 string
  const format = "png";

  return (
    <>
      <Box className="white-box">
        {/* <Grid container rowSpacing={0} columnSpacing={5}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>

        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
          
        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
          
        </Grid>
      </Grid> */}

        <Box
          sx={{ width: "100%", typography: "body1" }}
          className="custom-tabs"
        >
          <TabContext value={value}>
            <Box>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                className="flex-wrap-tabs"
              >
                <Tab label="E-Mail-Settings" value="1" />
                <Tab label="Terms And Conditions" value="2" />
              </TabList>
            </Box>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <hr />
            </Grid>
            <Box
            // sx={{
            //   border: "1px solid #ccc",
            //   marginTop: "10px",
            //   background:
            //     value == 1 || value == 3 ? "#fff" : "#f7f7f7#",
            //   height: "110px",
            //   d isplay: "flex",
            //   justifyContent: "center",
            //   alignItems: "center",
            // }}
            >
              <TabPanel value="1">
                <>
                  <Grid container rowSpacing={0} columnSpacing={5}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                      <Box>
                        <DocuTextField
                          label={"Portal Id"}
                          size="small"
                          fullWith
                        />
                        <DocuTextField
                          type={"text"}
                          label={"Name"}
                          size="small"
                          fullWith
                          fieldValue={
                            AgreementListDetails.vCustomerName
                              ? AgreementListDetails.vCustomerName
                              : ""
                          }
                        />
                        <DocuTextField
                          type={"text"}
                          label={"WebSite"}
                          size="small"
                          fullWith
                          fieldValue={
                            AgreementListDetails.Website
                              ? AgreementListDetails.Website
                              : ""
                          }
                        />
                      </Box>

                      <Box>
                        <Typography
                          className="font-16 poppins-medium text-uppercase mt-2"
                          // variant="Body2"
                        >
                          Ftp Mode
                        </Typography>

                        <hr />

                        <FormControl>
                          {/* <FormLabel id="demo-row-radio-buttons-group-label">
                            Gender
                          </FormLabel> */}
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              value="Active"
                              control={
                                <Radio
                                  checked={
                                    AgreementListDetails.PassiveMode !== true
                                      ? true
                                      : false
                                  }
                                />
                              }
                              label="Active"
                            />

                            <FormControlLabel
                              control={
                                <Radio
                                  checked={
                                    AgreementListDetails.PassiveMode === true
                                      ? true
                                      : false
                                  }
                                />
                              }
                              label="Passive"
                              value="Passive"
                            />
                          </RadioGroup>
                        </FormControl>
                        <hr />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                      <Box className=" mt-2">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                AgreementListDetails.ApplyApproval == true
                                  ? true
                                  : false
                              }
                            />
                          }
                          label="Generate Approval Code"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                AgreementListDetails.ForApprovalTick == true
                                  ? true
                                  : false
                              }
                            />
                          }
                          label="Always For Approval"
                        />

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                AgreementListDetails.PublishFromUser == true
                                  ? true
                                  : false
                              }
                            />
                          }
                          label="Always Publish From User"
                        />

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                AgreementListDetails.ShowAttachments == true
                                  ? true
                                  : false
                              }
                            />
                          }
                          label="Show Attachment On Login"
                        />
                      </Box>

                      <DocuTextField
                        size={"small"}
                        fieldLabel={"number"}
                        label={"Send Reminders"}
                        fieldValue={
                          AgreementListDetails.ReminderInDays
                            ? AgreementListDetails.ReminderInDays
                            : ""
                        }

                        // defaultValue={AgreementListDetails.ReminderInDays}
                        // defaultValue={'12'}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                      <span className=" Request_Detailas mt-1">
                        Logo Size Limit 2 Mb
                      </span>
                      <Box
                        sx={{
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                          marginTop: "10px",
                          height: "140px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {/* <Avatar
                          alt="Mr."
                          src={avatarSrc}
                          sx={{ width: 80, height: 80, cursor: "pointer" }}
                          // onClick={handleAvatarClick}
                        /> */}

                        <img
                          src={`data:image/${format};base64,${base64String}`}
                          alt="Base64 Image"
                          style={{ width: "100%", height: "100%" }}
                          // alt="avatar"
                        />
                      </Box>

                      <Box className=" d-flex justify-content-end mt-3">
                        <Button
                          component="label"
                          role={undefined}
                          variant="contained"
                          className="btn-blue"
                          size="small"
                          tabIndex={-1}
                          startIcon={<CloudUploadIcon />}
                          sx={{ mr: 2 }}
                        >
                          Upload Logo Image
                          <VisuallyHiddenInput
                            type="file"
                            onChange={selecFiles}
                          />
                        </Button>

                        <Button
                          component="label"
                          role={undefined}
                          variant="contained"
                          className="btn-blue"
                          size="small"
                          tabIndex={-1}
                          // startIcon={<CloudUploadIcon />}
                        >
                          Save
                        </Button>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xll={12}>
                      <Typography
                        className="font-18 poppins-medium mt-5"
                        variant="Body2"
                      >
                        Disclaimer
                      </Typography>

                      <Box
                        sx={{
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                          marginTop: "10px",
                          height: "150px",
                          justifyContent: "center",
                          alignItems: "center",
                          overflow: "auto",
                        }}
                        className=" mt-4"
                      >
                        <p className=" p-3">
                          {AgreementListDetails.vDisclaimer}
                        </p>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              </TabPanel>

              <TabPanel value="2">
                <Typography className="font-18 poppins-medium" variant="Body2">
                  Your Gretting
                </Typography>

                <Grid container>
                  <Grid item sm={12}>
                    <Box
                      sx={{
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        marginTop: "10px",
                        height: "100px",
                        justifyContent: "center",
                        alignItems: "center",
                        overflow: "auto",
                      }}
                      className=" mt-3"
                    >
                      <p className=" p-3">
                        {AgreementListDetails.vEmailHeader}
                      </p>
                    </Box>

                    <Box className="d-flex justify-content-between flex-wrap align-items-center px-4 mt-3">
                      <Box className="text-center">
                        <div className="m-auto d-inline-block user-profile-img">
                          <Typography className="font-16 poppins-medium">
                            <EmailIcon className="font-20 me-2 text-blue" />{" "}
                            Email Id
                          </Typography>
                          <Typography className="font-12 text-gray">
                            {/* Client@Domain.net */}
                          </Typography>
                        </div>
                      </Box>

                      <Box className="text-center">
                        <div className="m-auto d-inline-block user-profile-img">
                          <Typography className="font-16 poppins-medium">
                            <LanguageIcon className="font-20 me-2 text-blue" />{" "}
                            Portal Site
                          </Typography>
                          <Typography className="font-12 text-gray">
                            {/* www.docusoftcloud.net/Code */}
                          </Typography>
                        </div>
                      </Box>

                      <Box className="text-center">
                        <div className="m-auto d-inline-block user-profile-img">
                          <Typography className="font-16 poppins-medium">
                            <HttpsIcon className="font-20 me-2 text-blue" />{" "}
                            Password
                          </Typography>
                          <Typography className="font-12 text-gray">
                            {/* docusoftcloud123. */}
                          </Typography>
                        </div>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        marginTop: "10px",
                        height: "150px",
                        justifyContent: "center",
                        alignItems: "center",
                        overflow: "auto",
                      }}
                      className=" mt-4"
                    >
                      <p className=" p-3">
                        {AgreementListDetails.vEmailFooter}
                      </p>
                    </Box>

                    <Box className=" d-flex justify-content-end mt-3">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              AgreementListDetails.PublishEmailCopy == true
                                ? AgreementListDetails.PublishEmailCopy == true
                                : ""
                            }
                          />
                        }
                        label="Send Email-Copy To Publisher"
                      />

                      <Button
                        component="label"
                        role={undefined}
                        variant="contained"
                        className="btn-blue"
                        size="small"
                        tabIndex={-1}
                        // startIcon={<CloudUploadIcon />}>
                        sx={{ height: "30px" }}
                      >
                        Update
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </TabPanel>
            </Box>
          </TabContext>
        </Box>
      </Box>
    </>
  );
}

export default ComponySetting;
