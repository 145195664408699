import { Box, Pagination } from "@mui/material";
import React from "react";
import PortalMessagesFilters from "./PortalMessagesFilters";

import CustomePortalMessageTable from "../../../components/CustomePortalMessageTable";
import { useDispatch, useSelector } from "react-redux";
import { GetAllSentMessages_Json_Redux } from "../../../redux/api_helper";
import { useLocalStorage } from "../../../custom-hook/useLocalStorage";
import moment from "moment";
import NoData from "../../../components/NoData";
import CustomLoader from "../../../components/CustomLoader";
function SentMessages({ value }) {
  let dispatch = useDispatch();
  const { getAllSentMessages, getAllSentMessagesLoding } = useSelector(
    (state) => state.docu
  );

  let objReq = useLocalStorage("accid");

  let sentObj = {
    accid: objReq.accid,
    email: objReq.email,
    password: objReq.password,
    ccode: "",
    emailAddress: "",
  };
  React.useEffect(() => {
    dispatch(
      GetAllSentMessages_Json_Redux(sentObj, function (res) {
      })
    );
  }, [dispatch]);

  const TableHeder = [
    "Subject",
    "Recipient",
    "Approval Requested",
    "Issued On",
    "Due Date",
    "Status",
  ];

  // Create a JSON object from the array using the map method
  const jsonObjectArray = getAllSentMessages.map((item) => {
    let approvedDate = "Not available";
    let viewedDate = "Not available";
    if (item["Date Approved"]) {
      approvedDate = moment(item["Date Approved"]).format("DD/MM/yyyy");
    }
    if (item.Viewed) {
      viewedDate = moment(item.Viewed).format("DD/MM/yyyy");
    }
    let viewStatus = "Pending";
    if (item.Viewed == "") {
      viewStatus = "Unread";
    } else if (item.Viewed != "" && item.Approved == "Yes") {
      viewStatus = "Approved";
    } else if (item.Viewed != "" && item.Approved == "No") {
      viewStatus = "Disapproved";
    } else {
      viewStatus = "Pending";
    }
    let objectAll = {
      Subject: item?.Subject ? item.Subject : "Not available",
      Recipient: item["Client Name"] ? item["Client Name"] : "Not available",
      ApprovalRequested: item["To be Approved"]
        ? item["To be Approved"]
        : "Not available",
      IssuedOn: item.Issued
        ? moment(item.Issued).format("DD/MM/yyyy")
        : "Not available",
      DueDate: item["Due Date"]
        ? moment(item["Due Date"]).format("DD/MM/yyyy")
        : "Not available",
      Status: viewStatus,
      MessageNo:item["Message No"] ? item["Message No"] : 'Not available',

      MessageID:item["Message ID"] ? item["Message ID"] : 'Not available',
      ReferenceName: item["Client Name"] ? item["Client Name"] : 'Not available',
      History: [
        {
          date: "Section",
          customerId: ": " + item.Section ? item.Section : "Not available",
        },
        {
          date: "Date Approved",
          customerId: ": " + approvedDate,
        },
        {
          date: "Viewed On",
          customerId: ": " + viewedDate,
        },
        {
          date: "Email ID",
          customerId: ": " + item.EMailId ? item.EMailId : "Not available",
        },
        {
          date: "Sent By",
          customerId:
            ": " + item["Sent/Received By"]
              ? item["Sent/Received By"]
              : "Not available",
        },
      ],
    };
    return objectAll;
  });

  //Add paginations
  const [currentPage, setCurrentPage] = React.useState(1);

  let itemsPerPage = 10;
  // Calculate the total number of pages
  const totalPages = Math.ceil(jsonObjectArray.length / itemsPerPage);

  // Determine the items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = jsonObjectArray.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  //End Pagination
 const handleChange = (e,value) => {
    setCurrentPage(value);
  };

  
  return (
    <div>
      <Box className="white-box">
        <PortalMessagesFilters filterType={value} />
      </Box>
      <Box>
        {getAllSentMessagesLoding ? (
          <>
            <Box>
              <CustomLoader />
            </Box>
          </>
        ) : currentItems && currentItems.length > 0 ? (
          <CustomePortalMessageTable
            TableHeder={TableHeder}
            TableData={currentItems}
            value={value}
          />
        ) : (
          <NoData message="No Message here" />
        )}
      </Box>
      <nav aria-label="Page navigation example">
        <Box className="mt-4">
          {/* <PaginationComponent /> */}
          <Pagination
            count={totalPages}
            page={currentPage}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            className="custom-pagination"
          />
        </Box>
      </nav>
    </div>
  );
}

export default SentMessages;
