import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'

function InitialRoute() {
  return (
    <>{window.location.pathname==="/" ? <Navigate to={"/user/Login"}/> : <Navigate to={window.location.pathname}/>}</>
  )
}

export default InitialRoute
