import React, { useState, useEffect, useMemo } from "react"; 
import { useDispatch, useSelector } from "react-redux"; 
import { SentMessageOfConatct } from "../../redux/ak_api_helper"; 
import Box from "@mui/material/Box"; 
import Table from "@mui/material/Table"; 
import TableBody from "@mui/material/TableBody"; 
import TableCell from "@mui/material/TableCell"; 
import TableContainer from "@mui/material/TableContainer"; 
import TableHead from "@mui/material/TableHead"; 
import TableRow from "@mui/material/TableRow"; 
import Paper from "@mui/material/Paper"; 
import Badge from "@mui/material/Badge";  
import MailIcon from "@mui/icons-material/Mail"; 
import { useNavigate } from "react-router-dom"; 
import CustomeSerchBar from "../../components/CustomeSerchBar"; 
import CustomDateRangePicker from "../../components/CustomDateRangePicker";
import moment from "moment"; 
import NoData from "../../components/NoData"; 
import { Pagination } from "@mui/material"; 
 
import { markMessageAsViewed } from "../../redux/dev_chetan_api_helper"; 
import CustomLoader from "../../components/CustomLoader";
import { SentConatactData } from "../../redux/akSlice";

// import { useDispatch } from "react-redux"; 
function Row(props) { 
  const { row } = props; 

  const navigate = useNavigate(); 
  const dispatch = useDispatch(); 
  const formatDate = (date) => moment(date).format("DD-MM-YYYY"); 
  // console.log(row,"rowDatasett"); 
  const [Messagesent, setMessagesent] = React.useState(true); 
  return ( 
    <React.Fragment> 
      <TableRow  
      className="pointer" hover 
      sx={{ "& > *": { borderBottom: "unset" } }} 
      onDoubleClick={() =>{ 
        dispatch(markMessageAsViewed(row.PortalDocID)); 
        navigate(`/client/portalmessage/inboxes/incoming-message-viewer?PortalDocID=${row.PortalDocID}&Subject=${row.Subject}&Section=${row.ItemName}&SendDate=${formatDate(row.ADate)}&ViewDate=${formatDate(row.ViewedDate)}&ReferenceName=${row.CName}&Inbox=${Messagesent}&Status=Yes`); 
      }} 
      > 
        <TableCell component="th" scope="row"> 
          {row.Subject} 
        </TableCell> 
        <TableCell align="center">{row.ItemName}</TableCell> 
        <TableCell align="center"> 
          {row.ADate ? formatDate(row.ADate) : ""} 
        </TableCell> 
        <TableCell align="center"> 
          {row.ViewedDate ? formatDate(row.ViewedDate) : ""} 
        </TableCell> 
        <TableCell align="center">{row.CName}</TableCell> 
      </TableRow> 
    </React.Fragment> 
  ); 
} 
 
export default function PortalSendinbox() { 
  const navigate = useNavigate(); 
  const dispatch = useDispatch(); 
  const [searchInput, setSearchInput] = React.useState("");
  const [filterCriteria, setFilterCriteria] = React.useState({});
  const {conatactData , contactDataLoding, allPortalSentMessages} = useSelector((state) => state.ak); 
  
  // console.log('kk conatactData',conatactData )
  const [serchQuery, setSerchQuery] = useState(""); 
  const [dateRange, setDateRange] = useState({ 
    startDate: moment().startOf("day"), 
    endDate: moment().endOf("day"), 
  }); 
 
  // console.log("Contact Data", conatactData); 
 
  const obj12 = { 
    accId: localStorage.getItem("agrno") ? localStorage.getItem("agrno") : "0003",
    username: localStorage.getItem("EmailClient") ? localStorage.getItem("EmailClient") : "patrick.docusoft@outlook.com", 
    sPassword: localStorage.getItem("passwordClient") ? localStorage.getItem("passwordClient") : "", 
  }; 
  
  useEffect(() => { 
    dispatch(SentMessageOfConatct(obj12)); 
    
  }, [dispatch]); 
 
  const filterBySection = (e) => { 
    setSerchQuery(e.target.value); 
   // filterData() 
  }; 
 
  const handleDateChange = (range) => { 
    setDateRange(range); 
  }; 
 
  const formatDate = (date) => moment(date).format("DD-MM-YYYY"); 
 
//   const filterData = conatactData.filter((item) => { 
 
//     return item.Subject.toLowerCase().includes(serchQuery.toLowerCase()) 
//     // let itemDate = formatDate(item.ADate); 
//     // // itemDate = itemDate.format('DD-MM-YYYY') 
//     // console.log("Date is formate", dateRange.startDate, dateRange.endDate); 
//     // const isWithinDateRange = itemDate.isBetween( 
//     //   dateRange.startDate, 
//     //   dateRange.endDate 
//     // ); 
 
//     // const isWithinDateRange  =
// // itemDate.isBetween(dateRange.startDate , dateRange.endDate) 
 
//     // const isWithinDateRange = itemDate.isBetween() 
//     // const matchesSearchQuery = item.Subject.toLowerCase().includes( 
//     //   serchQuery.toLowerCase() 
//     // ); 
//     // return matchesSearchQuery; 
//   }); 

const handleSendMessageSearch = (e) => {
  setSearchInput(e.target.value);
  setFilterCriteria({ ...filterCriteria, Subject: [e.target.value] });
}

const handleDateRangePicker = (date) => {
  setFilterCriteria({ ...filterCriteria, ADate: [date.startDate, date.endDate] });
}

useMemo(() => {
  if (Object.keys(filterCriteria).length > 0) {
    let filteredData = (Object.keys(filterCriteria).length > 0 && Object.keys(filterCriteria).length === 1) ? allPortalSentMessages.filter(itm => {
      if (Object.keys(filterCriteria).includes('ADate')) {
        if (moment(itm.ADate)._d >= filterCriteria['ADate'][0] && moment(itm.ADate)._d <= filterCriteria['ADate'][1]) {
          return true;
        }
      } else if (Object.keys(filterCriteria).includes('Subject')) {
        if (Boolean(itm.Subject)) {
          return itm.Subject.toLowerCase().includes(searchInput.toLowerCase());
        }
      }
    }) : (Object.keys(filterCriteria).length > 0 && Object.keys(filterCriteria).length === 2) ? allPortalSentMessages.filter(itm => {
      if (moment(itm.ADate)._d >= filterCriteria['ADate'][0] && moment(itm.ADate)._d <= filterCriteria['ADate'][1] && itm.Subject.toLowerCase().includes(searchInput.toLowerCase())) {
        return true;
      }
    }) : [];
    dispatch(SentConatactData({conatactData:filteredData}));
  }
}, [filterCriteria]);

// console.log('Contact Data length', conatactData)

const [currentPage, setCurrentPage] = React.useState(1);
let itemsPerPage = 10;

// Calculate the total number of pages
const totalPages = Math.ceil(conatactData.length / itemsPerPage);

// Determine the items to display on the current page
const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentItems = conatactData.slice(indexOfFirstItem, indexOfLastItem);

// Change page
const paginate = (pageNumber) => setCurrentPage(pageNumber);

const handleChange = (event, value) => {
  setCurrentPage(value);
};
 
  return ( 
    <Box className="white-box"> 
 
      <Box 
        sx={{ 
          display: "flex", 
          justifyContent: "space-between", 
        }} 
      > 
        <h2 className="title"> 
          Sent Message{" "} 
          {(!window.location.pathname.includes("sendinboxes")) && <Badge badgeContent={conatactData && conatactData.length} color="primary"> 
            <MailIcon color="action" /> 
          </Badge>} 
        </h2> 
      </Box>  
 
      <Box 
        sx={{ 
          display: "flex", 
          justifyContent: "space-between", 
          flexWrap: "wrap",
          gap: 2,
        }}
      > 
        <Box 
          sx={{ 
            display: "flex", 
            alignItems: "center",
            flexBasis: { xs: "100%", sm: "auto" },
          }}
        > 
          <CustomeSerchBar className="mb-2 " onChange={handleSendMessageSearch}/> 
        </Box> 
        <Box 
          sx={{ 
            display: "flex", 
            justifyContent: "space-between", 
            flexBasis: { xs: "100%", sm: "auto" },
          }} 
        > 
          <Box 
            sx={{ 
              display: "flex",
              marginBottom: 2,
            }} 
          > 
            <CustomDateRangePicker 
              onChange={() => {}} 
              strText={"Filter By Date"} 
              tooltipTlt={"Sent Date"} 
              setDateObj={handleDateRangePicker}
            /> 
            {/* <IconButton> 
              <RefreshIcon /> 
            </IconButton>  */}
          </Box> 
        </Box> 
      </Box> 
 

      <hr /> 
 
 
      

      {contactDataLoding ? (
        <>
        <CustomLoader/>
        </>
      ) : conatactData && conatactData.length > 0 ?  (
        <>
          <Box> 
          <TableContainer component={Paper} sx={{ boxShadow: "none" }} > 
            <Table aria-label="collapsible table" className="custom-table mb-3"> 
              <TableHead> 
                <TableRow > 
                  <TableCell className="poppins-semibold py-2">Subject</TableCell> 
                  <TableCell className="poppins-semibold py-2" align="center"> 
                    Section 
                  </TableCell> 
                  <TableCell className="poppins-semibold py-2" align="center"> 
                    Sent Date 
                  </TableCell> 
                  <TableCell className="poppins-semibold py-2" align="center"> 
                    Viewed On 
                  </TableCell> 
                  <TableCell className="poppins-semibold py-2" align="center"> 
                    Reference 
                  </TableCell> 
                </TableRow> 
              </TableHead> 
              <TableBody> 
                {currentItems.map((row) => ( 
                  <Row key={row.id} row={row} className='' /> 
                ))} 
              </TableBody> 
            </Table> 
          </TableContainer> 
 
          {/* <PaginationComponent />  */}
          
          <Box className='mt-4'>
          {/* <PaginationComponent /> */}
           <Pagination count={totalPages} page={currentPage}   variant="outlined" shape="rounded" onChange={handleChange} />
         
        </Box>

        </Box> 
        </>
      ) : <NoData message="No Message here"/>}
    </Box> 
  ); 
}
