import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const DounloadFile = ({ open, onClose, base64Data, fileType }) => {
  // Function to render file content based on file type
  const renderFile = () => {
    switch (fileType) {
      case 'jpeg':
      case 'jpg':
      case 'png':
      case 'gif':
        return <img src={`data:image/${fileType};base64,${base64Data}`} alt="Image Preview" style={{ width: '100%' }} />;
      
      case 'pdf':
        return (
            <iframe
            src={`data:application/${fileType};base64,${base64Data}`}
            title="PDF Viewer"
            style={{ width: '100%', height: '600px', border: 'none' }} // Set width to 100%
          />
        );

    //   case 'application/msword':
    //   case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    //   case 'doc': // Handling doc, docx
    //   case 'docx':
    //     return (
    //       <a
    //         href={`data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${base64Data}`}
    //         download="document.docx"
    //       >
    //         Download Word file
    //       </a>
    //     );

    //   case 'application/vnd.ms-excel':
    //   case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    //   case 'xlsx': // Handling xlsx, xls
    //   case 'xls':
    //     return (
    //       <a
    //         href={`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64Data}`}
    //         download="spreadsheet.xlsx"
    //       >
    //         Download Excel file
    //       </a>
    //     );

      default:
        return <p>Unsupported file type</p>;
    }
  };

  return (
    <Dialog
    onClose={onClose}
    aria-labelledby="customized-dialog-title"
    open={open}
    maxWidth="md" // Set maxWidth to 'lg' for larger dialog
    fullWidth // Make the dialog full width
    >
      <DialogTitle>
        Document Viewer
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8, color: 'grey[500]' }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <div id="fileContainer">
          {renderFile()}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DounloadFile;
