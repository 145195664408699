// import { useLocalStorage } from "../custom-hook/useLocalStorage";
import CommanCLS from "../services/CommanService";
import { SettingsBluetoothSharp, TryOutlined } from "@mui/icons-material";

// import Swal from 'sweetalert2';
// import 'sweetalert2/src/sweetalert2.scss';
import {
  docuEngagerClient,
  docuSms,
  portalClient,
  portalDoc,
  portalUser,
  practiceTest,
} from "./AxiosInstance";
import {
  setGetMessageAttachments_Redux,
  fetchdocuRequestDataClient,
  setAddDSRequest_Redux,
  setAllContactsRedux,
  setFolderData_Redux,
  setGetAllFolders_Redux,
  setGetClientCardDetails_Redux,
  setGetClientsByFolder_Redux,
  setGetForwardUserList_Redux,
  setPortalDocReqTemplatesLiset_Redux,
  setTestApiHelper,
  setPortalRequestDocuments_Redux,
  setDocumentRequests_Redux,
  setPortalUsersRequestedDocs_Redux,
  fetchdocuRequestDataClientAll,
  setApproveRequestedDocument_Redux,
  setDisapproveRequestedDocument_Redux,
  setJson_ExplorerSearchDoc_Redux,
  setGetSectionsByFolder_Redux,
  setRequestedDocumentAsByteArray_Redux,
  setPortalSectionData,
  setPortalToData,
  setPortalCCData,
  setGetUsersEmailSignature_Redux,
  setGetAccountUsersContactsByClient_Redux,
  setAllSentMessages_Redux,
  setAllReceivedMessages_Redux,
  setRetractedMessages_Json_Redux,
  setPortalUsersRequestedDocs2_Redux,
} from "./docuSlice";

import { useLocalStorage } from "../custom-hook/useLocalStorage";
import { Callbacks } from "jquery";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { setOpenComposeMessageModal } from "./modalSlice";

import moment from "moment";

// const reqObj = useLocalStorage('required');
const baseUrl = "https://portal.docuengager.com/PortalUserServices.asmx/";
let Cls = new CommanCLS(
  baseUrl,
  "0003",
  "patrick@docusoft.net",
  "UGF0cmljazEyMy4="
);
// export const demoTestApiHelper = () => dispatch => {
//     dispatch(setTestApiHelper(["Hello World"]));
// }
let accid = {
  accid: localStorage.getItem("agrno"),
  email: localStorage.getItem("Email"),
  password: localStorage.getItem("password"),
};

let accId = {
  accId: localStorage.getItem("agrno") ? localStorage.getItem("agrno") : "0003",
  email: localStorage.getItem("Email")
    ? localStorage.getItem("Email")
    : "patrick@docusoft.net",
  password: localStorage.getItem("password")
    ? localStorage.getItem("password")
    : "UGF0cmljazEyMy4=",
};

const userBody = {
  accid: localStorage.getItem("accid") ? localStorage.getItem("accid") : "0003",
  email: localStorage.getItem("Email"),
  EmailId: localStorage.getItem("EmailId"),
  password: localStorage.getItem("password"),
};

const clientBody = {
  accId: localStorage.getItem("accIdClient")
    ? localStorage.getItem("accIdClient")
    : "0003",
  username: localStorage.getItem("EmailClient"),
  sPassword: localStorage.getItem("passwordClient")
    ? localStorage.getItem("passwordClient")
    : "All",
};

const LoginClient = localStorage.getItem("LoginClient");

export const fetchdocuRequestData = (cusTomerEmail) => async (dispatch) => {
  console.log(cusTomerEmail,"==========cusTomerEmail ");
  
  console.log("cusTomerEmail", cusTomerEmail)
  if (LoginClient) {
    let body = {
      ...clientBody,
      template: "0",
      filterBy: "All",
      username: cusTomerEmail || clientBody.username
    };

    try {
      let res = await portalUser.post("PortalUsersRequestedDocs2New_Json", body);

      if (res && res.data) {
        let str = res.data;
        // console.log("UpdateRequestedDocStatusAction_Json 11111", str.d);

        let parsedData = typeof str.d === "string" ? JSON.parse(str.d) : str.d;

        const arrayUniqueByKey = [
          ...new Map(
            parsedData.map((item) => [item["TemplateName"], item])
          ).values(),
        ];

        dispatch(fetchdocuRequestDataClient(arrayUniqueByKey));
        dispatch(fetchdocuRequestDataClientAll(parsedData));
      }
    } catch (error) {
      console.error("Network error: No response received from server", error);
    }
  } else {

    let obj = {
      ...accId,
      username: cusTomerEmail ||
        localStorage.getItem("EmailClient"),
      sPassword: "0",
      filterBy: "All",
    };

    try {
      let res = await portalClient.post("PortalUsersRequestedDocs2_Json", obj);

      if (res && res.data) {
        let str = res.data;

        console.log("UpdateRequestedDocStatusAction_Json 2222", JSON.parse(str.d));

        let parsedData = typeof str.d === "string" ? JSON.parse(str.d) : str.d;

        const arrayUniqueByKey = [
          ...new Map(
            parsedData.map((item) => [item["TemplateName"], item])
          ).values(),
        ];

        dispatch(fetchdocuRequestDataClient(arrayUniqueByKey));
        dispatch(fetchdocuRequestDataClientAll(parsedData));
      }
    } catch (error) {
      console.error("Network error: No response received from server", error);
    }

    //   try {
    //     Cls.PortalUsersRequestedDocs_Json(obj, (sts, data) => {
    //       if (sts && data) {
    //         let json = JSON.parse(data);

    //         if (json && json.length > 0) {
    //           const arrayUniqueByKey = [
    //             ...new Map(json.map((item) => [item["TemplateName"], item])).values(),
    //           ];

    //           console.log(json, "======json");

    //           dispatch(fetchdocuRequestDataClient(arrayUniqueByKey));
    //           dispatch(fetchdocuRequestDataClientAll(json));

    //           return json;
    //         }

    //         dispatch(fetchdocuRequestDataClient([]));
    //         dispatch(fetchdocuRequestDataClientAll([]));
    //       }
    //     });
    //   } catch (err) {
    //     console.error("Error while calling PortalUsersRequestedDocs_Json", err);
    //   }
  }
};

export const sonamConfirmMessage = (txt, callBack) => (dispatch) => {
  Swal.fire({
    // title: "Are you sure you want to delete this item?",
    text: txt,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes !",
  }).then((result) => {
    if (result.isConfirmed) {
      return callBack(true);
    } else {
      return callBack(false);
    }
  });
};

export const RequestedDocumentCreated_Json = () => { };

export const sonamConfirmMessage1 =
  (txt, oldFile, newFile, callBack) => (dispatch) => {
    Swal.fire({
      // title: "Are you sure you want to delete this item?",
      text: txt,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes !",
      html: `
        <p>${txt}</p><br>
        <p>Existing File : ${oldFile}</p><br>
        <p>New File : ${newFile}</p>
        
    `,
      // preConfirm: () => {
      //     const checkbox = document.getElementById('myCheckbox');
      //     return checkbox ? checkbox.checked : false;
      //   }
    }).then((result) => {
      if (result.isConfirmed) {
        return callBack(true);
      } else {
        return callBack(false);
      }
    });
  };
export const ConvertToPdf_Json_Redux = (obj, callBack) => async (dispatch) => {
  try {
    const response = await portalClient.post("ConvertToPdf_Json", {
      ...accid,
      ...obj,
    });
    if (response?.data) {
      callBack(response?.data?.d);
    }
  } catch (error) {
    console.log(
      "Network error: No response received from server ConvertToPdf_Json",
      error
    );
  }
};

export const Json_AddDSRequest_Redux = (obj, callBack) => (dispatch) => {
  try {
    const response = docuSms.post("Json_AddDSRequest", obj);
    callBack(response);
    dispatch(setAddDSRequest_Redux(response));
  } catch (error) {
    console.log("Network error: No response received from server", error);
  }
};

export const Json_GetForwardUserList_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      const response = await docuSms.post("Json_GetForwardUserList", obj);

      let js;
      try {
        js = JSON.parse(JSON.stringify(response?.data));
      } catch (jsonError) {
        console.error("Error parsing JSON:", jsonError);
        return;
      }
      callBack(JSON.parse(js.d)); // Use the parsed JSON
      dispatch(setGetForwardUserList_Redux(JSON.parse(js.d))); // Dispatch the parsed JSON
    } catch (error) {
      console.error("Network error:", error);
    }
  };

export const Json_GetFolderData_Redux = (obj, callBack) => async (dispatch) => {
  try {
    let res = await docuSms.post("Json_GetFolderData", obj);

    if (res?.d) {
      let str = JSON.parse(JSON.stringify(res?.data));
      callBack(str?.d);
      dispatch(setFolderData_Redux(str?.d));
    } else {
      callBack([]);
    }
  } catch (error) {
    console.log(
      "Network error: No response received from server, Json_GetFolderData_Redux",
      error
    );
  }
};
export const GetPortalSections_Json = (obj, callBack) => async (dispatch) => {
  try {
    let res = await portalUser.post("/GetPortalSections_Json", obj);
    if (res?.data) {
      let str = JSON.parse(JSON.stringify(res?.data));
      callBack(JSON.parse(str?.d));
      dispatch(setPortalSectionData(JSON.parse(str?.d)));
    } else {
      callBack([]);
    }
  } catch (error) {
    console.log(
      "Network error: No response received from server, GetPortalSections_Json",
      error
    );
  }
};
export const Json_GetClientListByEmail =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await docuSms.post("/Json_GetClientListByEmail", obj);
      console.log(res, "GetPortalSections_Jso2222n");
      if (res?.data) {
        let str = JSON.parse(JSON.stringify(res?.data));
        // let parseData = JSON.parse(str);
        console.log(str.d, "Json_GetClientListByEmail");
        callBack(JSON.parse(str?.d));
      } else {
        callBack([]);
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, GetPortalSections_Json",
        error
      );
    }
  };
export const PortalUsersCompanyList_Json =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalUser.post("/PortalUsersCompanyList_Json", obj);
      console.log(res, "GetPortalSections_Jso2222n");
      if (res?.data) {
        let str = JSON.parse(JSON.stringify(res?.data));
        callBack(JSON.parse(str?.d));
      } else {
        callBack([]);
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, GetPortalSections_Json",
        error
      );
    }
  };
export const PortalUsersInbox_Json = (obj, callBack) => async (dispatch) => {
  try {
    let res = await portalUser.post("/PortalUsersInbox_Json", obj);
    if (res?.data) {
      let str = JSON.parse(JSON.stringify(res?.data));
      console.log(str.d, "PortalUsersInbox_Json");
      callBack(JSON.parse(str?.d));
    } else {
      callBack([]);
    }
  } catch (error) {
    console.log(
      "Network error: No response received from server, GetPortalSections_Json",
      error
    );
  }
};

export const GetPortalManagers_Json = (obj, callBack) => async (dispatch) => {
  try {
    let res = await portalUser.post("/GetPortalManagers_Json", obj);

    if (res?.data) {
      let str = JSON.parse(JSON.stringify(res?.data));
      callBack(JSON.parse(str?.d));
      dispatch(setPortalToData(JSON.parse(str?.d)));
      dispatch(setPortalCCData(JSON.parse(str?.d)));
    } else {
      callBack([]);
    }
  } catch (error) {
    console.log(
      "Network error: No response received from server, GetPortalManagers_Json",
      error
    );
  }
};

export const getFileExtension = (fileName) => {
  return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2); // Extracts file extension
};

export const RequestedDocumentCreated_Json_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      // let res = await portalClient.post("RequestedDocumentCreated_Json", obj);
      let res = await portalUser.post("RequestedDocumentCreated_Json", obj);
      if (res) {
        let js = JSON.parse(JSON.stringify(res.data));
        callBack(js);
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, RequestedDocumentCreated_Json",
        error
      );
    }
  };

export const RequestedDocumentCreatedNew_Json =
  (obj, callBack) => async (dispatch) => {
    try {
      // let res = await portalClient.post("RequestedDocumentCreated_Json", obj);
      let res = await portalUser.post("RequestedDocumentCreatedNew2_Json", obj);
      if (res) {
        let js = JSON.parse(JSON.stringify(res.data));
        callBack(js);
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, RequestedDocumentCreated_Json",
        error
      );
    }
  };

export const DeleteRequestedDocument_Json =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalUser.post("DeleteRequestedDocument_Json", obj);
      if (res) {
        let js = JSON.parse(JSON.stringify(res.data.d));
        callBack(JSON.parse(js));
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, DeleteRequestedDocument_Json",
        error
      );
    }
  };
export const GetAllFolders_Json_Redux = (callBack) => async (dispatch) => {
  try {
    let res = await portalClient.post("GetAllFolders_Json", { ...accid });
    if (res?.data) {
      let str = JSON.parse(JSON.stringify(res?.data));
      if (str?.d) {
        // console.log("GetAllFolders_Json", JSON.parse(str?.d));
        callBack(JSON.parse(str.d));
        dispatch(setGetAllFolders_Redux(JSON.parse(str?.d)));
      }
    }
  } catch (error) {
    console.log(
      "Network error: No response received from server, GetAllFolders_Json",
      error
    );
  }
};
export const PortalDocTemplateDeleted_Json_Redux =
  (o, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("PortalDocTemplateDeleted_Json", {
        ...accId,
        ...o,
      });
      if (res?.data) {
        let str = JSON.parse(JSON.stringify(res?.data));
        if (str?.d) {
          callBack(str.d);
        }
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, PortalDocTemplateDeleted_Json",
        error
      );
    }
  };

export const Json_GetClientCardDetails_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await practiceTest.post("Json_GetClientCardDetails", obj);
      if (res?.data) {
        let str = JSON.parse(JSON.stringify(res?.data));
        if (str?.d) {
          let js = JSON.parse(str?.d);
          dispatch(setGetClientCardDetails_Redux(js));
          callBack(js);
        }
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, Json_GetClientCardDetails",
        error
      );
    }
  };

//Create method for get all contacts
export const GetAllContactsJsonRedux = (obj, callBack) => async (dispatch) => {
  try {
    let res = await portalClient.post("GetAllContacts_Json", obj);
    if (res) {
      let str = JSON.parse(JSON.stringify(res.data));
      callBack(JSON.parse(str.d));
      dispatch(setAllContactsRedux(JSON.parse(str.d)));
    }
  } catch (error) {
    console.log(
      "Network error: No response received from server, GetAllContactsJsonRedux",
      error
    );
  }
};

export const GetClientsByFolder_Json_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("GetClientsByFolder_Json", obj);
      if (res?.data) {
        let str = JSON.parse(JSON.stringify(res?.data));
        if (str?.d) {
          callBack(JSON.parse(str?.d));
          dispatch(setGetClientsByFolder_Redux(JSON.parse(str?.d)));
        }
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, GetClientsByFolder_Json",
        error
      );
    }
  };
export const GetSectionsByFolder_Json_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("GetSectionsByFolder_Json", obj);
      if (res?.data) {
        let str = JSON.parse(JSON.stringify(res?.data));
        if (str?.d) {
          callBack(JSON.parse(str?.d));
          dispatch(setGetSectionsByFolder_Redux(JSON.parse(str?.d)));
        }
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, GetSectionsByFolder_Json",
        error
      );
    }
  };

export const GetPortalDocReqTemplates_Json_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("GetPortalDocReqTemplates_Json", {
        ...accId,
        ...obj,
      });
      if (res?.data) {
        let js = JSON.parse(JSON.stringify(res?.data));
        callBack(JSON.parse(js?.d));
        dispatch(setPortalDocReqTemplatesLiset_Redux(JSON.parse(js?.d)));
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, GetPortalDocReqTemplates_Json",
        error
      );
    }
  };

export const PortalDocTemplateExists_Json_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("PortalDocTemplateExists_Json", obj);
      if (res?.data) {
        callBack(res?.data?.d);
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, PortalDocTemplateExists_Json",
        error
      );
    }
  };
export const PortalRequestedDocCreated_Json_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("PortalRequestedDocCreated22_Json", {
        ...accId,
        ...obj,
      });
      if (res?.data) {
        callBack(res?.data?.d);
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, PortalRequestedDocCreated22_Json",
        error
      );
    }
  };
export const PortalRequestedDocUpdated_Json1_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("PortalRequestedDocUpdated2_Json", {
        ...accId,
        ...obj,
      });
      if (res?.data) {
        callBack(res?.data?.d);
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, PortalRequestedDocUpdated2_Json",
        error
      );
    }
  };

export const PortalDocTemplateCreated_Json_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("PortalDocTemplateCreated_Json", obj);
      if (res?.data) {
        callBack(res?.data);
        // dispatch(setPortalDocReqTemplatesLiset_Redux(JSON.parse(js?.d)))
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, PortalDocTemplateCreated_Json",
        error
      );
    }
  };
export const MessagePublishedPortalTask_Json_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("MessagePublishedPortalTask_Json", obj);
      if (res?.data) {
        //let js = JSON.parse(JSON.stringify(res?.data));
        callBack(res?.data);
        // dispatch(setPortalDocReqTemplatesLiset_Redux(JSON.parse(js?.d)))
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, MessagePublishedPortalTask_Json",
        error
      );
    }
  };
export const MessageUploaded_Json = (obj, callBack) => async (dispatch) => {
  try {
    let res = await portalUser.post("MessageUploaded_Json", obj);
    if (res?.data) {
      let js = JSON.parse(JSON.stringify(res?.data));
      toast.success("Sent message successfully!");
      dispatch(setOpenComposeMessageModal(false));
      callBack(JSON.parse(js));
      // dispatch(setPortalDocReqTemplatesLiset_Redux(JSON.parse(js?.d)))
    }
  } catch (error) {
    console.log(
      "Network error: No response received from server, MessageUploaded_Json",
      error
    );
  }
};
export const Json_ExplorerSearchDoc_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await practiceTest.post("Json_ExplorerSearchDoc", obj);
      if (res?.data) {
        let js = JSON.parse(JSON.stringify(res?.data));
        if (js?.d) {
          callBack(JSON.parse(js?.d));
          dispatch(setJson_ExplorerSearchDoc_Redux(JSON.parse(js?.d)));
        }
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, Json_ExplorerSearchDoc",
        error
      );
    }
  };

export const GetUsersEmailSignature_Json_Redux = (obj) => async (dispatch) => {
  try {
    let res = await portalClient.post("GetUsersEmailSignature_Json", obj);
    if (res?.data) {
      dispatch(setGetUsersEmailSignature_Redux(res?.data?.d));
    }
  } catch (error) {
    console.log(
      "Network error: No response received from server, GetUsersEmailSignature_Json",
      error
    );
  }
};

export const Json_Portal_GetClientListByEmail = () => async (dispatch) => {
  try {
    let p = {
      agrno: "0003",
      ContactEmail: "dbeckhamportal@gmail.com",
    };
    let res = await docuSms.post("Json_Portal_GetClientListByEmail", p);
    if (res?.data) {
      let js = JSON.parse(JSON.stringify(res?.data));
      if (js?.d) {
        // callBack(js?.d);
        dispatch(setGetUsersEmailSignature_Redux(js?.d));
      }
    }
  } catch (error) {
    console.log(
      "Network error: No response received from server, GetUsersEmailSignature_Json",
      error
    );
  }
};

export const Json_GetItemBase64DataById_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await docuSms.post("Json_GetItemBase64DataById", obj);
      if (res?.data) {
        let js = res?.data;
        if (js?.d) {
          callBack(js?.d);
        }
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, Json_GetItemBase64DataById",
        error
      );
    }
  };
export const RemoveFilesForUpload_Json_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("RemoveFilesForUpload_Json", obj);
      if (res?.data) {
        let js = res?.data;
        if (js?.d) {
          callBack(js?.d);
        }
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, RemoveFilesForUpload_Json",
        error
      );
    }
  };

export const PrepareDocumentsForPublish_Json_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("PrepareDocumentsForPublish_Json", obj);
      if (res?.data) {
        let js = JSON.parse(JSON.stringify(res?.data));
        if (js?.d) {
          callBack(js?.d);
        }
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, PrepareDocumentsForPublish_Json",
        error
      );
    }
  };

export const GetSignatureCreationStatus_Json_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("GetSignatureCreationStatus_Json", {
        ...accid,
        ...obj,
      });
      if (res?.data) {
        let js = JSON.parse(JSON.stringify(res?.data));
        if (js?.d) {
          callBack(js?.d);
        }
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, GetSignatureCreationStatus_Json",
        error
      );
    }
  };

function generateUniqueKey(obj) {
  return obj.PortalDocId + "|" + obj.emailid;
}

export const GetMessageAttachments_Json_Redux =
  (messageId) => async (dispatch) => {
    try {
      let res = await portalClient.post("GetMessageAttachments_Json", {
        ...accid,
        ...{ messageId: messageId },
      });

      if (res?.data) {
        let js = JSON.parse(JSON.stringify(res?.data));

        let dt = JSON.parse(js?.d);
        if (dt.length > 0) {
          let groupedByPortalDocId = dt.reduce((acc, doc) => {
            if (!acc[doc.PortalDocId]) {
              acc[doc.PortalDocId] = [];
            }
            acc[doc.PortalDocId].push(doc);
            return acc;
          }, []);
          dispatch(setGetMessageAttachments_Redux(groupedByPortalDocId));
        } else {
          dispatch(setGetMessageAttachments_Redux([]));
        }
      } else {
        dispatch(setGetMessageAttachments_Redux([]));
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, GetMessageAttachments_Json",
        error
      );
    }
  };

export const Json_GetWebTemplatesList_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await practiceTest.post("Json_GetSMSTemplate", obj);
      if (res?.data) {
        let js = JSON.parse(JSON.stringify(res?.data));
        if (js?.d) {
          callBack(JSON.parse(js?.d));
        }
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, Json_GetWebTemplatesList",
        error
      );
    }
  };
export const Json_GetStandardLetterData_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await docuSms.post("Json_GetStandardLetterData", obj);
      if (res?.data) {
        let js = JSON.parse(JSON.stringify(res?.data));
        if (js?.d) {
          callBack(JSON.parse(js?.d));
        }
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, Json_GetStandardLetterData",
        error
      );
    }
  };

export const Json_GetHtmlFromRtf_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await docuSms.post("Json_GetHtmlFromRtf", obj);
      if (res?.data) {
        let js = JSON.parse(JSON.stringify(res?.data));
        if (js?.d) {
          callBack(js?.d);
        }
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, Json_GetHtmlFromRtf",
        error
      );
    }
  };

//Get All documents requests
export const GetDocumentRequests_Json_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("GetDocumentRequests_Json", obj);
      if (res) {
        let str = JSON.parse(JSON.stringify(res.data));
        callBack(JSON.parse(str.d));
        dispatch(setDocumentRequests_Redux(JSON.parse(str.d)));
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, GetDocumentRequests_Json",
        error
      );
    }
  };
//sign and approve the request
export const SignRequestedDocument_Json =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalUser.post("SignRequestedDocumentNew_Json", obj);
      if (res) {
        let str = JSON.parse(JSON.stringify(res.data));
        callBack(str.d);
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, SignRequestedDocument_Json",
        error
      );
    }
  };
//Get Portal documents requests By Id
export const PortalRequestedDocDeleted_Json_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      const response = await portalClient.post(
        "PortalRequestedDocDeleted_Json",
        { ...accId, ...obj }
      );
      if (response?.data) {
        callBack(response?.data?.d);
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server PortalRequestedDocDeleted_Json",
        error
      );
    }
  };

export const PortalRequestedDocUpdated1_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      const response = await portalClient.post("PortalRequestedDocUpdated1", {
        ...accId,
        ...obj,
      });
      if (response?.data) {
        callBack(response?.data?.d);
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server PortalRequestedDocUpdated1",
        error
      );
    }
  };

export const GetPortalRequestDocuments_Json_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("GetPortalRequestDocuments_Json", {
        ...accId,
        ...obj,
      });
      if (res?.data?.d) {
        let str = JSON.parse(JSON.stringify(res?.data));
        callBack(JSON.parse(str?.d));
        dispatch(setPortalRequestDocuments_Redux(JSON.parse(str?.d)));
      } else {
        callBack({});
        dispatch(setPortalRequestDocuments_Redux([]));
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, GetPortalRequestDocuments_Json",
        error
      );
    }
  };

export const CheckDocumentForSignature_Json_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("CheckDocumentForSignature_Json", {
        ...accId,
        ...obj,
      });
      if (res) {
        let str = JSON.parse(JSON.stringify(res.data));
        callBack(JSON.parse(str.d));
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, CheckDocumentForSignature_Json",
        error
      );
    }
  };
export const CheckDocumentForFormFilling_Json_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("CheckDocumentForFormFilling_Json", {
        ...accId,
        ...obj,
      });
      if (res) {
        let str = JSON.parse(JSON.stringify(res.data));
        callBack(JSON.parse(str.d));
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, CheckDocumentForFormFilling_Json",
        error
      );
    }
  };

export const UpdateRequestedDocStatusAction_Json_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("UpdateRequestedDocStatusAction_Json", {
        ...accId,
        ...obj,
      });
      if (res) {
        let str = JSON.parse(JSON.stringify(res.data));
        callBack(str.d);
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error
      );
    }
  };
export const PortalDocTemplateUpdated_Json_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("PortalDocTemplateUpdated_Json", {
        ...accId,
        ...obj,
      });
      if (res) {
        let str = JSON.parse(JSON.stringify(res.data));
        callBack(str.d);
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, PortalDocTemplateUpdated_Json",
        error
      );
    }
  };

//Get Request Details by docu temp id
export const PortalUsersRequestedDocs_Json_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("PortalUsersRequestedDocs_Json", obj);

      if (res) {
        let str = JSON.parse(JSON.stringify(res.data));
        if (str?.d) {
          console.log("PortalUsersRequestedDocs_Json", JSON.parse(str.d));
          callBack(JSON.parse(str.d));
          dispatch(setPortalUsersRequestedDocs_Redux(JSON.parse(str.d)));
        }
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, PortalUsersRequestedDocs_Json",
        error
      );
    }
  };

export const ApproveRequestedDocument_Json_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("ApproveRequestedDocument_Json", obj);
      if (res) {
        let str = JSON.parse(JSON.stringify(res.data));
        callBack(JSON.parse(str.d));
        dispatch(setApproveRequestedDocument_Redux(JSON.parse(str.d)));
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, ApproveRequestedDocument_Json",
        error
      );
    }
  };

//DisapproveRequestedDocument docx by temp and doc Id from requested Details
export const DisapproveRequestedDocument_Json_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post(
        "DisapproveRequestedDocument_Json",
        obj
      );
      if (res) {
        let str = JSON.parse(JSON.stringify(res.data));
        callBack(JSON.parse(str.d));
        dispatch(setDisapproveRequestedDocument_Redux(JSON.parse(str.d)));
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, DisapproveRequestedDocument_Json",
        error
      );
    }
  };

//RequestedDocumentAsByteArray_Json Get Request docx by base 64
export const RequestedDocumentAsByteArray_Json_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post(
        "RequestedDocumentAsByteArray2_Json",
        obj
      );
      if (res) {
        let str = JSON.parse(JSON.stringify(res.data));
        callBack(str.d);
        dispatch(setRequestedDocumentAsByteArray_Redux(str.d));
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, RequestedDocumentAsByteArray_Json",
        error
      );
    }
  };

export const RequestedDocumentAsByteArray_Json_ReduxData =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post(
        "RequestedDocumentAsByteArray_Json",
        obj
      );
      if (res) {
        let str = JSON.parse(JSON.stringify(res.data));
        callBack(str.d);
        // dispatch(setRequestedDocumentAsByteArray_ReduxData(str.d))
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, RequestedDocumentAsByteArray_Json",
        error
      );
    }
  };
//Get contacts details with client id from folders
export const Json_GetAccountUsersContactsByClient_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post(
        "Json_GetAccountUsersContactsByClient",
        obj
      );
      if (res) {
        let str = JSON.parse(JSON.stringify(res.data));
        callBack(str.d);
        dispatch(setGetAccountUsersContactsByClient_Redux(str.d));
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, Json_GetAccountUsersContactsByClient",
        error
      );
    }
  };

//Save and share documents on docusoft
export const DocusoftRequestedDocument_Json =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("DocusoftRequestedDocument_Json", obj);
      if (res) {
        let js = JSON.parse(JSON.stringify(res.data.d));
        if (res?.data.d == "") {
          callBack({ d: true });
        } else {
          callBack(JSON.parse(js));
        }
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, DocusoftRequestedDocument_Json",
        error
      );
    }
  };
//Delete documents
export const DeleteRequestedDocumentFromClient_Json =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("DeleteRequestedDocument_Json", obj);
      if (res) {
        let js = JSON.parse(JSON.stringify(res.data.d));
        callBack(JSON.parse(js));
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, DeleteRequestedDocumentFromClient_Json",
        error
      );
    }
  };
//Renewal request documents for Renewal
export const RenewRequestedDocument_Json_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("RenewRequestedDocument_Json", obj);
      if (res) {
        let str = JSON.parse(JSON.stringify(res.data));
        callBack(str.d);
        //  dispatch(setGetAccountUsersContactsByClient_Redux(str.d))
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, RenewRequestedDocument_Json",
        error
      );
    }
  };

//Get Sent messages by 3 jun
export const GetAllSentMessages_Json_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("GetAllSentMessages_Json", obj);
      if (res) {
        let str = JSON.parse(JSON.stringify(res.data));
        callBack(JSON.parse(str.d));
        dispatch(setAllSentMessages_Redux(JSON.parse(str.d)));
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, GetAllSentMessages_Json",
        error
      );
    }
  };
//Get all Received messages  added by 3 jun
export const GetAllReceivedMessages_Json_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("GetAllReceivedMessages_Json", obj);
      if (res) {
        let str = JSON.parse(JSON.stringify(res.data));
        callBack(JSON.parse(str.d));
        dispatch(setAllReceivedMessages_Redux(JSON.parse(str.d)));
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, GetAllReceivedMessages_Json",
        error
      );
    }
  };

//Get All RetractedMessages added by 3 jun
export const GetRetractedMessages_Json_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("GetRetractedMessages_Json", obj);
      if (res) {
        let str = JSON.parse(JSON.stringify(res.data));
        callBack(JSON.parse(str.d));
        dispatch(setRetractedMessages_Json_Redux(JSON.parse(str.d)));
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, GetRetractedMessages_Json",
        error
      );
    }
  };

//Get all Sent Messages By Folder  by 3 jun
export const GetAllSentMessagesByFolder_Json_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("GetAllSentMessagesByFolder_Json", obj);
      if (res) {
        let str = JSON.parse(JSON.stringify(res.data));
        callBack(JSON.parse(str.d));
        dispatch(setAllSentMessages_Redux(JSON.parse(str.d)));
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, GetAllSentMessagesByFolder_Json",
        error
      );
    }
  };

//Get All recieved messages  by 3 jun
export const GetAllReceivedMessagesByFolder_Json_Redux =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post(
        "GetAllReceivedMessagesByFolder_Json",
        obj
      );
      if (res) {
        let str = JSON.parse(JSON.stringify(res.data));
        callBack(JSON.parse(str.d));
        dispatch(setAllReceivedMessages_Redux(JSON.parse(str.d)));
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, GetAllReceivedMessagesByFolder_Json",
        error
      );
    }
  };

// Update Request documents by request details page by doc Id
export const UpdateRequestedDocStatusAction_Json_Requested =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalUser.post(
        "UpdateRequestedDocStatusAction_Json",
        obj
      );
      if (res) {
        let str = JSON.parse(JSON.stringify(res.data));
        callBack(JSON.parse(str.d));
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json from request details",
        error
      );
    }
  };

//Get Status by status Id
export const GetRequestStatusById_Json =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalUser.post("GetRequestStatusById_Json", obj);
      if (res) {
        let js = JSON.parse(JSON.stringify(res.data.d));
        callBack(JSON.parse(js));
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, GetRequestStatusById_Json",
        error
      );
    }
  };

//Added new function for get requested details for status name
export const PortalUsersRequestedDocs2_Json_Redux =
  (obj, callBack) => async (dispatch) => {


    try {
      let res = await portalClient.post("PortalUsersRequestedDocs2_Json", obj);

      if (res) {
        let str = JSON.parse(JSON.stringify(res.data));
        if (str?.d) {
          callBack(JSON.parse(str.d));
          dispatch(setPortalUsersRequestedDocs2_Redux(JSON.parse(str.d)));
        }
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, PortalUsersRequestedDocs2_Json",
        error
      );
    }
  };
