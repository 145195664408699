import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { setOpenContactDetailModal } from '../redux/modalSlice';
import { useDispatch } from 'react-redux';


function ContactDetailsModal({open}) {
    const dispatch = useDispatch();
    const ContacthandleClose = () => {
        dispatch(setOpenContactDetailModal(false));
    };
    return (
        <Dialog
            open={open}
            onClose={ContacthandleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Use Google's location service?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Let Google help apps determine location. This means sending anonymous
                    location data to Google, even when no apps are running.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={ContacthandleClose}>Disagree</Button>
                <Button onClick={ContacthandleClose} autoFocus>
                    Agree
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ContactDetailsModal