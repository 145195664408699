import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openContactDetailModal: false,
  openDocumentPreviewModal: false,
  openUploadDocument: false,
  uploadDocumentData: false,
  openApproveModal: false,
  approveData: false,
  type: "",
  id: 0,
  openSignDocument: false,
  openCompanyModal: false,
  openComposeMessageModal: false,
  openBookMeetingModal: false,
  openWhatsAppValidModal: false,
  openIntegrationModal: false,
  getWhatappNumber: "",
  getOrigantryNumber: "",
  getWhatappUpdate: false,
  getCalendryMeetingModal: false
};

const modalSlice = createSlice({
  name: "modal_slice",
  initialState,
  reducers: {
    setOpenContactDetailModal: (state, action) => {
      state.openContactDetailModal = action.payload;
    },
    setOpenDocumentPreviewModal: (state, action) => {
      state.openDocumentPreviewModal = action.payload;
    },
    setOpenUploadDocument: (state, action) => {
      const { isOpen, data } = action.payload;
      state.openUploadDocument = isOpen;
      state.uploadDocumentData = data;
    },
    setOpenApproveModal: (state, action) => {
      const { isOpen, data, type, id } = action.payload;
      state.openApproveModal = isOpen;
      state.approveData = data;
      state.type = type;
      state.id = id;
    },
    setOpenSignDocument: (state, action) => {
      state.openSignDocument = action.payload;
    },
    setOpenCompanyModal: (state, action) => {
      state.openCompanyModal = action.payload;
    },
    setOpenComposeMessageModal: (state, action) => {
      state.openComposeMessageModal = action.payload;
    },
    setOpenBookMeetingModal: (state, action) => {
      state.openBookMeetingModal = action.payload;
    },
    setWhatsAppValidModal: (state, action) => {
      state.openWhatsAppValidModal = action.payload;
    },
    setWhatsAppNumber: (state, action) => {
      state.getWhatappNumber = action.payload;
    },
    setWhatappUpdate: (state, action) =>{
      state.getWhatappUpdate = action.payload;
    },
    setOrigantryNumber: (state, action) =>{
      state.getOrigantryNumber = action.payload;
    },
    setCalendryMeetingModal: (state, action) => {
      state.getCalendryMeetingModal = action.payload;
    },
    setOpenIntegrationModal: (state, action) => {
      state.openIntegrationModal = action.payload;
    },
  },
});

export const {
  setOpenContactDetailModal,
  setOpenDocumentPreviewModal,
  setOpenUploadDocument,
  setOpenApproveModal,
  setOpenSignDocument,
  setOpenComposeMessageModal,
  setOpenCompanyModal,
  setOpenBookMeetingModal,
  setWhatsAppValidModal,
  setOpenIntegrationModal,
  setWhatsAppNumber,
  setOrigantryNumber,
  setWhatappUpdate,
  setCalendryMeetingModal
} = modalSlice.actions;

export default modalSlice.reducer;
