import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PersonIcon from '@mui/icons-material/Person';
import MenuList from '@mui/material/MenuList';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
// import CreateTemplate from './CreateTemplate';
import { useNavigate } from 'react-router-dom';
import { GetAllFolders_Json_Redux, GetPortalDocReqTemplates_Json_Redux, PortalDocTemplateDeleted_Json_Redux } from '../../../redux/api_helper';
import { useDispatch, useSelector } from 'react-redux';
import TemplateMenuItem from './TamplateMenuItem';
import CreateTemplate from './CreateTemplate';
import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import NoData from '../../../components/NoData';
import CustomLoader from '../../../components/CustomLoader';
import CreateIcon from '@mui/icons-material/Create';
import { setRequestDocument_Redux } from '../../../redux/docuSlice';


const FolderId = localStorage.getItem('FolderId');

const ITEM_HEIGHT = 48;

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 12 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function Templates() {
    const navigate = useNavigate();
    let dispatch = useDispatch();
    const allTempList = useSelector((state) => state.docu.PortalDocReqTemplatesLiset)

    const [getFolderList, setGetFoldersList] = React.useState([]);
    const [defaltFolderData, setDefaltFolderData] = React.useState({});
    const [showTemplateList, setShowTemplateList] = React.useState(allTempList)

    const [searchString, setsearchString] = React.useState("");
    const [openModal, setOpenModal] = React.useState(false);
    const [txtFolderid, setTxtFolderid] = React.useState(FolderId);
    const [isLoding, setIsLoding] = React.useState(true);
    const [value, setValue] = React.useState(0);


    const [selectedTemplate, setSelectedTemplate] = React.useState(null);



    React.useEffect(() => {
        dispatch(GetAllFolders_Json_Redux(function (res) {
            if (res) {
                setGetFoldersList(res);
                setIsLoding(false)
                let result = res.filter((e) => e.FolderID === parseInt(FolderId));
                if (result.length > 0) {
                    setDefaltFolderData(result[0])
                }
            }
        }))
        getAllTemplates(FolderId);
        if (selectedTemplate) {
            try {
                if (selectedTemplate.action === 'Edit' || selectedTemplate.action === 'Delete') {

                    console.log("set selected item111...", selectedTemplate.data)

                    dispatch(setRequestDocument_Redux(selectedTemplate.data))
                }

            } catch (error) {

            }
        }

    }, [dispatch, selectedTemplate])


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickFolder = (e) => {
        try {
            getAllTemplates(e.FolderID)
            setTxtFolderid(e.FolderID)
        } catch (error) {
            console.log(error);
        }
    }

    function getAllTemplates(pid) {
        try {
            let o = {
                accId: localStorage.getItem("agrno"),
                email: localStorage.getItem("Email"),
                password: localStorage.getItem("password"),
                allTemplates: "true",
                folder: pid,
                forCompose: "false",
            };
            dispatch(GetPortalDocReqTemplates_Json_Redux(o, (res) => { }));
        } catch (error) {
            console.log(
                "Network error: No response received from server, GetPortalDocReqTemplates_Json",
                error
            );
        }
    }

    const handleClickOpenModal = () => {
        setOpenModal(true);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const deleteTemplate = (item) => {
        console.log("selectedTemplate", item)
        try {
            Swal.fire({
                // title: "Are you sure you want to delete this item?",
                text: "Are you sure you want to delete this item?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!"
            }).then((result) => {
                if (result.isConfirmed) {
                    let o = { id: item.DocTempID }
                    dispatch(PortalDocTemplateDeleted_Json_Redux(o, (res) => {
                        if (res) {
                            toast.success("Template Deleted");
                            getAllTemplates(txtFolderid)
                        }
                    }))
                }
            });
        } catch (error) {
            console.log("Delete Template", error)
        }
    }

    const handleSearchChange = (e) => {
        setsearchString(e.target.value)
    }

    React.useEffect(() => {
        setTimeout(() => {
            if (searchString.length > 2) {
                const filteredTemplates = allTempList.filter(template =>
                    template.TemplateName &&
                    template.TemplateName.toLowerCase().includes(searchString.toLowerCase())
                );
                setShowTemplateList(filteredTemplates);
            } else {
                setShowTemplateList(allTempList)
            }
        }, 1000)
    }, [searchString, allTempList])

    return (
        <Box className="container">
            <Box className="row">
                <Box className='col-xxl-10 m-auto'>
                    <Box className=''>
                        <Box className="d-flex align-items-center justify-content-between">
                            <Box className='search-input'
                                sx={{
                                    width: 340,
                                    maxWidth: '100%',
                                }}>
                                <SearchIcon />
                                <TextField fullWidth label="Search" size='small' onChange={handleSearchChange} />
                            </Box>
                            {/* <Button variant='contained' className='btn-blue'>Create Folder</Button> */}

                            <Button
                                variant="contained"       // Button variant 'contained' for a filled style
                                className="btn-blue"       // Custom class for additional styling
                                onClick={() => {          // Event handler triggered on button click
                                    navigate('/user/templates/create-templates');  // Navigate to a new route
                                    dispatch(setRequestDocument_Redux({}));      // Dispatch a Redux action
                                }}
                                startIcon={<CreateIcon />} // Adds an icon before the text in the button
                            >
                                Create Template           
                            </Button>
                        </Box>

                        <hr />

                        {isLoding ? (
                            <>
                                <CustomLoader />
                            </>
                        ) : getFolderList.length > 0 ? (
                            <>
                                <Box
                                    sx={{ flexGrow: 1, display: 'flex', }} className='custom-tabs'
                                >
                                    <Tabs
                                        orientation="vertical"
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="Vertical tabs example"
                                        className='text-left tabs-vertical'
                                        sx={{ borderRight: 1, borderColor: 'divider', minWidth: '280px' }}
                                    >
                                        {getFolderList && getFolderList.length > 0 ? getFolderList.map((item, index) => {
                                            // if(item.FolderID===FolderId){
                                            //     return <Tab className='text-left' icon={<PersonIcon />} iconPosition="start" label={item.Folder} {...a11yProps(index)} />
                                            // }
                                            return (<div style={{ overflow: "hidden" }} key={index}>
                                                <Tab className='text-left w-100' key={index} onClick={() => handleClickFolder(item)} icon={<PersonIcon />} iconPosition="start" label={item.Folder} {...a11yProps(index)} />
                                            </div>)
                                        }) : ""}
                                    </Tabs>

                                    <Box className='w-100 custom-tabs-list' sx={{ height: 'calc(100vh - 210px)', overflow: 'auto' }} >
                                        <TabPanel value={value} className='p-0 tabs-list' index={0}>
                                            <MenuList className='p-0'>
                                                {/* {templateList.length>0? <CustomLoader></CustomLoader>:null} */}

                                                {showTemplateList && showTemplateList.length > 0 ? showTemplateList.map((item, index) => (
                                                    <TemplateMenuItem key={index} item={item} setSelectedTemplate={setSelectedTemplate} handleClickOpenModal={handleClickOpenModal} deleteTemplate={deleteTemplate} />
                                                )) :
                                                    <NoData message='No Attachments here'></NoData>
                                                }
                                            </MenuList>
                                        </TabPanel>
                                        <TabPanel value={value} className='p-0' index={1}>
                                            Item Two
                                        </TabPanel>
                                        <TabPanel value={value} className='p-0' index={2}>
                                            Item Three
                                        </TabPanel>
                                        <TabPanel value={value} className='p-0' index={3}>
                                            Item Four
                                        </TabPanel>
                                        <TabPanel value={value} className='p-0' index={4}>
                                            Item Five
                                        </TabPanel>
                                        <TabPanel value={value} className='p-0' index={5}>
                                            Item Six
                                        </TabPanel>
                                        <TabPanel value={value} className='p-0' index={6}>
                                            Item Seven
                                        </TabPanel>
                                    </Box>
                                </Box>
                            </>
                        ) : getFolderList == 0 ? (
                            <>
                                <NoData messsage='No Attachments here' />
                            </>
                        ) : ''}
                    </Box>
                </Box>
            </Box>

            <Dialog
                // sx={{ maxWidth: '1600px', width: '100%' }}
                maxWidth="lg"
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='custom-modal modal-request-document modal-lg'
            >
                <Box className="d-flex align-items-center justify-content-between modal-head">
                    <Box className="clearfix">
                        <DialogTitle>{"Create New Template"}</DialogTitle>
                    </Box>
                    <Button onClick={handleCloseModal} autoFocus>
                        <CloseIcon />
                    </Button>
                </Box>

                <DialogContent >

                    <DialogContentText id="alert-dialog-description">
                        <CreateTemplate isHideTitle={false} selectedTemplate={selectedTemplate} />
                    </DialogContentText>

                    <DialogActions className='pt-4 px-0'>
                        <Button onClick={handleCloseModal} className='btn-red' variant="outlined" startIcon={<CloseIcon />}>Cancel</Button>
                        <Button onClick={handleCloseModal} className='btn-blue' autoFocus variant="outlined" startIcon={<CreateIcon />}>
                            Save Template
                        </Button>
                    </DialogActions>
                </DialogContent>

            </Dialog>

        </Box>
    );
}