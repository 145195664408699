import ApartmentIcon from "@mui/icons-material/Apartment";
import TextField from "@mui/material/TextField";
import EmailIcon from "@mui/icons-material/Email";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import PersonIcon from "@mui/icons-material/Person";
import SearchIcon from "@mui/icons-material/Search";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import { Box, Grid, InputBase } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import DataGrid, {
  Column,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomLoader from "../components/CustomLoader";
import NoData from "../components/NoData";
import {
  setContactRequestDocCount,
  setMessageReadUnreadCountDetails,
} from "../redux/dev_chetan_slice";
import AutoComplete from "../components/AutoComplete";
import { GetAllFolders_Json_Redux } from "../redux/api_helper";
import { getContactsListByFolder, Json_GetSupplierListByProject } from "../redux/dev_chetan_api_helper";

const pageSizes = [10, 25, 50, 100];

export default function ContactGrid({ dataSourceOptions }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allContacts, allContactsLoding } = useSelector(
    (state) => state.dev_chetan
  );
  const [isLoding, setIsLoding] = useState(false);
  const [view, setView] = React.useState("list");
  const [activeBtn, setActiveBtn] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [getFolder, setGetFolders] = React.useState([]);
  const [selectedFolder, setSelectedFolder] = React.useState();

  React.useEffect(() => {
    dispatch(
      GetAllFolders_Json_Redux(function (res) {
        setGetFolders(res);
        setSelectedFolder(res[0]);
      })
    );
  }, [dispatch]);

  const getFolderObject = (event, value) => {
    setSelectedFolder(value)
  };

  // Filter contacts based on the search query
  const filteredContacts = allContacts?.filter((contact) =>
    Object.values(contact).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const highlightText = (text) => {
    if (!searchQuery.trim()) return text;

    const parts = text.split(new RegExp(`(${searchQuery})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === searchQuery.toLowerCase() ? (
        <span key={index} style={{ backgroundColor: "yellow" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const handleClick = (clickbutton) => () => {
    setActiveBtn(clickbutton);
  };
  useEffect(() => {
    if (allContacts) {
      setIsLoding(true);
    }
  });
  const [collapsed, setCollapsed] = useState(true);
  const onContentReady = useCallback(
    (e) => {
      if (collapsed) {
        e.component.expandRow(["EnviroCare"]);
        setCollapsed(false);
      }
    },
    [collapsed]
  );

  const handleRowDoubleClick = (e) => {
    navigate(
      `/user/contact/contacts/contact-details?orgNo=${e.data.OriginatorNo}&contactNo=${e.data.ContactNo}&emailAddress=${e.data["E-Mail"]}`
    );
    dispatch(setMessageReadUnreadCountDetails([]));
    dispatch(setContactRequestDocCount([]));
  };
  const handleChange = (event, nextView) => {
    setView(nextView);
  };

  const handleCardClick = (contact) => {
    navigate(
      `/user/contact/contacts/contact-details?orgNo=${contact.OriginatorNo}&contactNo=${contact.ContactNo}&emailAddress=${contact["E-Mail"]}`
    );
  };

  React.useEffect(() => {
    if (selectedFolder) {
      dispatch(getContactsListByFolder(selectedFolder));
    }
  }, [selectedFolder]);

  return (
    <Box className='relative'>

      <Box className='row search-area'>
      <Grid container spacing={2} >
        <Grid item xs={6}>
          <Box className='bg-white'>
            <AutoComplete
              defaultValue={selectedFolder}
              disablePortal
              lebel="Folder"
              target="Folder"
              id="combo-box-demo"
              ComData={getFolder}
              getOptionLabel={(option) => option.Folder}
              comFun={getFolderObject}
              sx="small"
              renderInput={(params) => <TextField {...params} label="Folder" />}
              className="form-control mb-3"
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box className="search-input mb-3">
            <SearchIcon />
            <TextField
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
              fullWidth
              label="Search"
              size="small"

            // onChange={(e) =>
            //   console.log(e.target.value, "=============")
            // }
            />
          </Box>
        </Grid>
        </Grid>

      </Box>

      {allContactsLoding ? (
        <>
          <CustomLoader />
        </>
      ) : allContacts && allContacts.length > 0 ? (
        <>
          <Box className="text-end relative">
            <ToggleButtonGroup
              // orientation="vertical"
              value={view}
              exclusive
              onChange={handleChange}
              className="table-toggle-btn"
            >
              <ToggleButton
                value="list"
                aria-label="list"
                onClick={handleClick(1)}
              >
                <ViewListIcon />
              </ToggleButton>
              <ToggleButton
                value="module"
                aria-label="module"
                onClick={handleClick(2)}
              >
                <ViewModuleIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          {activeBtn === 1 && (
            <>

              {/* <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #7a7a7a",
                  // height: "50px",
                  background: "#fff",
                  borderRadius: "5px",
                  width: "30%",
                  marginBottom: "10px",
                }}
              >
                <InputBase
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                  size="small"
                  sx={{
                    flex: 1,
                    paddingLeft: "20px",
                    paddingRight: "10px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                />
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                  size="small"
                >
                  <SearchIcon />
                </IconButton>
              </Box> */}

              {/* <Grid container rowSpacing={0} columnSpacing={2}> */}
              <Box className='row gx-3'>
                {filteredContacts.length > 0 ? (
                  filteredContacts.map((contact, index) => (
                    <Box
                      item
                      // xs={12}
                      // sm={12}
                      // md={6}
                      // lg={6}
                      // xl={4}
                      // xxl={4}
                      className='col-xl-4 col-md-6 col-sm-12'
                      key={index}
                    >
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          cursor: "pointer",
                        }}
                        className="white-box mb-3"
                        // onClick={() => handleCardClick(contact)}
                        onDoubleClick={() => handleCardClick(contact)}
                      >
                        <ul className="contact-list" style={{ width: "100%" }}>
                          <li>
                            <span className="poppins-semibold">
                              <FormatListNumberedIcon /> Originator Number:{" "}
                            </span>
                            <span>{highlightText(contact.OriginatorNo)}</span>
                          </li>
                          <li>
                            <span className="poppins-semibold">
                              <ApartmentIcon /> Company Name:
                            </span>
                            <span>
                              {" "}
                              {highlightText(contact["Company Name"])}
                            </span>
                          </li>
                          <li>
                            <span className="poppins-semibold">
                              <PersonIcon /> First Name:
                            </span>
                            <span> {highlightText(contact["First Name"])}</span>
                          </li>
                          <li>
                            <span className="poppins-semibold">
                              <PersonIcon /> Last Name:
                            </span>
                            <span> {highlightText(contact["Last Name"])}</span>
                          </li>
                          <li>
                            <span className="poppins-semibold">
                              <EmailIcon /> Email:
                            </span>
                            <span>{highlightText(contact["E-Mail"])}</span>
                          </li>
                        </ul>
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Grid item xs={12}>
                    <div>No Data Found</div>
                  </Grid>
                )}
              </Box>
            </>
          )}

          {activeBtn === 2 && (
            <DataGrid
              dataSource={allContacts}
              allowColumnReordering={true}
              rowAlternationEnabled={true}
              showBorders={true}
              className="table-grid"
              onRowDblClick={handleRowDoubleClick}
              width="100%"
            // onContentReady={onContentReady}
            >
              {/* <GroupPanel visible={true} /> */}
              <SearchPanel visible={true} highlightCaseSensitive={true} />
              {/* <Grouping autoExpandAll={false} /> */}
              <Column
                dataField="OriginatorNo"
                caption="Originator No"
                dataType="string"
              />
              <Column
                dataField="Company Name"
                caption="Company Name"
                dataType="string"
              />
              <Column
                dataField="First Name"
                caption="First Name"
                dataType="string"
              />
              <Column
                dataField="Last Name"
                caption="Last Name"
                dataType="string"
              />
              <Column dataField="E-Mail" caption="E Mail" dataType="string" />
              <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
              <Paging defaultPageSize={10} />
            </DataGrid>
          )}
        </>
      ) : (
        <>
          <NoData message={"No Data Here"} />
        </>
      )}
    </Box>
  );
}
