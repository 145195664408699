import React from 'react'
import DownloadIcon from '@mui/icons-material/Download';
import { Box, Button, Tooltip, Typography, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import pdf from "../assets/images/files-icon/pdf.png";
import GetFileType from './FileType';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const SimpleFileUpload = ({ comFun, fileName, accept, Type, closeBox, closeBtn, closeFun }) => {
  return (
    <Box className='text-start'>
      {closeBox ? (
        <Box className='d-flex align-items-center'>
          <GetFileType Type={Type} />
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '550',
            }}
            className='ms-1'
          >
            {fileName}
          </Typography>
          <Tooltip title="Remove" arrow>
            <Button size='small' className='min-width-auto' onClick={closeFun}>
              <CloseIcon className='font-18 text-red' />
            </Button>
          </Tooltip>
        </Box>
      ) : null}

      {closeBtn ? (
        <Button
          component='label'
          role={undefined}
          variant='contained'
          className='btn-blue'
          size='small'
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
        >
          Upload file
          <VisuallyHiddenInput type='file' onChange={comFun} accept={accept} />
        </Button>
      ) : null}
    </Box>
  );
};

export default SimpleFileUpload