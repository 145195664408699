import './App.css';
import CustomRoutes from './routes/CustomRoutes';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <div className="App ">
      <CustomRoutes />
      <ToastContainer/>
    </div>
  );
}

export default App;