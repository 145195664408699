import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";

const ClientLogout = () => {
    const navigate = useNavigate();
    useEffect(()=>{
        localStorage.clear();
        navigate("/client/ClientLogin");
    },[]);
  return (
    <div>
      
    </div>
  )
}

export default ClientLogout
