import React from 'react'

const Settings = () => {
  return (
    <div>
      settings
    </div>
  )
}

export default Settings



// import { Outlet } from 'react-router-dom'
// import * as React from 'react';
// import PropTypes from 'prop-types';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
// import Typography from '@mui/material/Typography';
// import Box from '@mui/material/Box';

// import { useSearchParams } from 'react-router-dom'
// import My_Profile from './My_Profile';
// import Two_Factor_Authentication from './Two_Factor_Authentication';
// import My_preference from './My_preference';
// import My_signature from './My_signature ';
// function CustomTabPanel(props) {
//     const { children, value, index, ...other } = props;

//     return (
//         <div
//             role="tabpanel"
//             hidden={value !== index}
//             id={`simple-tabpanel-${index}`}
//             aria-labelledby={`simple-tab-${index}`}
//             {...other}
//         >
//             {value === index && (
//                 <Box sx={{ p: 3 }}>
//                     <Typography>{children}</Typography>
//                 </Box>
//             )}
//         </div>
//     );
// }

// CustomTabPanel.propTypes = {
//     children: PropTypes.node,
//     index: PropTypes.number.isRequired,
//     value: PropTypes.number.isRequired,
// };

// function a11yProps(index) {
//     return {
//         id: `simple-tab-${index}`,
//         'aria-controls': `simple-tabpanel-${index}`,
//     };
// }




// function Settings() {
//   const [searchParams] = useSearchParams();
//   const id = searchParams.get('id');
//   const request = searchParams.get('request');
//   const [value, setValue] = React.useState(0);
//   React.useEffect(() => {
//       a11yProps(1);
//       setValue(id ? 1 : 0);
//   }, [id])


//   const handleChange = (event, newValue) => {
//       setValue(newValue);
//   };
//   return (
//     <>
//     <Box>
//             <Typography class="title">
//                 Invite Overview
//             </Typography>


//             <Box sx={{ width: '100%' }} className='custom-tabs'>
//                 <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
//                     <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
//                         <Tab label="My_Profile" {...a11yProps(0)} />
//                         <Tab label="Two_Factor_Authentication" {...a11yProps(1)} />
//                         <Tab label="My_preference" {...a11yProps(2)} />
//                         <Tab label="My_signature" {...a11yProps(3)} />  
//                         <Tab label="MESSAGES" {...a11yProps(4)} />  
//                         <Tab label="MESSAGES" {...a11yProps(5)} />
                      
//                     </Tabs>
//                 </Box>
             
//                 <CustomTabPanel className='p-0' value={value} index={0}>
//                    <My_Profile/>
//                 </CustomTabPanel>
//                 <CustomTabPanel className='p-0' value={value} index={1}>
//                 <Two_Factor_Authentication/>
//                 </CustomTabPanel>
//                 <CustomTabPanel className='p-0' value={value} index={2}>
//                    <My_preference/>
//                 </CustomTabPanel>
//                 <CustomTabPanel className='p-0' value={value} index={3}>
//                <My_signature/>
//                 </CustomTabPanel>
//                 <CustomTabPanel className='p-0' value={value} index={4}>
//                   aa
//                 </CustomTabPanel>
//                 <CustomTabPanel className='p-0' value={value} index={5}>
//                   aa
//                 </CustomTabPanel>
//             </Box>


//         </Box>
   
//     <Outlet/>

//     </>
//   )
// }

// export default Settings
