import EditUDFClientCard from "../../components/EditUDFClientCard";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import FlagIcon from "@mui/icons-material/Flag";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { height, styled } from "@mui/system";

import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import profileBG from "../../assets/images/profile.jpg";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import FileUpload from "../../components/FileUpload";
import {
  ContectDetails_Redux,
  getClientAMlChekDetailasProfile,
  GetClientContactDetailasProfile,
  GetPortalContactDetailsByEmail,
  GetSignature_Json_Redux,
  Json_GetCRMContactUDFValues_Redux1,
  Json_Portal_GetClientListByEmail_Redux,
  SignatureRemoved,
  UDF_Add_Redux1,
  updateClientContactDetailasProfile,
} from "../../redux/ak_api_helper";
import {
  setOpenSignDocument,
  setOrigantryNumber,
  setWhatappUpdate,
  setWhatsAppNumber,
  setWhatsAppValidModal,
} from "../../redux/modalSlice";

import PersonIcon from "@mui/icons-material/Person";

import DocuTextField from "../../cmp-utils/DocuTextField";
import { SignRequestedDocument_Json } from "../../redux/api_helper";
import { toast } from "react-toastify";
import CustomeSignature from "../../components/CustomeSignature";
import {
  clientBodyaccIdData,
  clientBodyaccidData,
} from "../../utils/RequestsFunction";
import { GetCRMContactUDFValueAction } from "../../redux/dev_aditya_action";

function ClientProfile() {
  const [openSignature, setOpenSignature] = React.useState(false);
  const handleClickOpenSignature = () => {
    
    dispatch(GetSignature_Json_Redux(clientBodyaccidData));
    setOpenSignature(true);
  };

  const handleCloseSignature = () => {
    setOpenSignature(false);
  };

  const dispatch = useDispatch();

  const obj1 = {
    accId: localStorage.getItem("accIdClient")
      ? localStorage.getItem("accIdClient")
      : "",
    username: localStorage.getItem("EmailClient")
      ? localStorage.getItem("EmailClient")
      : "",
    sPassword: localStorage.getItem("passwordClient")
      ? localStorage.getItem("passwordClient")
      : "",
  };

  const obj12 = {
    accid: localStorage.getItem("agrno"),
    email: localStorage.getItem("Email"),
    password: localStorage.getItem("password")
  };

  useEffect(() => {
    dispatch(Json_Portal_GetClientListByEmail_Redux());
    dispatch(GetPortalContactDetailsByEmail(obj1));
    dispatch(GetClientContactDetailasProfile(obj1));
  }, []);

  const { CRMContactUDFValues } = useSelector((state) => state.ak);
  const { ContectDetails1 } = useSelector((state) => state.ak);
  const { GetFolders, udf1 } = useSelector((state) => state.ak);
  const filteredUdf = udf1.filter((item) => item.ClientView === true);

  const { AgreementListDetails } = useSelector((state) => state.ak);

  const { data } = useSelector((state) => state.ak);
  const { originFullDetails } = useSelector((state) => state.ak);
  // const {data}
  const getClientContactDetailas = useSelector(
    (state) => state.ak.getClientContactDetailas?.getClientContactDetailas || ""
  );
  const getClientContactDetailasProfile = useSelector(
    (state) =>
      state.ak.getClientContactDetailasProfile
        .getClientContactDetailasProfile || ""
  );

  const [countryName, setCountryName] = useState("");
  const [number, setNumber] = useState("");
  const [verifiedWhatapp, setVerifiedWhatapp] = useState();
  const [countryData, setCountryData] = useState(null);
  const [error, setError] = useState("");
  const { UdfNameId } = useSelector((state) => state.ak);
  const [selectedOption, setSelectedOption] = useState({});
  const [selectOrnitoNo, setselectOrginitoNo] = useState({});

  const [amlDoc, setAmlDoc] = useState([]);

  const { signature } = useSelector((state) => state.ak);

  useEffect(() => {
    if (Object.keys(UdfNameId).length > 0) {
      setselectOrginitoNo(UdfNameId[0]);
      let reqObj = {
        AccID: localStorage.getItem("accIdClient"),
        strEmailId: localStorage.getItem("EmailClient"),
        password: localStorage.getItem("passwordClient"),
        CliientId: UdfNameId[0].OriginatorNo,
      };
      dispatch(ContectDetails_Redux(reqObj));
      let obj12 = {
        ...clientBodyaccIdData,
        strProjectId: "15",
        strOrignatorNumber: UdfNameId[0].OriginatorNo,
      };

      dispatch(UDF_Add_Redux1(obj12));
    }
  }, [UdfNameId]);

  useEffect(() => {
    if (Object.keys(selectOrnitoNo).length > 0) {
      dispatch(setOrigantryNumber(selectOrnitoNo.OriginatorNo));
      dispatch(
        GetCRMContactUDFValueAction({
          Accid: localStorage.getItem("accIdClient"),
          username: localStorage.getItem("EmailClient"),
          sPassword: localStorage.getItem("passwordClient"),
          ClientId: selectOrnitoNo.OriginatorNo,
        })
      );
    }
  }, [selectOrnitoNo]);

  const [OriginatorNoset, setOriginatorNoset] = useState(null);

  const [avatarSrc, setAvatarSrc] = useState("/static/images/avatar/1.jpg");
  const [anchorEl, setAnchorEl] = useState(null);

  const [signaturePreview, setSignaturePreview] = useState(true);
  const [signatureSetup, setSignatureSetup] = useState(false);
  const [signatureSetupVisible, setSignatureSetupVisibleSetting] =
    useState(true);

  const handleSelect = (event, value) => {
    try {
      if (value) {
        setselectOrginitoNo(value);
        dispatch(setOrigantryNumber(value.OriginatorNo));
        setSelectedOption(value);
      } else {
        setSelectedOption({ "Company Name": "", OriginatorNo: null });
      }
    } catch (error) {
      console.error("An error occurred in h8andleSelect:", error);
    }
  };

  useEffect(() => {
    handleSelect();
  }, []);

  const openWhatAppModal = () => {
    dispatch(setWhatsAppValidModal(true));
  };

  // start Edit Funcanility

  const [editModal, setEditModal] = useState(true);
  const [editButtonShow, setEditButtonShow] = useState(true);
  const [formData, setFormData] = useState({
    Active: "",
    Add1: "",
    Add2: "",
    Add3: "",
    "Assigned Manager": "",
    "Company Name": "",
    ContactNo: "",
    Country: "",
    State: "",
    "E-Mail": "",
    "First Name": "",
    Folder: "",
    Greeting: "",
    "Last Name": "",
    "Main Contact": "",
    ManagerName: "",
    Mobile: "",
    OriginatorNo: "",
    OtherEmail: "",
    Postcode: "",
    Role: "",
    Tel: "",
    Title: "",
    Town: "",
  });

  useEffect(() => {
    setFormData({
      ManagerName:
        (getClientContactDetailasProfile &&
          getClientContactDetailasProfile[0].ManagerName) ||
        "",
      Add1:
        (getClientContactDetailasProfile &&
          getClientContactDetailasProfile[0]["Add1"]) ||
        "",
      Add2:
        (getClientContactDetailasProfile &&
          getClientContactDetailasProfile[0]["Add2"]) ||
        "",
      Add3:
        (getClientContactDetailasProfile &&
          getClientContactDetailasProfile[0]["Add3"]) ||
        "",
      Country:
        (getClientContactDetailasProfile &&
          getClientContactDetailasProfile[0].Country) ||
        "",
      State:
        (getClientContactDetailasProfile &&
          getClientContactDetailasProfile[0].State) ||
        "",
      Town:
        (getClientContactDetailasProfile &&
          getClientContactDetailasProfile[0].Town) ||
        "",
      PostCode:
        (getClientContactDetailasProfile &&
          getClientContactDetailasProfile[0].PostCode) ||
        "",
    });
  }, [getClientContactDetailasProfile]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const updateContactDetailas = () => {
    let newObject = { ...formData, ...obj1 };
    try {
      dispatch(updateClientContactDetailasProfile(newObject)).then(() =>
        dispatch(GetClientContactDetailasProfile(obj1))
      );
    } catch (error) {}
    setEditButtonShow(!editButtonShow);
    setEditModal(!editModal);
  };

  const hanldeEditClick = () => {
    setEditButtonShow(!editButtonShow);
    setEditModal(!editModal);
  };

  useEffect(() => {
    if (originFullDetails != "") {
      setCountryName(
        getClientContactDetailas && getClientContactDetailas.Country
      );
      setVerifiedWhatapp(
        getClientContactDetailas && getClientContactDetailas.blnContactVarified
      );

      setNumber(
        getClientContactDetailas && getClientContactDetailas.Mobile
          ? getClientContactDetailas.Mobile
          : ""
      );
      if (
        (getClientContactDetailas &&
          getClientContactDetailas.Mobile == undefined) ||
        (getClientContactDetailas && getClientContactDetailas.Mobile == null) ||
        (getClientContactDetailas && getClientContactDetailas.Mobile == "") ||
        (getClientContactDetailas.getClientContactDetailas &&
          getClientContactDetailas.Mobile == "") ||
        (getClientContactDetailas &&
          getClientContactDetailas.blnContactVarified == false)
      ) {
        dispatch(setWhatappUpdate(true));
      }
    }
  }, [handleSelect, openWhatAppModal]);

  useEffect(() => {
    if (!countryName) {
      setError("The input field cannot be empty");
      setCountryData(null);
      return;
    }
    const finalURL = `https://restcountries.com/v3.1/name/${countryName.trim()}?fullText=true`;
    fetch(finalURL)
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "Not Found") {
          setError("Country Information is not Found");
          setCountryData(null);
        } else if (data.length === 0) {
          setError("Please enter a valid country name.");
          setCountryData(null);
        } else {
          setError("");

          let originaData =
            number + setCountryData(+data[0].idd.root + data[0].idd.suffixes);

          let countryData = data[0].idd.root + data[0].idd.suffixes;
          countryData = countryData && countryData.replace("+", "");

          dispatch(setWhatsAppNumber(countryData + number));
        }
      })
      .catch(() => {
        setError("An error occurred while fetching data.");
        setCountryData(null);
      });
  }, [getClientContactDetailas]);

  const handleAvatarClick = () => {
    document.getElementById("avatar-input").click();
  };

  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setAvatarSrc(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChangeSignature = async() => {
    const obj12 = {
      accid: localStorage.getItem("agrno"),
      email: localStorage.getItem("Email"),
      password: localStorage.getItem("password")
    };
    // dispatch(SignatureRemoved(obj12));
    setSignaturePreview(false);
    setSignatureSetup(true);
  };

  const base64String = signature;
  const format = "png";
  const ImageBox = styled(Box)({
    width: "100%",
    height: "auto",
  });

  return (
    <Box>
      <Box className="container p-0">
        <Box className="profile-box white-box p-0">
          <Box className="relative">
            <Button
              className="btn-blue me-2 btn-signature"
              endIcon={<EditIcon />}
              onClick={handleClickOpenSignature}
            >
              {" "}
              Add Signature
            </Button>
          </Box>
          <img src={profileBG} className="w-100 height-130" />

          <Box className="d-flex justify-content-between align-items-center px-4 flex-wrap">
            <Box className="">
              <Box className="d-flex mt-3 mb-2">
                <PhoneIphoneIcon className="font-32 me-2 text-blue" />
                <Box>
                  <Typography className="poppins-medium">
                    Contact Number
                  </Typography>
                  <Typography className="font-14 text-gray">
                    {/* +911234567890 */}
                    {getClientContactDetailas && getClientContactDetailas.Mobile
                      ? getClientContactDetailas.Mobile
                      : ""}
                  </Typography>
                </Box>
              </Box>

              <Box className="d-flex mb-3">
                <WhatsAppIcon className="font-32 me-2 text-blue" />
                <Box>
                  <Typography className="poppins-medium">WhatsApp</Typography>
                  {verifiedWhatapp ? (
                    <>
                      <Typography className="font-14 text-success poppins-medium">
                        <span>
                          <CheckCircleOutlineIcon className="font-20" />{" "}
                          Verified
                        </span>
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography
                        className="font-16 text-gray poppins-medium "
                        onClick={openWhatAppModal}
                      >
                        Not Verified{" "}
                        <Button
                          id="basic-button"
                          aria-haspopup="true"
                          className="btn-blue me-3"
                          onClick={openWhatAppModal}
                          sx={{ height: "20px", width: "130px" }}
                        >
                          Click to Verified
                        </Button>
                      </Typography>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
            <Box className="text-center order-first order-md-0 m-auto">
              <Stack className="m-auto d-inline-block user-profile-img">
                <Box className="inner-user-profile-img">
                  <Avatar
                    alt="Mr."
                    src={avatarSrc}
                    sx={{
                      width: 80,
                      height: 80,
                      cursor: "pointer",
                      margin: "0 auto",
                    }}
                    onClick={handleAvatarClick}
                  />
                  <input
                    type="file"
                    id="avatar-input"
                    style={{ display: "none" }}
                    onChange={handleAvatarChange}
                  />

                  <Tooltip title="UK" arrow>
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/13/United-kingdom_flag_icon_round.svg/2048px-United-kingdom_flag_icon_round.svg.png"
                      className="profile-flat"
                    />
                  </Tooltip>
                </Box>
                <Box className="mt-2">
                  <Typography className="font-18 poppins-medium">
                    {originFullDetails ? originFullDetails.Title : ""}{" "}
                    {originFullDetails ? originFullDetails["First Name"] : ""}{" "}
                    {originFullDetails ? originFullDetails["Last Name"] : ""}
                  </Typography>
                  <Typography className="font-12 text-gray">
                    {originFullDetails?.Role}
                  </Typography>

                  <Box className="mt-2">
                    <Button
                      sx={{ cursor: "pointer" }}
                      onClick={handleMenuClick}
                      className="btn-blue"
                      endIcon={<KeyboardArrowDownIcon />}
                    >
                      {selectOrnitoNo["Company Name"] || ""}
                    </Button>

                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                      className="custom-dropdown"
                    >
                      {UdfNameId.map((client) => (
                        <MenuItem
                          key={client.OriginatorNo}
                          onClick={(event) => {
                            handleSelect(event, client);
                            handleMenuClose();
                          }}
                        >
                          {client["Company Name"]}
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                </Box>
              </Stack>
            </Box>
            <Box>
              <Box className="d-flex mb-3 mt-3">
                <EmailIcon className="font-32 me-2 text-blue" />
                <Box>
                  <Typography className="poppins-medium">E-Mail ID</Typography>
                  <Typography className="font-14 text-gray">
                    {getClientContactDetailas &&
                    getClientContactDetailas.EMailId
                      ? getClientContactDetailas.EMailId
                      : ""}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* editModal */}

        <Grid container rowSpacing={0} columnSpacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            xxl={4}
            className="d-flex"
          >
            <Box className="white-box  w-100">
              <h2 className="title font-18">AML Documents</h2>
              <hr />

              <Box className="">
                <FileUpload
                  selectedFiles={amlDoc}
                  setSelectedFiles={setAmlDoc}
                />
              </Box>

              <Box className="">
                <FileUpload
                  selectedFiles={amlDoc}
                  setSelectedFiles={setAmlDoc}
                />
              </Box>
            </Box>
          </Grid>

          {/* editModal */}
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
            {editModal ? (
              <Box className="white-box">
                <div className=" d-flex justify-content-between ">
                  <h2 className="title font-18">Contact Details</h2>
                  <IconButton
                    onClick={() => {
                      hanldeEditClick();
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </div>
                <hr />

                <Grid container rowSpacing={0} columnSpacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <Box className="d-flex mb-2">
                      <PersonIcon className="font-20 me-2 text-blue" />
                      <Box>
                        <Typography className="poppins-medium font-14">
                          ManagerName
                        </Typography>
                        <Typography className="font-12 text-gray">
                          {(getClientContactDetailasProfile &&
                            getClientContactDetailasProfile[0].ManagerName) ||
                            ""}
                        </Typography>
                      </Box>
                    </Box>

                    <Box className="d-flex mb-2">
                      <LocationOnIcon className="font-20 me-2 text-blue" />
                      <Box>
                        <Typography className="poppins-medium font-14">
                          Address Line 1
                        </Typography>
                        <Typography className="font-12 text-gray">
                          {(getClientContactDetailasProfile &&
                            getClientContactDetailasProfile[0].Add1) ||
                            ""}
                        </Typography>
                      </Box>
                    </Box>

                    <Box className="d-flex mb-2">
                      <LocationOnIcon className="font-20 me-2 text-blue" />
                      <Box>
                        <Typography className="poppins-medium font-14">
                          Address Line 2
                        </Typography>
                        <Typography className="font-12 text-gray">
                          {getClientContactDetailasProfile &&
                            getClientContactDetailasProfile[0].Add2}
                        </Typography>
                      </Box>
                    </Box>

                    <Box className="d-flex">
                      <LocationOnIcon className="font-20 me-2 text-blue" />
                      <Box>
                        <Typography className="poppins-medium font-14">
                          Address Line 3
                        </Typography>
                        <Typography className="font-12 text-gray">
                          {getClientContactDetailasProfile &&
                            getClientContactDetailasProfile[0].Add3}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <Box className="d-flex mb-2">
                      <LocationCityIcon className="font-20 me-2 text-blue" />
                      <Box>
                        <Typography className="poppins-medium font-14">
                          Town
                        </Typography>
                        <Typography className="font-12 text-gray">
                          {getClientContactDetailasProfile &&
                            getClientContactDetailasProfile[0].Town}
                        </Typography>
                      </Box>
                    </Box>

                    <Box className="d-flex mb-2">
                      <FlagIcon className="font-20 me-2 text-blue" />
                      <Box>
                        <Typography className="poppins-medium font-14">
                          Country
                        </Typography>
                        <Typography className="font-12 text-gray">
                          {getClientContactDetailasProfile &&
                            getClientContactDetailasProfile[0].Country}
                        </Typography>
                      </Box>
                    </Box>

                    <Box className="d-flex mb-2">
                      <FlagIcon className="font-20 me-2 text-blue" />
                      <Box>
                        <Typography className="poppins-medium font-14">
                          County
                        </Typography>
                        <Typography className="font-12 text-gray">
                          {getClientContactDetailasProfile &&
                            getClientContactDetailasProfile[0].State}
                        </Typography>
                      </Box>
                    </Box>

                    <Box className="d-flex">
                      <LocalPostOfficeIcon className="font-20 me-2 text-blue" />
                      <Box>
                        <Box>
                          <Typography className="poppins-medium font-14">
                            Post Code
                          </Typography>
                          <Typography className="font-12 text-gray">
                            {getClientContactDetailasProfile &&
                              getClientContactDetailasProfile[0]["PostCode"]}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box className="white-box">
                <div className="d-flex justify-content-between">
                  <h2 className="title font-18">Contact Details</h2>
                  <div>
                    <IconButton
                      onClick={() => {
                        hanldeEditClick();
                      }}
                    >
                      <CloseIcon />
                    </IconButton>

                    <Button
                      id="basic-button"
                      aria-haspopup="true"
                      className="btn-blue "
                      onClick={() => {
                        updateContactDetailas();
                      }}
                    >
                      Update
                    </Button>
                  </div>
                </div>
                <hr />

                <Grid container rowSpacing={0} columnSpacing={2}>
                  <Grid item xs={12} sm={4} md={4} lg={4} cl={4} xxl={4}>
                    <DocuTextField
                      size={"small"}
                      label={"ManagerName"}
                      fieldValue={formData.ManagerName}
                      id={"ManagerName"}
                      fieldName={"ManagerName"}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} cl={4} xxl={4}>
                    <DocuTextField
                      size={"small"}
                      label={"Address Line 1"}
                      fieldValue={formData.Add1}
                      id={"Add1"}
                      fieldName={"Add1"}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} cl={4} xxl={4}>
                    <DocuTextField
                      size={"small"}
                      label={"Address Line 2"}
                      fieldValue={formData.Add2}
                      id={"Add2"}
                      fieldName={"Add2"}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} cl={4} xxl={4}>
                    <DocuTextField
                      size={"small"}
                      label={"Address Line 3"}
                      fieldValue={formData.Add3}
                      id={"Add3"}
                      fieldName={"Add3"}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} cl={4} xxl={4}>
                    <DocuTextField
                      size={"small"}
                      label={"Town"}
                      fieldValue={formData.Town}
                      id={"Town"}
                      fieldName={"Town"}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} cl={4} xxl={4}>
                    <DocuTextField
                      size={"small"}
                      label={"Country"}
                      fieldValue={formData.Country}
                      id={"Country"}
                      fieldName={"Country"}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} cl={4} xxl={4}>
                    <DocuTextField
                      size={"small"}
                      label={"County"}
                      fieldValue={formData.State}
                      id={"State"}
                      fieldName={"State"}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} cl={4} xxl={4}>
                    <DocuTextField
                      size={"small"}
                      label={"Postcode"}
                      fieldValue={formData.PostCode}
                      id={"Postcode"}
                      fieldName={"Postcode"}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}

            <Box className="white-box">
              <h2 className="title font-18">AML Check</h2>
              <hr />

              <Grid container rowSpacing={0} columnSpacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <Box className="d-flex mb-2">
                    <LocationOnIcon className="font-20 me-2 text-blue" />
                    <Box>
                      <Typography className="poppins-medium font-14">
                        Bank Account Number
                      </Typography>
                      <Typography className="font-12 text-gray">
                        021616506414
                      </Typography>
                    </Box>
                  </Box>

                  <Box className="d-flex mb-2">
                    <LocationOnIcon className="font-20 me-2 text-blue" />
                    <Box>
                      <Typography className="poppins-medium font-14">
                        Bank SR Code
                      </Typography>
                      <Typography className="font-12 text-gray">
                        16516645
                      </Typography>
                    </Box>
                  </Box>

                  <Box className="d-flex mb-2">
                    <LocationOnIcon className="font-20 me-2 text-blue" />
                    <Box>
                      <Typography className="poppins-medium font-14">
                        Driving License Number
                      </Typography>
                      <Typography className="font-12 text-gray">
                        4565-4654-7987
                      </Typography>
                    </Box>
                  </Box>

                  <Box className="d-flex">
                    <LocationOnIcon className="font-20 me-2 text-blue" />
                    <Box>
                      <Typography className="poppins-medium font-14">
                        NI Number
                      </Typography>
                      <Typography className="font-12 text-gray">
                        5995494984
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <Box className="d-flex mb-2">
                    <LocationOnIcon className="font-20 me-2 text-blue" />
                    <Box>
                      <Typography className="poppins-medium font-14">
                        Passport Number
                      </Typography>
                      <Typography className="font-12 text-gray">
                        98494651654
                      </Typography>
                    </Box>
                  </Box>

                  <Box className="d-flex mb-2">
                    <LocationOnIcon className="font-20 me-2 text-blue" />
                    <Box>
                      <Typography className="poppins-medium font-14">
                        Personal UTR Number
                      </Typography>
                      <Typography className="font-12 text-gray">
                        954987897987
                      </Typography>
                    </Box>
                  </Box>

                  <Box className="d-flex mb-2">
                    <LocationOnIcon className="font-20 me-2 text-blue" />
                    <Box>
                      <Typography className="poppins-medium font-14">
                        Photo ID Verified
                      </Typography>
                      <Typography className="font-12 text-success poppins-medium">
                        <span>
                          <HowToRegIcon className="font-22" /> Verified
                        </span>
                      </Typography>
                    </Box>
                  </Box>

                  <Box className="d-flex">
                    <LocationOnIcon className="font-20 me-2 text-blue" />
                    <Box>
                      <Box>
                        <Typography className="poppins-medium font-14">
                          Address Verified
                        </Typography>
                        <Typography className="font-12 text-danger poppins-medium">
                          <span>
                            <CloseIcon className="font-20" /> Not Verified
                          </span>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            {udf1 && udf1.length > 0 && (
              <EditUDFClientCard data={udf1} />
            )}
          </Grid>
        </Grid>
      </Box>

      {/* add signature */}
      <Dialog
        open={openSignature}
        onClose={handleCloseSignature}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="custom-modal"
        sx={{ "& .MuiDialog-paper": { width: "60%", maxWidth: "600px" } }}
      >
        <Box className="d-flex align-items-center justify-content-between modal-head">
          <Box className="clearfix">
            <DialogTitle className="p-0">
              {"Sign & Approve Document"}
            </DialogTitle>
          </Box>

          <Button onClick={handleCloseSignature} autoFocus>
            <CloseIcon />
          </Button>
        </Box>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box>
              {signaturePreview && (
                <>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      variant="h3"
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "black",
                      }}
                    >
                      {"Signature Preview"}
                    </Typography>

                    <IconButton
                      sx={{ marginTop: "-15px" }}
                      onClick={handleChangeSignature}
                    >
                      <EditIcon />
                    </IconButton>
                  </Box>
                  <Box>
                    <Box
                      sx={{
                        border: "1px solid black",
                        borderRadius: "10px",
                        background: "#fff",
                        height: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {base64String.length > 0 ? (
                        <ImageBox
                          component="img"
                          src={`data:image/${format};base64,${base64String}`}
                          alt="Base64 Image"
                          sx={{ width: 300, height: 95 }}
                        />
                      ) : (
                        <h2 className="title font-18">No Signature</h2>
                      )}
                    </Box>

                    <hr />
                  </Box>
                </>
              )}

              {signatureSetup && (
                <>
                  <Box>
                    <CustomeSignature
                      setSignaturePreview={setSignaturePreview}
                      setSignatureSetup={setSignatureSetup}
                      setSignatureSetupVisibleSetting={
                        setSignatureSetupVisibleSetting
                      }
                    />
                  </Box>
                </>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default ClientProfile;
