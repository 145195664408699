import React, { useState } from "react";
import { useSelector } from "react-redux";
function PreviewNewTAb() {
  const shareboxDocForPreviewURL = useSelector(state => state.dev_chetan.shareboxDocForPreviewURL);
  const loadingURL = 'https://res.cloudinary.com/bytesizedpieces/image/upload/v1656084931/article/a-how-to-guide-on-making-an-animated-loading-image-for-a-website/animated_loader_gif_n6b5x0.gif';
  const [isLoading, setIsLoading] = useState(true);
 
  return (
    <iframe
          src={isLoading ? loadingURL : shareboxDocForPreviewURL}
          onLoad={() => {
            setIsLoading(false);
          }}
          width="100%" // Set the width
          height="700px" // Set the height
          frameBorder="0" // Set frameborder to 0
          allowFullScreen // Allow fullscreen mode
          title="Embedded Content" // Set the title for accessibility
        ></iframe>
  );
}

export default PreviewNewTAb;
