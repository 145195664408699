import AllService from "./AllService";
export default class CommanCLS extends AllService {

    constructor(APIUrl, agrno, Email, password) {
        super(APIUrl, agrno, Email, password);
    }

    Json_getViewerToken(obj, callBack) {
        super.CreateNewServiceParamObject("Json_getViewerToken", obj, true);
        super.CallNewService("Json_getViewerToken", function (status, Data) {
            if (status) {
                return callBack(true, Data);
            }
            else {
                return callBack(false, []);
            }
        })
    }
    //PortalUsersRequestedDocs2_Json
    // PortalUsersRequestedDocs_Json (obj, callBack) {
    //     super.CreateNewServiceParamObject("PortalUsersRequestedDocs_Json ", obj, false);
    //     super.CallNewService("PortalUsersRequestedDocs_Json ", function (status, Data) {
    //         if (status) {
    //             return callBack(true, Data);
    //         }
    //         else {
    //             return callBack(false, []);
    //         }
    //     })
    // }
    PortalUsersRequestedDocs_Json (obj, callBack) {
        super.CreateNewServiceParamObject("PortalUsersRequestedDocs2_Json ", obj, false);
        super.CallNewService("PortalUsersRequestedDocs2_Json ", function (status, Data) {
            if (status) {
                return callBack(true, Data);
            }
            else {
                return callBack(false, []);
            }
        })
    }
    RequestedDocumentCreated_Json (obj, callBack) {
        super.CreateNewServiceParamObject("RequestedDocumentCreated_Json ", obj, false);
        super.CallNewService("RequestedDocumentCreated_Json ", function (status, Data) {
            if (status) {
                return callBack(true, Data);
            }
            else {
                return callBack(false, []);
            }
        })
    }

    RequestedDocumentAsByteArray_Json (obj, callBack) {
        super.CreateNewServiceParamObject("RequestedDocumentAsByteArray_Json ", obj, false);
        super.CallNewService("RequestedDocumentAsByteArray_Json ", function (status, Data) {
            if (status) {
                return callBack(true, Data);
            }
            else {
                return callBack(false, []);
            }
        })
    }
    
    Json_Portal_GetClientListByEmail (obj, callBack) {
        super.CreateNewServiceParamObject("Json_Portal_GetClientListByEmail ", obj, false);
        super.CallNewService("Json_Portal_GetClientListByEmail ", function (status, Data) {
            if (status) {
                return callBack(true, Data);
            }
            else {
                return callBack(false, []);
            }
        })
    }
//     Json_Portal_GetClientListByEmail (obj, callBack) {
//         super.CreateNewServiceParamObject("Json_Portal_GetClientListByEmail ", obj, false);
//         super.CallNewService("Json_Portal_GetClientListByEmail ", function (status, Data) {
//             if (status) {
//                 return callBack(true, Data);
//             }
//             else {
//                 return callBack(false, []);
//             }
//         })
//     }
}