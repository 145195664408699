import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Grid, Menu, MenuItem, Divider } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import LaunchIcon from "@mui/icons-material/Launch";
import { markMessageAsViewed } from "../redux/dev_chetan_api_helper";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";

function Row(props) {
  const { row, value } = props;
  const [open, setOpen] = React.useState(false);
  // dropdown
  const [MessageanchorEl, MessagesetAnchorEl] = React.useState(null);
  const Messageopen = Boolean(MessageanchorEl);
  const handleClick = (event) => {
    MessagesetAnchorEl(event.currentTarget);
  };
  const MessagehandleClose = () => {
    MessagesetAnchorEl(null);
  };

  const HandleLaunchNewTab  = () => {
  console.log("========clicked");
  let url = `/user/portalmessage/inboxes/incoming-message-viewer?PortalDocID=${row.MessageNo}&Section=${row.DueDate}&Subject=${row.Subject}&Issued=${formatDate(row.Issued)}&Viewed=${formatDate(row.Viewed)}&ClientName=${row.ReferenceName}&Status=Yes`
  window.open(url,"_blank")
  }

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formatDate = (date) => moment(date).format("DD-MM-YYYY");
  
  return (
    <React.Fragment>
      {value === "2" ? (
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}
          onDoubleClick={() => {
            dispatch(markMessageAsViewed(row["Message No"]));
            navigate(`/user/portalmessage/inboxes/incoming-message-viewer?PortalDocID=${row.MessageNo}&Section=${row.DueDate}&Subject=${row.Subject}&Issued=${formatDate(row.Issued)}&Viewed=${formatDate(row.Viewed)}&ClientName=${row.ReferenceName}&Status=Yes`);
          }}
        >
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.Subject}
          </TableCell>
          <TableCell align="center">
            {row.Recipient}
            <p className="font-12">Patrick Jones</p>
          </TableCell>
          <TableCell align="center">{row.ApprovalRequested}</TableCell>
          <TableCell align="center">{row.IssuedOn}</TableCell>
          <TableCell align="center">{row.DueDate}</TableCell>
          <TableCell align="right">
            <Box className="d-flex align-items-center justify-content-end">
              {value === 2 ? (
                ""
              ) : (
                <span className="badge text-bg-primary">{row.Status}</span>
              )}
              <Box className="ms-1">
                <IconButton
                  id="basic-button"
                  aria-controls={Messageopen ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={Messageopen ? "true" : undefined}
                  onClick={handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={MessageanchorEl}
                  open={Messageopen}
                  onClose={MessagehandleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  className="custom-dropdown"
                >
                  {value === 1 ? (
                      <MenuItem onClick={MessagehandleClose}>
                        <UnsubscribeIcon className="me-2" /> Retract Message
                      </MenuItem>
                  ) : value === 2 ? (
                    <MenuItem onClick={MessagehandleClose}>
                      <UnsubscribeIcon className="me-2" /> Docusoft Message
                    </MenuItem>
                  ) : (
                    <MenuItem onClick={MessagehandleClose}>
                      <UnsubscribeIcon className="me-2" />
                      Unretract Message
                    </MenuItem>
                  )}
                  {value === 1 ? (
                      <MenuItem onClick={MessagehandleClose}>
                        <InfoIcon className="me-2" /> Change to Information Only
                      </MenuItem>
                  ) : (
                    ""
                  )}
                  <MenuItem onClick={MessagehandleClose}>
                    <DeleteIcon className="me-2" /> Delete Message
                  </MenuItem>

                  <MenuItem onClick={HandleLaunchNewTab}>
                    <LaunchIcon className="me-2" /> Launch in new tab
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          </TableCell>
        </TableRow>
      ) : (
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}
        onDoubleClick={() =>{
          dispatch(markMessageAsViewed(row["Message No"]));
          let customerId = row.History[3].customerId
          navigate(`/user/portalmessage/sent/outgoing-message-viewer?PortalDocID=${row.MessageNo}&Section=${row.DueDate}&Subject=${row.Subject}&Issued=${formatDate(row.Issued)}&Viewed=${formatDate(row.Viewed)}&ClientName=${row.ReferenceName}&Status=Yes&MessageID=${row.MessageID}&customerId=${customerId}`);
        }}
        >
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.Subject}
          </TableCell>
          <TableCell align="center">
            {row.Recipient}
            <p className="font-12">Patrick Jones</p>
          </TableCell>
          <TableCell align="center">{row.ApprovalRequested}</TableCell>
          <TableCell align="center">{row.IssuedOn}</TableCell>
          <TableCell align="center">{row.DueDate}</TableCell>
          <TableCell align="right">
            <Box className="d-flex align-items-center justify-content-end">
              <span className="badge text-bg-primary">{row.Status}</span>             
              <Box className="ms-1">
                <IconButton
                  id="basic-button"
                  aria-controls={Messageopen ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={Messageopen ? "true" : undefined}
                  onClick={handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={MessageanchorEl}
                  open={Messageopen}
                  onClose={MessagehandleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  className="custom-dropdown"
                >
                  {value === 1 ? (
                      <MenuItem onClick={MessagehandleClose}>
                        <UnsubscribeIcon className="me-2" /> Retract Message
                      </MenuItem>
                  ) : value === 2 ? (
                    <MenuItem onClick={MessagehandleClose}>
                      <UnsubscribeIcon className="me-2" /> Docusoft Message
                    </MenuItem>
                  ) : (
                    <MenuItem onClick={MessagehandleClose}>
                      <UnsubscribeIcon className="me-2" />
                      Unretract Message
                    </MenuItem>
                  )}
                  {value === 1 ? (
                      <MenuItem onClick={MessagehandleClose}>
                        <InfoIcon className="me-2" /> Change to Information Only
                      </MenuItem>
                  ) : (
                    ""
                  )}

                  <MenuItem onClick={MessagehandleClose}>
                    <DeleteIcon className="me-2" /> Delete Message
                  </MenuItem>
                  <MenuItem onClick={HandleLaunchNewTab}>
                    <LaunchIcon className="me-2" /> Launch in new tab
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          </TableCell>
        </TableRow>
      )}

      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, border: 0 }}
          colSpan={7}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className="well-box mt-3">
              <Box className="white-box mb-0">
                <Grid container spacing={3} className="mb-">
                  <Grid item lg={5} xs={4} md={5}>
                    <Box className="table-inbox me-5">
                      <Table size="small" aria-label="purchases" className="table-grid">
                        <TableBody>
                          {row.History.map((historyRow) => (
                            <TableRow key={historyRow.date}>
                              <TableCell
                                component="th"
                                scope="row"
                                className="poppins-semibold"
                              >
                                {historyRow.date}
                              </TableCell>
                              <TableCell>{historyRow.customerId}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Grid>
                  <Grid item lg={7} xs={8} md={7}></Grid>
                </Grid>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function CustomePortalMessageTable({ TableHeder, TableData, value }) {
  // console.log(TableData, "TableData")
  return (
    <>
      <Table aria-label="collapsible" className="table custom-table">
        <TableHead>
          <TableRow>
            <TableCell />
            {TableHeder &&
              TableHeder.map((item, index) =>
                index === 0 ? (
                  <React.Fragment key={index}>
                    <TableCell className="poppins-semibold">{item}</TableCell>
                  </React.Fragment>
                ) : (
                  <React.Fragment key={index}>
                    <TableCell className="poppins-semibold" align="center">
                      {item}
                    </TableCell>
                  </React.Fragment>
                )
              )}
          </TableRow>
        </TableHead>
        <TableBody>
          {TableData &&
            TableData.map((row, index) => (
              <Row key={index} row={row} value={value} />
            ))}
        </TableBody>
      </Table>
    </>
  );
}

export default CustomePortalMessageTable;


