import { useNavigate } from "react-router-dom";
import React, { useEffect } from 'react'

const ProtectedRoute = ({ Cmp }) => {
    const navigate = useNavigate();
    const login = localStorage.getItem("Login");
    const login1 = localStorage.getItem("LoginClient");
    useEffect(()=>{
      const baseUrl = window.location.pathname;
      if(!login && baseUrl.includes('/user')){
        navigate("/user/Login");
      }else if (!login1 && baseUrl.includes('/client')){
        navigate("/client/ClientLogin");
      }
    },[]);
    return (
        <div>
            {Cmp}
        </div>
    );
}

export default ProtectedRoute