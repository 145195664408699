import * as React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setContactModalData, handleOpenCreateContactModal } from '../redux/docuSlice';

const data = [
    {"First Name":"Chetan", "Last Name": "Docusoft", "Email": "chetan@gmail.com", "Email2":"chetan@docusoft.net"},
    {"First Name":"Sunil", "Last Name": "Docusoft", "Email": "sunil@gmail.com", "Email2":"sunil@docusoft.net"},
    {"First Name":"Sonam", "Last Name": "Docusoft", "Email": "sonam@gmail.com", "Email2":"sonam@docusoft.net"},
    {"First Name":"Sadik", "Last Name": "Docusoft", "Email": "sadik@gmail.com", "Email2":"sadik@docusoft.net"},
]

 function CustomTable() {
    const dispatch = useDispatch();
    const handleEdit=(contact)=>{
        dispatch(setContactModalData(contact));
        dispatch(handleOpenCreateContactModal(true));
    }
    return (
        <Box className="table-responsive">
            <table className='table table-striped'>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(item=>{
                        return <tr key={item["First Name"]}>
                        <td>{item["First Name"]} {item["Last Name"]}</td>
                        <td>{item["Email"]}</td>
                        <td className='text-end'>
                            <Button>
                                <EditIcon onClick={()=>handleEdit(item)}/>
                            </Button>
                            <Button>
                                <CloseIcon />
                            </Button>
                        </td>
                    </tr>
                    })}
                    {/* <tr>
                        <td>Patrick Mendez</td>
                        <td>patrick.docusoft@outlook.com</td>
                        <td className='text-end'>
                            <Button>
                                <EditIcon />
                            </Button>
                            <Button>
                                <CloseIcon />
                            </Button>
                        </td>
                    </tr> */}
                </tbody>
            </table>
        </Box>
    );
}

export default CustomTable