import React, { useState } from 'react';
import UserSetting from './UserSetting';
import ComponySetting from './ComponySetting';

function Setting() {
  const [companyData, setCompanyData] = useState(null);

  const handleCompanyData = (data) => {
    setCompanyData(data);
  };

  return (
    <>
      <UserSetting companyData={companyData} />
      <ComponySetting onCompanyDataChange={handleCompanyData} />
    </>
  );
}

export default Setting;
