import { Button } from '@mui/base'
import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import HtmlEditorDX from "../user/pages/create-request/HtmlEditor";
import SendIcon from '@mui/icons-material/Send';
import { useDispatch } from 'react-redux';
import { savePortalMessgeComment } from '../redux/dev_chetan_api_helper';
import { AddTaskComment_Json_Redux } from '../redux/dev_sonam_api_helper';

function Comments({portalDocID, portalMessageComments, ClientName,customerId}) {
    const [comment, setComment] = useState("");
    const [editorText, setEditorText] = useState("");
    const dispatch = useDispatch();
    const handleEditorChange = (e, editor) => {
        let text = editor.getContent({ format: "text" });
        setComment(text);
        setEditorText(e);
    }
    const saveMessageComment=()=>{
        if(ClientName){
            dispatch(AddTaskComment_Json_Redux(portalDocID, comment,customerId));
            setComment('');
            setEditorText('');
        } else {
            dispatch(savePortalMessgeComment(portalDocID, comment));
            setComment('');
            setEditorText('');
        }
        
    }
    return (
        <Box className='white-box'>
            <Box >
                <Typography variant="h3" className="font-18 text-black poppins-semibold mb-2">
                    Comment
                </Typography>

                {/* <DocuTextField rows={8} sx={{ background: "#fff", height: '120px' }} size={"Normal"} label={'dfadfadsf'} /> */}

                <Box className='editor-height-200'>
                    <HtmlEditorDX templateDataMarkup={editorText} handleEditorChange={handleEditorChange}/>
                </Box>

                {/* <Box>
            <AutoComplete
              sx={{
                "&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-yicy8l-MuiAutocomplete-root .MuiOutlinedInput-root ":
                {
                  with: "300px",
                  background: "#fff",
                },
              }}
              lable={'test'}
            />
          </Box> */}

                <Button
                    variant="contained"
                    className="btn-blue mt-3"
                    startIcon={<SendIcon />}
                    onClick={saveMessageComment}
                >Send
                </Button>
            </Box>

            <Box >
                <Box sx={{ marginTop: "25px" }} mb={3}>
                <div dangerouslySetInnerHTML={{ __html: portalMessageComments }}></div>
                    {/* <Typography className="font-16 poppins-semibold"
                        variant="h3"
                    >
                        On Mon 20 MAy 2024 at 8:30px the following response was recived :
                    </Typography>

                    <Typography
                        className="font-13 text-gray"
                        sx={{
                            marginTop: "20x",
                        }}
                        variant="p"
                    >
                        Dont Know What to do here
                    </Typography> */}
                    <hr />

                    {/* <Divider sx={{ border: "1px solid #555", marginTop: "10px" }} /> */}
                </Box>
            </Box>
        </Box>
    )
}

export default Comments