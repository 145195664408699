import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LinearScaleIcon from "@mui/icons-material/LinearScale";
import { Box, Tooltip, makeStyles } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import { GetMessageViewHistory_Json } from "../redux/dev_sonam_api_helper";
import { useCustomerId } from "../redux/get_email_form_url";

function TimelineModal({messageID}) {
    
  const [open, setOpen] = React.useState(false);
  const { viewHistoryData } = useSelector((state) => state.dev_sonam);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const dispatch = useDispatch()
  const userEmailid =useCustomerId();

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {    
    if(Boolean(localStorage.getItem("LoginClient")) === false){
      dispatch(GetMessageViewHistory_Json(messageID,userEmailid));
    }
  },[dispatch])

  return (
    <>
      <Tooltip title="Timeline" arrow>
        <Button
          className="min-width-auto ms-1"
          size="small"
          onClick={handleClickOpen}
        >
          <LinearScaleIcon className="text-black" />
        </Button>
      </Tooltip>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="custom-modal modal-lg"
        maxWidth={"lg"}
      >
        <Box className="d-flex align-items-center justify-content-between modal-head">
          <Box className="clearfix">
            <DialogTitle>Timeline</DialogTitle>
          </Box>

          {/*  */}
          <Button onClick={handleClose} autoFocus>
            <CloseIcon />
          </Button>
        </Box>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box class="activity-timeline activity-timeline-2 pe-3">
              <ul class="timeline-ul">
                {viewHistoryData &&
                  viewHistoryData.map((item, index) => (
                    <li>
                      <Box class="datetime">
                        <span>
                          {item.ViewDateTime
                            ? moment(item.ViewDateTime).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )
                            : "Date not available"}
                        </span>
                        <span>{}</span>
                      </Box>
                      <Box class="line-dotted">
                        <Box class="line-time"></Box>
                        <Box class="circle-time"></Box>
                        <Box class="circle-border"></Box>
                      </Box>
                      <Box class="timeline-details">
                        <Box class="icon-time-status"></Box>
                        <Box class="content-time">
                          <h5>{item.IPAddress ? item?.IPAddress : ""}</h5>
                          <Box className="user-name pt-2 mt-2 d-flex align-items-center">
                            <PersonIcon className="me-1" />{" "}
                            <p className="mb-0">{item?.Name}</p>
                          </Box>
                        </Box>
                      </Box>
                    </li>
                  ))}
              </ul>
            </Box>
          </DialogContentText>
        </DialogContent>

        <DialogActions className="p-4 pt-0">
          <Button onClick={handleClose} className="btn-red" variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default TimelineModal;
