import {
  Box,
  Grid,
  Typography,
  AccordionActions,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import React from "react";
import pdf from "../../assets/images/user-2.svg";
import unreadEmail from "../../assets/images/icons/unread-message.png";
import pendingDocument from "../../assets/images/icons/pending-document.png";

import DocumentCard from "../../cmp-utils/DocumentCard";
import EmailIcon from "@mui/icons-material/Email";
import ShareBox from "../../user/pages/ShareBox";
import Accordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button } from "@mui/base";
import Comments from "../../components/Comments";
import PersonIcon from "@mui/icons-material/Person";
import LaunchIcon from "@mui/icons-material/Launch";
import { useDispatch, useSelector } from "react-redux";
import { getPortalUserInboxMessages } from "../../redux/dev_chetan_api_helper";
import {
  GetPortalManagers_Json,
  fetchdocuRequestData,
} from "../../redux/api_helper";
import {
  GetPortalInformationTotalByContact_Json,
  GetPortalInformationTotal_Json,
} from "../../redux/dev_sonam_api_helper";
import { setAllPendingRequest } from "../../redux/dev_sonam_slice";

export default function ClientDashboard() {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState("panel1");
  const [penRequest, setpenRequest] = React.useState("");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const portalUserInboxMessage = useSelector(
    (state) => state.dev_chetan.portalUserInboxMessage
  );
  let managerData = useSelector((state) => state.docu.PortalToData);
  let pendingRequest = useSelector(
    (state) => state.docu.docuRequestDataClientAll
  );
  let pendingRequestService = useSelector(
    (state) => state.dev_sonam.AllPendingRequest
  );
  let filterData = pendingRequest.filter((item) => {
    return item.IsApproved == "";
  });

  React.useEffect(() => {
    let objectdata = {
      accid: "0003",
      email: "patrick@docusoft.net",
      password: "UGF0cmljazEyMy4=",
    };
    let objectdata1 = {
      accId: "0003",
      email: "patrick@docusoft.net",
      password: "UGF0cmljazEyMy4=",
    };
    dispatch(GetPortalManagers_Json(objectdata, function (res) {}));
    dispatch(fetchdocuRequestData());
    if (portalUserInboxMessage.length === 0)
      dispatch(getPortalUserInboxMessages());
    dispatch(
      GetPortalInformationTotalByContact_Json(function (data) {
        if (data && data.length > 0) {
          dispatch(setAllPendingRequest(data[0]));
        }
      })
    );
  }, []);

  return (
    <Box>
      <h2 className="title poppins-mediam">
        Welcome <span className="text-blue poppins-semibold">Patrick!</span>
      </h2>
      <Grid container spacing={3}>
        <Grid item md={6}>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <Box className="white-box d-flex align-items-center dashboard-message">
                <Box className="dashboard-icon">
                  <img src={unreadEmail} width={66} />
                </Box>
                <Box className="ms-2">
                  <h2 className="title mb-0">
                    {pendingRequestService.Unread
                      ? pendingRequestService.Unread
                      : 0}
                  </h2>
                  <p className="poppins-medium text-gray">Unread Messages</p>
                </Box>
              </Box>
            </Grid>

            <Grid item md={6}>
              <Box className="white-box d-flex align-items-center dashboard-message">
                <Box className="dashboard-icon">
                  <img src={pendingDocument} width={66} />
                </Box>
                <Box className="ms-2">
                  <h2 className="title mb-0">
                    {pendingRequestService.Pending
                      ? pendingRequestService.Pending
                      : 0}
                  </h2>
                  <p className="poppins-medium text-gray">
                    Pending Document Requests
                  </p>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <h2 className="title font-18 mb-1">Send Message</h2>
          <Box className="white-box p-3">
            <Grid container spacing={0}>
              {Array(4)
                .fill("")
                .map((_, i) => {
                  return (
                    <>
                      <Grid item md={6} className="" key={i}>
                        <Box className="d-flex align-items-center p-3 mb-0 pointer">
                          <img src={pdf} style={{ width: 36 }} alt="" />

                          <Box className="ms-2">
                            <Typography className="font-16 poppins-semibold">
                              Patrick Jones
                            </Typography>
                            <Typography className="font-12 poppins-semibold text-gray">
                              patrick@docusoft.net
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </>
                  );
                })}
            </Grid>
          </Box>
        </Grid>
        {/*  */}
        <Grid item md={6}>
          <h2 className="title font-18 mb-2">Expiring Soon !</h2>
          <Box className="white-box mb">
            <Grid container spacing={1}>
              {Array(10)
                .fill("")
                .map((_, i) => {
                  return (
                    <>
                      <Grid item xl={6} key={i}>
                        <Box className="d-flex align-items-center mb-0 pointer justify-content-between expiring-box">
                          <Box className="ms-2 d-flex">
                            <EmailIcon />
                            <Box>
                              <Typography className="font-14 poppins-semibold">
                                This is a notification
                              </Typography>
                            </Box>
                          </Box>

                          <Box>
                            <Typography className="font-12 poppins-semibold text-black">
                              01/06/2024
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </>
                  );
                })}
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {/*  */}

      <Grid container spacing={3}>
        <Grid item md={12}>
          <ShareBox />
        </Grid>
      </Grid>

      <Box className="mt-4">
        <h2 className="title font-18">Recent Comments</h2>
        <Box className="main-accordian">
          {[...Array(3)].map((_, index) => (
            <Accordion
              key={index}
              className="accordian-box"
              expanded={expanded === `panel${index + 1}`}
              onChange={handleChange(`panel${index + 1}`)}
            >
              <AccordionSummary
                aria-controls={`panel${index + 1}d-content`}
                id={`panel${index + 1}d-header`}
              >
                <Box className="d-flex align-items-center justify-content-between w-100 me-3">
                  <Box className="w-100">
                    <Typography>
                      Hey Patrick, could you please approve this document asap
                    </Typography>
                  </Box>
                  <Box className="d-flex">
                    <Box className="me-2">
                      <PersonIcon />
                    </Box>
                    <Box>
                      <p>Mona Smith</p>
                      <p className="nowrap font-12">30/05/2024 | 15:22</p>
                    </Box>
                  </Box>
                </Box>
                <LaunchIcon />
              </AccordionSummary>
              <AccordionDetails>
                <Comments />
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
      
    </Box>
  );
}
