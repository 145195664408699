
import { Box, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import React from 'react'
import Button from '@mui/material/Button';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import LockIcon from '@mui/icons-material/Lock';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';

const statusClass = (data) => {

    switch (true) {
      case data.RequestDocStatus === 1:
        return "approved";
      case data.RequestDocStatus === 2:
        return "pending";
      case data.RequestDocStatus === 3:
        return "approved";
      case data.RequestDocStatus === 4:
        return "approved";
      case data.RequestDocStatus === 5:
        return "rejected";
      case data.RequestDocStatus === 6:
        return "expiring";
      case data.RequestDocStatus === 7:
        return "expired";
      default:
        return "pending";
    }
  };

function Overview() {
    const { PortalUsersRequestedDocs2 } = useSelector((state) => state.docu);
    console.log("dev test total request's docs: ", PortalUsersRequestedDocs2.length);

    PortalUsersRequestedDocs2.map(itm=> {
        console.log("dev test RequestDocStatus",itm.RequestDocStatus, "Status", itm.Status);
    })
    
    const {contactRequestDocCount} = useSelector(state=>state.dev_chetan);
    // const data = contactRequestDocCount.filter(itm=>itm.)
    // console.log('contactRequestDocCount 51',contactRequestDocCount);
    return (
        <Box>
            <Box className="row">
                <Box className="col-lg-4 col-12">
                    <Box className="box bg-returned overview-status mb-3">
                        <Box className="p-3">
                            <h5 className="text-uppercase text-white">OVERALL <strong>STATUS</strong></h5>
                            <span className="font-size-30 text-white"><strong>RETURNED</strong>
                            </span>
                        </Box>
                    </Box>
                </Box>
                <Box className="col-lg-4 col-12">
                    <Box className="box bg-rejected overview-requests mb-3">
                        <Box className="p-3">
                            <h5 className="text-uppercase text-white">COMPLETE <strong>REQUESTS</strong></h5>
                            <span className="font-size-30 text-white"><strong>3/{contactRequestDocCount.length} REQUESTS</strong></span>
                        </Box>
                    </Box>
                </Box>

                <Box className="col-lg-4 col-12">
                    <Box className="box bg-accepted overview-duedate mb-3">
                        <Box className="p-3"><h6 className="text-uppercase text-white">DUE <strong>DATE</strong></h6>
                            <span className="font-size-30 text-white"><strong>24 MAY, 2024</strong></span>
                        </Box>
                    </Box>
                </Box>

            </Box>
            {/* row end */}

            <Box className="card p-3 mb-3">
                <Box className="d-flex justify-content-between align-items-center mb-3">

                    <Typography variant="h5">
                        Invite Progress
                    </Typography>
                    <Button variant="contained" className='btn-blue'>
                        View All
                    </Button>
                </Box>

                <Box className='table-responsive'>
                    <table className='table'>

                        <tbody>
                            <tr>
                                <td>Contact</td>
                                <td>Status</td>
                                <td>Requests</td>
                                <td className='text-end'>

                                    <Button variant="contained" size='small' className='btn-blue'>
                                        Add Another Contact
                                    </Button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography variant="body2">
                                        <a href='#'>Umair Khan</a>
                                    </Typography>
                                    <Typography variant="body2">
                                        umairkhansmurf4@gmail.com
                                    </Typography>
                                </td>
                                <td>
                                    <span className="badge text-bg-info">Returned</span>
                                </td>
                                <td>
                                    <Box className='custom-progress d-block d-md-flex'>
                                        <Box className='inner-custom-progress returned'>
                                            1 / 5 Returned
                                        </Box>
                                        <Box className='inner-custom-progress pending'>
                                            2 / 5 Pending
                                        </Box>
                                        <Box className='inner-custom-progress accept'>
                                            2 / 5 Accepted
                                        </Box>
                                    </Box>
                                </td>
                                <td className='text-end'>
                                    <Button size='small' className='me-2'>View Portal</Button>
                                    <Button size='small' className='min-width-auto'><FileCopyIcon /></Button>
                                    <Button size='small' className='min-width-auto'><LockIcon /></Button>
                                    <Button size='small' className='min-width-auto'><ShortcutIcon /></Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Box>
            </Box>

            <Grid container spacing={3} className="mb-3">
                <Grid item lg={6} xs={12} md={12}>
                    <Box className="card p-3">
                        <Box className="d-flex justify-content-between align-items-center mb-3">
                            <Typography variant="h5">
                                Recent History

                            </Typography>
                            <Button variant="contained" className='btn-blue'>
                                View All
                            </Button>
                        </Box>

                        <Box className='table-responsive'>
                            <table className='table'>
                                <tbody>
                                    <tr>
                                        <td>Date</td>
                                        <td>Action</td>
                                        <td>Contact</td>
                                    </tr>
                                    {Array(5).fill("").map((iem,index) => {
                                        return 
                                            <tr key={index}>
                                                <td>21 May	</td>
                                                <td>Email Sent</td>
                                                <td>Umair Khan</td>
                                            </tr>
                                        
                                    })}
                                </tbody>
                            </table>
                        </Box>
                    </Box>
                </Grid>

                <Grid item lg={6} xs={12} md={12}>
                    <Box className="card p-3">
                        <Box className="d-flex justify-content-between align-items-center mb-3">
                            <Typography variant="h5">
                                Options

                            </Typography>
                            <Button variant="contained" className='btn-blue'>
                                View All
                            </Button>
                        </Box>

                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckIcon />
                                </ListItemIcon>
                                <ListItemText>Single-line item</ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckIcon />
                                </ListItemIcon>
                                <ListItemText> Overdue Reminder: Every 2 days
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CloseIcon />
                                </ListItemIcon>
                                <ListItemText> Password Protected: No
                                </ListItemText>
                            </ListItem>
                        </List>

                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Overview