import React, { useEffect } from 'react'
import CreateUdf from './CreateUDF'
import CreateCompany from './CreateCompany'
import { useDispatch, useSelector } from 'react-redux';
import { ContectDetails_Redux, Json_Portal_GetClientListByEmail_Redux } from '../redux/ak_api_helper';

function Home() {
  const { UdfNameId } = useSelector((state) => state.ak);
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(Json_Portal_GetClientListByEmail_Redux());
  },[]);

//       useEffect(()=>{
//       let reqObj={
//                   AccID: "0003",
//                   strEmailId: "dbeckhamportal@gmail.com",
//                   password: "RFNwb3J0YWw5OQ==",
//                   CliientId: UdfNameId[0].OriginatorNo,
//                 }
// dispatch(ContectDetails_Redux(reqObj));
//     },[Object.keys(UdfNameId).length > 0])
  return (
    <div>
      <h1>Home Page</h1>
     {/* <Settings/> */}
     {/* <CreateUdf/> */}
     {/* <CreateCompany/> */}
    </div>
  )
}

export default Home
