import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Autocomplete, Box, List, ListItem, ListItemText, MenuList, Paper, TextField, Typography } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Background } from 'devextreme-react/cjs/range-selector';


function LinkDropDown({ ddType, label, dataOpt, changeMethod, isVisible, setIsVisible }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setIsVisible(false);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    useEffect(()=>{
        if(Boolean(isVisible)){
            setAnchorEl(null);
        }
    },[isVisible]);


    return (

        <Box className="select-dropdown">
            
            <Tooltip title="Add" arrow>
                <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    {label}
                    <KeyboardArrowDownIcon />
                </Button>
            </Tooltip>



            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className="search-list-main custom-dropdown"
                
            // MenuListProps={{
            //     'aria-labelledby': 'basic-button',
            // }}
            >
               
                <Paper className='custom-select-dropdown'>
                    
                    {(ddType && ddType == 1) ? (<Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={dataOpt}
                        getOptionLabel={(option) => option.Folder}
                        onChange={changeMethod}
                        size='small'
                        open={true}
                        renderInput={(params) => <TextField {...params} label="All" />}
                        className='w-100'
                        
                />) : (ddType && ddType == 2) ? (<Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={dataOpt}
                    key="clients"
                    getOptionLabel={(option) => option.Client}
                    onChange={changeMethod}
                    open={true}
                    size='small'
                    renderInput={(params) => <TextField {...params} label="All" />}
                    className='w-100'
                />) : <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={(typeof dataOpt === "string" || !dataOpt) ? [] : dataOpt}
                    key="contact"
                    getOptionLabel={(option) => option.ContactName}
                    onChange={changeMethod}
                    open={true}
                    size='small'
                    renderInput={(params) => <TextField {...params} label="All" />}
                    className='w-100'
                />}


                    {/* <Box className='px-2'>
                    <TextField
                        label="Folder"
                        variant="outlined"
                        autoFocus
                        sx={{ width: "100%" }}
                        size="small"
                    />
                </Box>

                <MenuList>
                    <MenuItem onClick={handleClose}>Profile</MenuItem>
                    <MenuItem onClick={handleClose}>My account</MenuItem>
                    <MenuItem onClick={handleClose}>Logout</MenuItem>
                </MenuList> */}
                </Paper>
            </Menu>

        </Box>
    )
}

export default LinkDropDown