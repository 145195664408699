import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import CountryDropdown from './CountryDropdown';
import { Box, Grid, TextField, Tooltip, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AutoComplete from './AutoComplete';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CommentsDisabledIcon from '@mui/icons-material/CommentsDisabled';
import InfoIcon from '@mui/icons-material/Info';



function AdditionalSettingsModal() {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Button variant="contained" className='' onClick={handleClickOpen}>
                <EditIcon />
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='custom-modal'
            >
                <Box className="d-flex align-items-center justify-content-between modal-head">
                    <Box className="clearfix">
                        <DialogTitle>{"Additional Settings"}</DialogTitle>
                    </Box>

                    {/*  */}
                    <Button onClick={handleClose} autoFocus>
                        <CloseIcon />
                    </Button>
                </Box>


                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                        <Grid container spacing={3} className="mb-">
                            <Grid item lg={8} xs={8} md={8}>
                                <AutoComplete label={'Almost Due Reminder'} />
                            </Grid>
                            <Grid item lg={4} xs={4} md={4}>
                                <Box className='d-flex justify-content-end'>
                                    <Tooltip title="Email notification enable"><Button className='min-width-auto'><MailOutlineIcon /></Button></Tooltip>
                                    <Tooltip title="SMS notification disabled"><Button className='min-width-auto'><CommentsDisabledIcon /></Button></Tooltip>
                                    <Button className='min-width-auto'>Edit</Button>
                                </Box>
                            </Grid>

                            <Grid item lg={8} xs={8} md={8}>
                                <AutoComplete label={'Overdue Reminder'} />
                            </Grid>
                            <Grid item lg={4} xs={4} md={4}>
                                <Box className='d-flex justify-content-end'>
                                    <Tooltip title="Notifications will run for 14 days"><Button className='min-width-auto'><InfoIcon /></Button></Tooltip>
                                    <Tooltip title="Email notification enable"><Button className='min-width-auto'><MailOutlineIcon /></Button></Tooltip>
                                    <Tooltip title="SMS notification disabled"><Button className='min-width-auto'><CommentsDisabledIcon /></Button></Tooltip>
                                    <Button className='min-width-auto'>Edit</Button>
                                </Box>
                            </Grid>

                            <Grid item lg={12} xs={12} md={12}>
                                <AutoComplete label={'Password Protected'} />
                            </Grid>

                            <Grid item lg={12} xs={12} md={12}>
                                <TextField
                                    required
                                    label="Reference ID"
                                    // defaultValue="Hello World"
                                    fullWidth
                                    size='small'
                                />
                            </Grid>


                            <Grid item lg={12} xs={12} md={12}>
                                <Box className='d-flex align-items-center'>
                                    <AutoComplete label={'Display User Override'} />
                                    <Tooltip title="Override who the contact(s) see they are interacting with over email and on their portal"><Button className='min-width-auto ms-2'><InfoIcon /></Button></Tooltip>
                                </Box>
                            </Grid>

                            <Grid item lg={12} xs={12} md={12}>
                                <Box className='d-flex align-items-center'>
                                    <AutoComplete label={'Notification User Override'} />
                                    <Tooltip title="Override who on your team receives Invite Status Update Notifications"><Button className='min-width-auto ms-2'><InfoIcon /></Button></Tooltip>
                                </Box>
                            </Grid>

                        </Grid>

                    </DialogContentText>

                    <DialogActions className='pt-4 px-0'>
                        <Button onClick={handleClose} className='btn-red' variant="outlined">Cancel</Button>
                        <Button onClick={handleClose} className='btn-blue' autoFocus variant="outlined">
                            Save Settings
                        </Button>
                    </DialogActions>
                </DialogContent>


            </Dialog>
        </React.Fragment>
    )
}

export default AdditionalSettingsModal