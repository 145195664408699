import React, { useEffect } from 'react';
import { Grid, TextField, Autocomplete, Box, Fab, } from '@mui/material'
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import CustomDatePicker from './CustomDatePicker';
import { useDispatch,useSelector } from 'react-redux';
import { Json_AddDSRequest_Redux,  Json_GetForwardUserList_Redux } from '../redux/api_helper';
import { useLocalStorage } from '../custom-hook/useLocalStorage';


import AssigneeUsers from './Assignee';


function SetupInvite() {
const dispatch = useDispatch();

let obj = useLocalStorage("required");


  function Json_AddDSRequest(){
    try {
      let obj={};
      dispatch(Json_AddDSRequest_Redux(obj,function(res){
        console.log("Json_AddDSRequest",res);
      }))
    } catch (error) {
        console.log("Network error",error);
    }
  }

 



  return (
    <Box>
      <h2 className='title'>1. Setup your invite</h2>

      <Grid container spacing={2}>
        <Grid item xs={12}>
        {<AssigneeUsers></AssigneeUsers>}

        </Grid>
        <Grid item xs={8}>
          <TextField
            required
            label="Invite Name"
            // defaultValue="Hello World"
            fullWidth
            size='small'
          />
        </Grid>
        <Grid item xs={4}>
          <CustomDatePicker />
        </Grid>
        <Grid item xs={12}>
          <textarea
            aria-label="minimum height"
            name="fullAddress"
            defaultValue={'test'}

            className="form-control textarea"
            placeholder="Full Address"
          // xl={{ minHeight: '400px', Height: '600px', }}
          />
        </Grid>
      </Grid>

    </Box>
  )
}

export default SetupInvite

