import { IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Password } from "@mui/icons-material";

function DocuTextField({
  fieldName,
  fieldValue,
  fieldLabel,
  onChange,
  type,
  size,
  defaultValue,
  rows,
  sx,
  label,
  icon,
  id
}) {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const getInputType = () => {
    if (type === "password") {
      return showPassword ? "text" : "password";
    }
    return type;
  };

  const getIconAdornment = () => {
    if (type === "password") {
      return (
        <InputAdornment position="end">
          <IconButton onClick={handleTogglePasswordVisibility} edge="end">
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      );
    }
    return icon ? <InputAdornment position="end">{icon}</InputAdornment> : null;
  };


  return (
    <TextField
      rows={rows}
      margin="normal"
      defaultValue={defaultValue}
      fullWidth
      name={fieldName}
      value={fieldValue}
      onChange={onChange}
      type={getInputType()}
      size={size}
      label={label}
      sx={sx}
      id={id}
      InputProps={{
        endAdornment: getIconAdornment(),
      }}
    />
  );
}

export default DocuTextField;
