import { Box, Chip, Grid, Tooltip, Typography, Button } from "@mui/material";
import GradingIcon from "@mui/icons-material/Grading";
import DrawIcon from "@mui/icons-material/Draw";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import InfoIcon from "@mui/icons-material/Info";
import UploadRequestDoc from "../components/RequestComponant/UploadRequestDoc";
import SignatureRequest from "../components/RequestComponant/SignatureRequest";
import FormFillRequest from "../components/RequestComponant/FormFillRequest";
import ReplaceRequestDoc from "../components/RequestComponant/ReplaceRequestDoc";

import csv from "../../src/assets/images/files-icon/csv.png";
import doc from "../../src/assets/images/files-icon/doc.png";
import jpg from "../../src/assets/images/files-icon/jpg.png";
import pdf from "../../src/assets/images/files-icon/pdf.png";
import png from "../../src/assets/images/files-icon/png.png";
import text from "../../src/assets/images/files-icon/text.png";
import xls from "../../src/assets/images/files-icon/xls.png";


export  const clientBodyaccIdData = {
  accId:localStorage.getItem("accIdClient"),
  username:localStorage.getItem("EmailClient"),
  sPassword:localStorage.getItem("passwordClient")
}

export  const userBodyDAtaa = {
  accid:localStorage.getItem("agrno"),
  email: localStorage.getItem("Email"),
  password: localStorage.getItem("password"),
}

export  const userBodyaccId = {
  accId:localStorage.getItem("agrno"),
  email: localStorage.getItem("Email"),
  password: localStorage.getItem("password"),
}

export const clientLoginStatus = localStorage.getItem("LoginClient")

export  const clientBodyaccidData = {
  accid:localStorage.getItem("accIdClient"),
  username:localStorage.getItem("EmailClient"),
  sPassword:localStorage.getItem("passwordClient")
}

export const GetStatusChipRequests = (res, requestStatus, statusForinfo) => {
  if (statusForinfo && statusForinfo == 3) {
    return <></>;
  } else {
    switch (res) {
      case "1":
        return (
          <Chip
            label={requestStatus}
            size="small"
            sx={{ background: "#0000FF" }}
            className="chips"
          />
        );
      case "2":
        return (
          <Chip
            label={requestStatus}
            size="small"
            sx={{ background: "#eea706" }}
            className="chips"
          />
        );
      case "3":
        return (
          <Chip
            label={requestStatus}
            size="small"
            sx={{ background: "#008000" }}
            className="chips"
          />
        );
      case "4":
        return (
          <Chip
            label={requestStatus}
            size="small"
            sx={{ background: "#008000" }}
            className="chips"
          />
        );
      case "5":
        return (
          <Chip
            label={requestStatus}
            size="small"
            sx={{ background: "#195e83" }}
            className="chips"
          />
        );
      case "6":
        return (
          <Chip
            label={requestStatus}
            size="small"
            sx={{ background: "#FF0000" }}
            className="chips"
          />
        );
      case "7":
        return (
          <Chip
            label={requestStatus}
            size="small"
            sx={{ background: "#A52A2A" }}
            className="chips"
          />
        );
      default:
        return <></>;
    }
  }
};

export const GetStatusRequests = (data) => {
  switch (true) {
    case data && data.Status == "0":
      return (
        <Tooltip title="Request Document" arrow>
          <Button size="small" className="min-width-auto">
            <GradingIcon className="font-18 text-black" />
          </Button>
        </Tooltip>
      );
    case data && data.Status == "1":
      return (
        <Tooltip title="Request Signature" arrow>
          <Button size="small" className="min-width-auto">
            <DrawIcon className="font-18 text-black" />
          </Button>
        </Tooltip>
      );
    case data && data.Status == "2":
      return (
        <Tooltip title="Send Form" arrow>
          <Button size="small" className="min-width-auto">
            <MarkEmailReadIcon className="font-18 text-black" />
          </Button>
        </Tooltip>
      );
    case data && data.Status == "3":
      return (
        <Tooltip title="For Info" arrow>
          <Button size="small" className="min-width-auto">
            <InfoIcon className="font-18 text-black" />
          </Button>
        </Tooltip>
      );
    default:
      return <></>;
  }
};

export const getStatusBaseButtonRequests = (
  accordionIndex,
  accordionItem,
  setPreview
) => {
  if (
    accordionItem &&
    accordionItem.Status == "0" &&
    accordionItem.RequestDocStatus == "3"
  ) {
    return (
      <ReplaceRequestDoc
        accordionItem={accordionItem}
        setPreview={setPreview}
      />
    );
  } else {
    switch (true) {
      case accordionItem &&
        ((accordionItem.Status == "0" &&
          accordionItem.RequestDocStatus == "2") ||
          (accordionItem.Status == "0" &&
            accordionItem.RequestDocStatus == "5") ||
          (accordionItem.Status == "0" &&
            accordionItem.RequestDocStatus == "7")):
        return (
          <UploadRequestDoc
            accordionItem={accordionItem}
            setPreview={setPreview}
          />
        );

      case accordionItem &&
        ((accordionItem.Status == "1" &&
          accordionItem.RequestDocStatus == "2") ||
          (accordionItem.Status == "1" &&
            accordionItem.RequestDocStatus == "5")):
        return <SignatureRequest accordionItem={accordionItem} />;
      case accordionItem &&
        ((accordionItem.Status == "2" &&
          accordionItem.RequestDocStatus == "2") ||
          (accordionItem.Status == "2" &&
            accordionItem.RequestDocStatus == "5") ||
          (accordionItem.Status == "2" &&
            accordionItem.RequestDocStatus == "3")):
        return <FormFillRequest accordionItem={accordionItem} />;
      default:
        return <></>;
    }
  }
};


export const UserStatusClass = (data) => {
  switch (true) {
    case data.RequestDocStatus == 1:
      return "approved";
    case data.RequestDocStatus == 2:
      return "pending";
    case data.RequestDocStatus == 3:
      return "approved";
    case data.RequestDocStatus == 4:
      return "approved";
    case data.RequestDocStatus == 5:
      return "rejected";
    case data.RequestDocStatus == 6:
      return "expiring";
    case data.RequestDocStatus == 7:
      return "expired";
    default:
      return "pending";
  }
};

export const GetDocTypeIconRequests = (target) => {
  const normalizedExtension = target.toLowerCase();

  switch (normalizedExtension) {
    case ".pdf":
      return <img src={pdf} style={{ width: 36 }} alt="documents" />;
    case ".png":
      return <img src={png} style={{ width: 36 }} alt="documents" />;
    case ".docx":
      return <img src={doc} style={{ width: 36 }} alt="documents" />;
    case ".xlsx":
    case ".xls":
      return <img src={xls} style={{ width: 36 }} alt="documents" />;
    case ".jpg":
    case ".jpeg":
      return <img src={jpg} style={{ width: 36 }} alt="documents" />;
    case ".text":
      return <img src={text} style={{ width: 36 }} alt="documents" />;
    case ".csv":
      return <img src={csv} style={{ width: 36 }} alt="documents" />;
    default:
      return null;
  }
};

export const GetDocTypeIcon2Requests = (target) => {
  switch (target) {
    case ".pdf":
      return pdf;
    case ".txt":
      return text;
    case ".docx":
      return doc;
    case ".jpg":
      return jpg;
    case ".jpeg":
      return jpg;
    case ".png":
      return png;
    case ".xls":
      return xls;
    case ".csv":
      return csv;
    default:
      return text;
  }
};
