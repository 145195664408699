import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ListItemIcon, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LaunchIcon from "@mui/icons-material/Launch";
import { useLocalStorage } from "../custom-hook/useLocalStorage";
import { useDispatch } from "react-redux";
import { downloadShareboxDocument, getShareBoxDocuments, removeShareboxDocument } from "../redux/dev_chetan_api_helper";

let acc_id = localStorage.getItem("agrno");
function DocumentTripleDot({ itm, i }) {
  const dispatch = useDispatch();
  const { agrno } = useLocalStorage();
  const portalUserObj = useLocalStorage("accid");
  // dropdown
  const [anchorElDocumentList, setAnchorElDocumentList] = React.useState({});
  const handleClick = (event, index) => {
    event.stopPropagation();
    const newAnchorElDocumentList = { ...anchorElDocumentList };
    newAnchorElDocumentList[index] = event.currentTarget;
    setAnchorElDocumentList(newAnchorElDocumentList);
  };
  const handleClose = (event, index) => {
    event.stopPropagation();
    const newAnchorElDocumentList = { ...anchorElDocumentList };
    delete newAnchorElDocumentList[index];
    setAnchorElDocumentList(newAnchorElDocumentList);
  };
  const viewInNewTab = (e, i, data) => {
    let url = `https://www.sharedocuments.co.uk/Preview.aspx?accid=${acc_id?acc_id:"0003"}&attachment=${data.AttachID}&Id=${data.PortalDocID}&extension=${data.DocExtension}`;
    window.open(url);
    handleClose(e, i);
  };
  const downloadDocument = async (e, i, data) => {
    dispatch(downloadShareboxDocument(data.PortalDocID, data.AttachID, data.DocExtension, data["Document Name"]));
    handleClose(e, i);
  };
  const removeShareboxDoc=async(e,i,data)=>{
    const result = await dispatch(removeShareboxDocument(data));
    if(result==="Done") dispatch(getShareBoxDocuments());
    handleClose(e, i);
  }
  return (
    <div>
      <Button
        id={`basic-button-${i}`}
        aria-controls={
          anchorElDocumentList[i]
            ? `basic-menu-${anchorElDocumentList[i]}`
            : undefined
        }
        aria-haspopup="true"
        aria-expanded={Boolean(anchorElDocumentList[i]) ? "true" : undefined}
        onClick={(e) => handleClick(e, i)}
        className="min-width-auto"
      >
        <MoreVertIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorElDocumentList[i]}
        open={Boolean(anchorElDocumentList[i])}
        onClose={(e) => handleClose(e, i)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        className="custom-dropdown"
      >
        {window.location.pathname.split("/")[1]!=="client" && <MenuItem onClick={(e) => removeShareboxDoc(e, i, itm)}>
          <ListItemIcon>
            <CloseIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Remove Document</Typography>
        </MenuItem>}

        <MenuItem onClick={(e) => downloadDocument(e, i, itm)}>
          <ListItemIcon>
            <CloudDownloadIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Download Document</Typography>
        </MenuItem>

        {/* <MenuItem onClick={(e) => handleClose(e, i)}>
          <ListItemIcon>
            <VisibilityIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">View as FlipBook</Typography>
        </MenuItem> */}

        <MenuItem onClick={(e) => viewInNewTab(e, i, itm)}>
          <ListItemIcon>
            <LaunchIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">View in new tab</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default DocumentTripleDot;
