import { toast } from "react-toastify";
import { clientBodyaccIdData, userBodyDAtaa } from "../utils/RequestsFunction";
import {
  GetAllClientList_reducer,
  GetAllContacts_reducer,
} from "./adityaReducer";
import { UDFClintData } from "./akSlice";
import {
  DesktopWebService,
  docuSMSApi,
  DSDesktopWebService,
  portalClient,
  portalUser,
} from "./AxiosInstance";
import { json } from "react-router-dom";

const clientBody = {
  accId: localStorage.getItem("accIdClient"),
  username: localStorage.getItem("EmailClient"),
  sPassword: localStorage.getItem("passwordClient"),
};

const userBody = {
  accid: localStorage.getItem("accid"),
  email: localStorage.getItem("Email"),
  password: localStorage.getItem("password"),
};

const ArgnoclientBody = {
  agrno: localStorage.getItem("accid"),
  Email: localStorage.getItem("Email"),
  password: localStorage.getItem("password"),
};

const LoginClient = localStorage.getItem("LoginClient");

export const ApproveMessageNew_Json = (obj, callBack) => async (dispatch) => {
  try {
    let res = await portalUser.post("ApproveMessageNew_Json", obj);
    if (res) {
      let js = JSON.parse(JSON.stringify(res.data.d));
      callBack(JSON.parse(js));
    }
  } catch (error) {
    console.log(
      "Network error: No response received from server, ApproveMessageNew_Json",
      error
    );
  }
};

export const DisapproveMessageNew_Json =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalUser.post("DisapproveMessageNew_Json", obj);
      if (res) {
        let js = JSON.parse(JSON.stringify(res.data.d));
        callBack(JSON.parse(js));
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, DisapproveMessageNew_Json",
        error
      );
    }
  };

export const UpdateRequestedDocStatusAction_Json_ReduxClient =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalUser.post(
        "UpdateRequestedDocStatusAction_Json",
        obj
      );
      if (res) {
        let str = JSON.parse(JSON.stringify(res.data));
        console.log("UpdateRequestedDocStatusAction_Json", str.d);
        callBack(str.d);
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error
      );
    }
  };

export const RenderRequestAll_Json_Action =
  (obj, callBack) => async (dispatch) => {
    let body = {
      accId: "0003",
      email: "patrick@docusoft.net",
      password: "UGF0cmljazEyMy4=",
      username: "patrick.docusoft@outlook.com",
      sPassword: "0",
      filterBy: "All",
    };
    try {
      let res = await portalUser.post("PortalUsersRequestedDocs2_Json", body);
      if (res) {
        let str = JSON.parse(JSON.stringify(res.data));
        // console.log("UpdateRequestedDocStatusAction_Json 11111", str.d);
        callBack(str.d);
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error
      );
    }
  };

export const GetRequestDocumentAction = (obj, callBack) => async (dispatch) => {
  if (LoginClient) {
    let body = {
      ...clientBody,
      ...obj,
    };
    try {
      let res = await portalUser.post(
        "GetPortalRequestDocumentsNew2_Json",
        body
      );
      if (res) {
        let str = JSON.parse(JSON.stringify(res.data));
        // console.log("UpdateRequestedDocStatusAction_Json 11111", str.d);
        callBack(str.d);
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error
      );
    }
  } else {
    let body = {
      ...userBody,
      ...obj,
    };

    try {
      let res = await portalClient.post(
        "GetPortalRequestDocuments2_Json",
        body
      );
      if (res) {
        let str = JSON.parse(JSON.stringify(res.data));
        // console.log("UpdateRequestedDocStatusAction_Json 11111", str.d);
        callBack(str.d);
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error
      );
    }
  }
};

export const SendReminderUserAction = (obj, callBack) => async (dispatch) => {
  let body = {
    ...userBody,
    ...obj,
  };

  try {
    let res = await portalClient.post("SendReminder", body);
    if (res) {
      let str = JSON.parse(JSON.stringify(res.data));
      // console.log("UpdateRequestedDocStatusAction_Json 11111", str.d);
      callBack(str.d);
    }
  } catch (error) {
    console.log(
      "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
      error
    );
  }
};

export const GetCRMContactUDFValueAction =
  (obj, callBack) => async (dispatch) => {
    let body = {
      ...userBody,
      ...obj,
    };

    try {
      let res = await portalUser.post("GetCRMContactUDFValues2_Json", obj);

      if (res) {
        let str = JSON.parse(JSON.stringify(res.data));
        const parseData = JSON.parse(str.d).Table;
        dispatch(UDFClintData(parseData));
        callBack(parseData);
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error
      );
    }
  };

export const UpdateUdfValuesAction = (obj, callBack) => async (dispatch) => {
  const { value, data, getOrigantryNumber } = obj;

  let body = {
    ...clientBodyaccIdData,
    projectid: data?.ProjectId,
    ClientId: getOrigantryNumber,
    ContactNo: data?.ContactNo,
    fieldName: data?.Name,
    fieldValue: value,
  };

  try {
    let res = await portalUser.post("UpdateContactField_Json", body);
    if (res) {
      callBack(res.data);
    }
  } catch (error) {
    console.log(
      "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
      error
    );
  }
};

export const ad_Json_GetAllContacts_Action = (callBack) => async (dispatch) => {
  try {
    let res = await portalClient.post("GetAllContacts_Json", userBodyDAtaa);
    if (res) {
      const newRes = JSON.parse(res.data.d);

      if (newRes) {
        dispatch(GetAllContacts_reducer(newRes));
        callBack(newRes);
      }
    }
  } catch (error) {
    console.log(
      "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
      error
    );
  }
};

export const ad_Json_GetAllClientList_Action =
  (callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("GetAllClientList_Json", userBodyDAtaa);
      const newRes = JSON.parse(res.data.d).Table;
      if (newRes) {
        dispatch(GetAllClientList_reducer(newRes));
        callBack(newRes);
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error
      );
    }
  };

// https://docusms.uk/dsdesktopwebservice.asmx/Json_UpdateContact

export const ad_Json_UpdateContact_Action =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("UpdateContact_Json", {
        ...userBody,
        ...obj,
      });

      if (res.data.d) {
        // dispatch(GetAllClientList_reducer(newRes))
        callBack(res.data.d);
        return res.data.d;
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error
      );
    }
  };

export const ad_UpdateContactField_Json_Action =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("UpdateContactField_Json", {
        ...userBody,
        ...obj,
      });
      console.log(res?.data, "=========== res?.data");

      if (res?.data?.d) {
        callBack(res?.data?.d);
        return res?.data?.d;
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error
      );
    }
  };

export const ad_Json_GetConfiguration_Action =
  (callBack) => async (dispatch) => {
    try {
      let res = await docuSMSApi.post("Json_GetConfiguration", {
        ...ArgnoclientBody,
      });

      if (res?.data?.d) {
        callBack(JSON.parse(res?.data?.d));
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error
      );
    }
  };
// https://docusms.uk/dswebclientmanager.asmx/Json_GetConfiguration
