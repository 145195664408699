import React from 'react'
import ShareBox from '../../user/pages/ShareBox'

export default function ClientSharebox() {
  return (
    <>
      <ShareBox/>
    </>
  )
}
