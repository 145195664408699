import React, { useState } from 'react';
import { MenuItem, ListItemIcon, IconButton, Menu, Typography, Box } from '@mui/material';
import { Sort as SortIcon, MoreVert as MoreVertIcon } from '@mui/icons-material';

const ITEM_HEIGHT = 48;
const options = ['Edit', 'Copy', 'Delete'];

const TemplateMenuItem = ({ item, setSelectedTemplate, handleClickOpenModal,deleteTemplate }) => {
  // console.log("hello print",item,);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleMenuItemClick = (action,item) => {

    //console.log('Action for template:', action, item);

    setSelectedTemplate({action:action,data:item});

  
    handleClose();
    switch (action) {
        case 'Edit':         
            // Implement edit functionality here
            handleClickOpenModal()
            break;
        case 'Copy':
            console.log('Copy action for template');
            // Implement copy functionality here            
            break;
        case 'Delete':
          deleteTemplate(item);
            console.log('Delete action for template');
            // Implement delete functionality here
            break;
        default:
            break;
    }
};



  return (
    <MenuItem className='pe-2'>
      <ListItemIcon>
        <SortIcon />
      </ListItemIcon>
      <Box>
        <Typography variant="body1" className='font-14'>{item.TemplateName}</Typography>
      </Box>
      <Box className='ms-auto'>
        <IconButton
          aria-label="more"
          aria-controls={open ? 'long-menu' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch',
            },
          }}
        >
          {options.map((option) => (
            <MenuItem key={option} onClick={() => handleMenuItemClick(option,item)}>
              {option}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </MenuItem>
  );
};

export default TemplateMenuItem;
