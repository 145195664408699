import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import * as React from "react";

function AutoComplete({
  ComData = [],
  target = "",
  comFun,
  lable,
  defaultValue,
  sx,
}) {
  const [value, setValue] = React.useState(defaultValue);

  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (comFun) {
      comFun(event, newValue);
    }
  };

  return (
    <Autocomplete
      fontSize="small"
      disablePortal
     
      id="combo-box-demo"
      value={value || null} // Ensure value is null if not set
      options={ComData}
      getOptionLabel={(option) => {
        if (!option) return "";
        if (typeof option === "string") return option;
        if (typeof option === "number") return option.toString();
        if (typeof option === "object" && option !== null) {
          return option[target] || "";
        }
        return "";
      }}
      onChange={handleChange}
      size="small"
      renderInput={(params) => (
        <TextField fontSize="small" {...params} label={lable} />
      )}
      className="w-100"
      sx={sx}
      isOptionEqualToValue={(option, value) => {
        if (typeof option === "object" && typeof value === "object") {
          return option[target] === value[target];
        }
        return option === value;
      }}
    />
  );
}

export default React.memo(AutoComplete);
