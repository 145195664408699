import { Box, Grid, Typography } from '@mui/material';
import React, { memo, useCallback } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import PostAddIcon from '@mui/icons-material/PostAdd';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import { useDispatch, useSelector } from 'react-redux';
import { handleOpenInviteModal } from '../redux/docuSlice';
import { useNavigate } from 'react-router-dom';


const CreateNewInviteModal = ({ open }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleClose = useCallback(() => {
        dispatch(handleOpenInviteModal(false));
    }, [open]);

    const moveToInvite = () => {
        navigate('/user/Invite');
        handleClose();
    }

    return (
        <Box>

            {/* <Button variant="outlined" onClick={() => dispatch(handleOpenInviteModal(true))}>
                Create New Invite
            </Button> */}

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='custom-modal'
            >

                <Box className="d-flex align-items-center justify-content-between modal-head">
                    <Box className="clearfix">
                        <DialogTitle>Create Request</DialogTitle>
                    </Box>

                    {/*  */}
                    <Button onClick={handleClose} autoFocus>
                        <CloseIcon />
                    </Button>
                </Box>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                        <Typography variant="overline" display="block" gutterBottom>
                            How would you like to create your new invite?
                        </Typography>

                        <Grid container spacing={3} className="mb-">
                            <Grid item lg={6} xs={6} md={6}>
                                <Button variant="outlined" className='d-block w-100' onClick={moveToInvite}>
                                    <Typography variant="overline" display="block" gutterBottom>
                                        Create
                                    </Typography>
                                    <Typography variant="overline" display="block" gutterBottom>
                                        Blank invite
                                    </Typography>
                                    <PostAddIcon />
                                </Button>
                            </Grid>

                            <Grid item lg={6} xs={6} md={6}>
                                <Button variant="outlined" className='d-block w-100'>
                                    <Typography variant="overline" display="block" gutterBottom>
                                        Create from
                                    </Typography>
                                    <Typography variant="overline" display="block" gutterBottom>
                                        Template
                                    </Typography>
                                    <BackupTableIcon />
                                </Button>
                            </Grid>

                        </Grid>

                    </DialogContentText>

                    <DialogActions className='pt-4 px-0'>
                        <Button onClick={handleClose} className='btn-blue' variant="outlined">Disagree</Button>
                        <Button onClick={handleClose} autoFocus variant="outlined">
                            Agree
                        </Button>
                    </DialogActions>
                </DialogContent>

            </Dialog>
        </Box>
    )
}
export default memo(CreateNewInviteModal);