import React from "react";
import { Box, Grid ,TextField} from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// import AutoComplete from "../../components/AutoComplete";
import AutoComplete from "../components/AutoComplete";
import { useDispatch, useSelector } from 'react-redux';
import {
    Json_GetFolders_Redux,
} from "../redux/ak_api_helper";

export default function UploadForm() {
    const dispatch = useDispatch();
    const folders = useSelector((state) => state.folders); 
   

    React.useEffect(() => {
        dispatch(Json_GetFolders_Redux()); 
    }, [dispatch]);
    return (
        <>
            <Grid container>
                <Grid item sx={12} sm={12} md={12} lg={12}>
                    <Box sx={style.container}>
                        <h1>Handle Data</h1>
                        <Box sx={style.content}>
                            <FormControl fullWidth>
                                 <AutoComplete
                lable="Folder"
                ComData={folders}
                target="Folder"
              
              />
                            </FormControl>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

const style = {
    container: {
        position: "relative",
        margin: "0 auto",
        background: "#ecf0f1",
        width: "550px",
        borderRadius: "5px",
        boxShadow: "3px 3px 10px #333",
        padding: "15px"
    },
    content: {
        marginTop: "10px"
    }
};
