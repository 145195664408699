import React from 'react'
import { Box } from '@mui/material'
import CustomBreadCrumbs from '../../components/CustomBreadCrumbs';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SetupInvite from '../../components/SetupInvite';
import StepContacts from '../../components/StepContacts';
import StepRequests from '../../components/StepRequests';
import StepReview from '../../components/StepReview';

const steps = [{id: 0, label:' 1. Setup',comp:<SetupInvite/>, completed: false},{id: 1, label:' 2. Contacts',comp:<StepContacts />, completed: false},{id: 2, label:' 3. Requests',comp:<StepRequests />, completed: false},{id: 3, label:' 4. Review',comp:<StepReview />, completed: false}];

const breadcrumbItems = [
    { label: 'Invites', href: '/' },
    { label: 'Create Invite', href: '/material-ui/getting-started/installation/' },
    { label: 'Breadcrumb' } // No href means this is the current page
]; 
function CreateInvite() {
    
    const [activeStep, setActiveStep] = React.useState(steps[0]);

    const [isAllStepsCompleted, setIsAllStepsCompleted] = React.useState(false);

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return activeStep.id;
    };

    const allStepsCompleted = () => {
        steps.every(stp => stp.completed===true) ? setIsAllStepsCompleted(true) : setIsAllStepsCompleted(false);
    };

    const handleNext = (stp) => {
        if(stp?.id){
            steps[stp.id-1].completed = true;
            setActiveStep(stp);
            return;
        }else{
            steps[activeStep.id].completed = true;
            allStepsCompleted();
        }
    };

    const handleBack = (stp) => {
        setActiveStep(stp);
    };

    const handleStep = (stp) => () => {
        setActiveStep(stp);

    };

    const handleComplete = (stp) => {
        handleNext(stp);
    };

    const handleReset = () => {
        setActiveStep(steps[0]);
        steps.map(itm=>{itm.completed=false});
        setIsAllStepsCompleted(false);
    };

    return (
        <Box className="container">
            <Box className="row">
                <Box className='col-xxl-9 m-auto'>

                    <Box>
                        <CustomBreadCrumbs items={breadcrumbItems}/>
                    </Box>

                    <h2 className='title'>Create Invite</h2>

                    <Box sx={{ width: '100%' }}>
                        <Stepper nonLinear activeStep={activeStep.id}>
                            {steps.map((stp, index) => (
                                <Step key={stp.label} completed={stp.completed}>
                                    <StepButton color="inherit" onClick={handleStep(stp)}>
                                        {stp.label}
                                    </StepButton>
                                </Step>
                            ))}
                        </Stepper>
                        <div>
                            {isAllStepsCompleted ? (
                                <React.Fragment>
                                    <Typography sx={{ mt: 2, mb: 1 }}>
                                        All steps completed - you&apos;re finished
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        <Button onClick={handleReset}>Reset</Button>
                                    </Box>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {activeStep.comp}
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Button
                                            color="inherit"
                                            disabled={activeStep.id === 0}
                                            onClick={() => handleBack(steps[activeStep.id - 1])}
                                            sx={{ mr: 1 }}
                                        >
                                            Back
                                        </Button>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        {/* <Button onClick={handleNext} sx={{ mr: 1 }}>
                                            Next
                                        </Button> */}
                                        {activeStep.id !== steps.length &&
                                        // (activeStep.completed ? (    // don't touch, it can be helpful sometime
                                            (false ? (
                                                <Typography variant="caption" sx={{ display: 'inline-block' }}>
                                                    Step {activeStep.id + 1} already completed
                                                </Typography>
                                            ) : (
                                                <Button onClick={() => handleComplete(steps[activeStep.id + 1])}>
                                                    {completedSteps() === totalSteps() - 1
                                                        ? 'Send invite'
                                                        : 'Next'}
                                                </Button>
                                            ))}
                                    </Box>
                                </React.Fragment>
                            )}
                        </div>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default CreateInvite