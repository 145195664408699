import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { useLocalStorage } from "../custom-hook/useLocalStorage";
import SearchIcon from "@mui/icons-material/Search";

import {
  GetAllFolders_Json_Redux,
  GetClientsByFolder_Json_Redux,
  Json_GetAccountUsersContactsByClient_Redux,
} from "../redux/api_helper";
import CompanyGrid from "./CompanyGrid";
import { Email } from "@mui/icons-material";
import { Json_GetSupplierListByProject } from "../redux/dev_chetan_api_helper";
export default function Companies({ chgObj, setChgObj }) {
  let objReq = useLocalStorage("accid");
  let dispatch = useDispatch();
  const { clientListByProjectId: getCustomersByClient } = useSelector(
    (state) => state.dev_chetan
  );
  const [getFolder, setGetFolders] = React.useState([]);
  const [selectedFolder, setSelectedFolder] = React.useState({
    FolderID: "15",
    Folder: "Client",
  });
  const [searchQuery, setSearchQuery] = React.useState("");

  React.useEffect(() => {
    dispatch(
      GetAllFolders_Json_Redux(function (res) {
        setGetFolders(res);
        setSelectedFolder(res[0]);
      })
    );
    dispatch(Json_GetSupplierListByProject());
  }, [dispatch]);

  const getFolderObject = (event, value) => {
    if (value && value.FolderID) {
      // const newObj = { ...objReq, folder: parseInt(value.FolderID) };
      // console.log('GetClientsByFolder Request Object===', newObj);
      // dispatch(GetClientsByFolder_Json_Redux(newObj, function (res) {
      //     setClientsByFolder(res);
      // }));
      dispatch(Json_GetSupplierListByProject(value.FolderID));
    } else {
      // setClientsByFolder([]);
    }
  };
  //Get custome r details clients and folders
  const getClientIdMethod = (event, value) => {
    console.log("getClientIdMethod ===", value);
    //Dummy content
    if (value) {
      const contactObj = {
        agrno: objReq.accid,
        Email: objReq.email,
        password: objReq.password,
        Clientid: value.ClientID,
      };

      // dispatch(Json_GetAccountUsersContactsByClient_Redux(contactObj, function (res)
      // {
      //     setCustomersByClient(res);
      // }));
    } else {
      // setCustomersByClient(getCustomersByClient);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredContacts = getCustomersByClient?.filter((contact) =>
    Object.values(contact).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  // console.log('getCustomersByClient' , getCustomersByClient)

  return (
    <>
      <Box className="relative">
        <Box className="search-area">
          <div className="row">
            <div className="col-md-6">
              <Box className="bg-white">
                <Autocomplete
                  value={selectedFolder}
                  disablePortal
                  id="combo-box-demo"
                  options={getFolder}
                  getOptionLabel={(option) => option.Folder}
                  onChange={getFolderObject}
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Folder" />
                  )}
                  className="w-100"
                />
              </Box>
            </div>
            <div className="col-md-6">
              <Box className="search-input mb-3">
                <SearchIcon />
                <TextField
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                  fullWidth
                  label="Search"
                  size="small"

                  // onChange={(e) =>
                  //   console.log(e.target.value, "=============")
                  // }
                />
              </Box>
            </div>
          </div>
        </Box>

        <Box className="row search-ar">
          <Grid item xs={6}></Grid>

          {/* <Grid item xs={6}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={getClientsByFolder}
                        key="clients"
                        getOptionLabel={(option) => option.Client}
                        onChange={getClientIdMethod}
                        size='small'
                        renderInput={(params) => <TextField {...params} label="Client" />}
                        className='w-100'
                    />
                </Grid> */}
        </Box>
      </Box>
      {/* <hr /> */}
      <Box className="mt-0">
        <CompanyGrid dataSourceOptions={filteredContacts} />
      </Box>
    </>
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
