import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import React, { useCallback } from "react";
import { Outlet } from "react-router-dom";
import AllModals from "../modals/AllModals";
import { DrawerHeader } from "../style-utils/sidebarNavUtils";
import CustomAppbar from "./CustomAppbar";
import CustomDrawer from "./CustomDrawer";
import { useMediaQuery } from "@mui/material";

const SideBar = () => {
  const isMobile = useMediaQuery('(max-width:600px)');

  const [open, setOpen] = React.useState(!isMobile);

  const handleDrawerToggle = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <CustomAppbar open={open} handleDrawerOpen={handleDrawerToggle} />
      <CustomDrawer open={open} handleDrawerToggle={handleDrawerToggle} />

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <AllModals />
        <Outlet />
      </Box>
    </Box>
  );
};

export default SideBar;
