import { Box, Pagination } from "@mui/material";
import React, { useState } from "react";
import PortalMessagesFilters from "./PortalMessagesFilters";
import CustomePortalMessageTable from "../../../components/CustomePortalMessageTable";
import { useDispatch, useSelector } from "react-redux";
import { GetAllReceivedMessages_Json_Redux } from "../../../redux/api_helper";
import { useLocalStorage } from "../../../custom-hook/useLocalStorage";
import moment from "moment";
import NoData from "../../../components/NoData";
import CustomLoader from "../../../components/CustomLoader";

function ReceivedMessages({ value }) {
  let dispatch = useDispatch();
  const {getAllReceivedMessages ,  getAllReceivedMessagesLoding} = useSelector(state => state.docu);
  let objReq = useLocalStorage("accid")

  let receivedObj = {
    accid: objReq.accid,
    email: objReq.email,
    password: objReq.password,
    ccode: '',
    emailAddress: objReq.EmailId,

  };

  React.useEffect(() => {
    dispatch(GetAllReceivedMessages_Json_Redux(receivedObj, function (res) { }))
  }, [dispatch]);

  const TableHeder = [
    "Subject",
    "Sender",
    "Issued On ",
    "Viewed On",
    "Section",
    "",
  ];

  // Create a JSON object from the array using the map method
  const jsonObjectArray = getAllReceivedMessages.map((item) => {

    let documentNo = 'Not available';
    if (item["Document No"]) {
      documentNo = item["Document No"];
    }

    let objectAll = {
      Subject: item?.Subject ? item.Subject : 'Not available',
      Recipient: item["Client Name"] ? item["Client Name"] : 'Not available',
      ApprovalRequested: item["To be Approved"] ? item["To be Approved"] : 'Not available',
      IssuedOn: item.Issued ? moment(item.Issued).format('DD/MM/yyyy') : 'Not available',
      DueDate: item.Section ? item.Section : 'Not available',
      Viewed: item.Viewed ? moment(item.Viewed).format('DD/MM/yyyy') : 'Not available',
      ReferenceName: item["Client Name"] ? item["Client Name"] : 'Not available',
      MessageNo: item["Message No"] ? item["Message No"] : 'Not available',
      History: [
        {
          date: "Reference ID",
          customerId: ": " + item.CCode ? item.CCode : 'Not available',
        },
        {
          date: "Date Approved",
          customerId: ": " + item["Date Approved"] ? moment(item["Date Approved"]).format('DD/MM/yyyy') : 'Not available',
        },

        {
          date: "Email ID",
          customerId: ": " + item.EMailId ? item.EMailId : 'Not available',
        },
        {
          date: "Docusoft ID",
          customerId: ": " + documentNo,
        },
      ],
    }
    return objectAll;
  });

  //Add paginations
  const [currentPage, setCurrentPage] = React.useState(1);
  let itemsPerPage = 10;
  // Calculate the total number of pages
  const totalPages = Math.ceil(jsonObjectArray.length / itemsPerPage);

  // Determine the items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = jsonObjectArray.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  //End Pagination 

 const handleChange = (e,value) => {
    setCurrentPage(value);
  };


  return (
    <>
      <Box className="white-box">
        {/* <PortalMessagesFilters filterType={value} /> */}
      </Box>
      <Box>      
        {getAllReceivedMessagesLoding ? (
          <>
            <Box>
              <CustomLoader />
            </Box>
          </>
        ) :currentItems && currentItems.length > 0 ? (
          <>
            <CustomePortalMessageTable
              TableHeder={TableHeder}
              TableData={currentItems}
              value={value}
            />
          </>
        ) : currentItems.length == 0 ? (
          <NoData messsage="No Message here" />
        ) : (
          ""
        )}
      </Box>    
       <Box className='mt-4'>
           <Pagination count={totalPages} page={currentPage}   variant="outlined" shape="rounded" onChange={handleChange} className="custom-pagination" />
        </Box>
    </>
  );
}

export default ReceivedMessages;
