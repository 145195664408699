import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LinkDropDown from "../../../components/LinkDropDown";
import TuneIcon from "@mui/icons-material/Tune";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import CreateIcon from "@mui/icons-material/Create";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import { setOpenCompanyModal, setOpenContactDetailModal } from "../../../redux/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import PersonIcon from "@mui/icons-material/Person";
import ApartmentIcon from "@mui/icons-material/Apartment";
import {
  GetAllFolders_Json_Redux,
  GetAllReceivedMessagesByFolder_Json_Redux,
  GetAllSentMessagesByFolder_Json_Redux,
  GetClientsByFolder_Json_Redux,
  Json_GetAccountUsersContactsByClient_Redux,
} from "../../../redux/api_helper";
import { useLocalStorage } from "../../../custom-hook/useLocalStorage";
import {
  handleOpenContactModal,
  handleOpenCreateContactModal,
} from "../../../redux/docuSlice";
import { Json_GetAllContactsByClientID_Redux, Json_GetClientAddresses_Redux, Json_GetClientCardDetails_Redux, Json_GetConfiguration, Json_Portal_GetClientListByEmail_Redux } from "../../../redux/ak_api_helper";
import { getClientIdMethod_Redux, getClientIdName_Redux, getContactEmailID_Redux, getFolderObject_Redux } from "../../../redux/akSlice";

function PortalMessagesFilters({ filterType }) {
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(Json_Portal_GetClientListByEmail_Redux());
  },[]);
  const [getFolder, setGetFolders] = React.useState([]);
  const [getClientsByFolder, setClientsByFolder] = React.useState([]);
  const [getCustomersByClient, setCustomersByClient] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [folderSet, setfolderId] = React.useState(0);
  const [clientSet, setClientCode] = React.useState("");
  //Set label
  const [folerLabelSet, setFolderLabel] = React.useState("All");
  const [clientLabelSet, setClientLabel] = React.useState("All");
  const [contactLabelSet, setContactLabel] = React.useState("All");

  let objReq = useLocalStorage("accid");
  const { GetFolders } = useSelector(state => state.ak);
  const { companyAddress } = useSelector(state => state.ak);
  const { companyCardDitiels } = useSelector(state => state.ak);
  const { bussiness, sources, mangers, companyStatus } = useSelector(state => state.ak);
  const [FolderObject, setFolderObject] = useState();
  const [ClientIdMethod, setClientIdMethod] = useState();

  // dropdown
  const [EditanchorEl, EditsetAnchorEl] = React.useState(null);
  const Editopen = Boolean(EditanchorEl);
  const EdithandleClick = (event) => {
      EditsetAnchorEl(event.currentTarget);
}
const EdithandleClose = () => {
    EditsetAnchorEl(null);
  };

  const hanleModalOpne = () => {
    dispatch(setOpenCompanyModal(true));
  }
  const hanleModalOpneContact = () => {
    dispatch(handleOpenContactModal(true));
  }
  // dropdown
  const [AddanchorEl, AddsetAnchorEl] = React.useState(null);
  const Addopen = Boolean(AddanchorEl);
  const AddhandleClick = (event) => {
    AddsetAnchorEl(event.currentTarget);
  };
  const AddhandleClose = () => {
    AddsetAnchorEl(null);
  };

  //Added all filters data by 1 jun 2023
  React.useEffect(() => {
    dispatch(
      GetAllFolders_Json_Redux(function (res) {
        setGetFolders(res);
      })
      );
    dispatch(Json_GetConfiguration());
  }, [dispatch]);

  //Get all client by folders seleected
  let filterObj = {
    accid: objReq.accid,
    email: objReq.email,
    password: objReq.password,
    emailAddress: objReq.EmailId,
  };
  const [isVisible, setIsVisible] = useState(false);
  const getFolderObject = (event, value) => {
    // console.log('getClientIdMethod called with value: ' , value);
    dispatch(getFolderObject_Redux(`${value.FolderID}`));

    setIsVisible(true);
    setLoading(true);
    if (value && value.FolderID) {
      setFolderObject(value.FolderID);
      setFolderLabel(value.Folder); //Set folder label
      const newObj = { ...objReq, folder: parseInt(value.FolderID) };
      dispatch(
        GetClientsByFolder_Json_Redux(newObj, function (res) {
          setClientsByFolder(res);
        })
      );
      //Filter data sections
      setfolderId(parseInt(value.FolderID));
      let filterFolderObj = {
        ...filterObj,
        folder: parseInt(value.FolderID),
        ccode: "",
      };
      console.log(`Folder obj=============${filterType} : `, filterFolderObj);
      if (filterType == 1) {
        //Sent messages

        dispatch(
          GetAllSentMessagesByFolder_Json_Redux(
            filterFolderObj,
            function (res) {
              setLoading(false);
            }
          )
        );
      } else if (filterType == 2) {
        //recieved messages
        dispatch(
          GetAllReceivedMessagesByFolder_Json_Redux(
            filterFolderObj,
            function (res) {
              setLoading(false);
            }
          )
        );
      }
    } else {
      setClientsByFolder([]);
    }
  };

  //Get custome r details clients and folders
  const getClientIdMethod = (event, value) => {
    console.log('getClientIdMethod called with value: ', value);
    setIsVisible(true);

    dispatch(getClientIdMethod_Redux(`${value.ClientID}`));

    dispatch(getClientIdName_Redux(`${value.Client}`));

    setLoading(true);
    if (value) {
      setClientIdMethod(value.ClientID)
      setClientLabel(value.Client);
      const contactObj = {
        agrno: objReq.accid,
        Email: objReq.email,
        password: objReq.password,
        Clientid: value.ClientID,
      };

      dispatch(
        Json_GetAccountUsersContactsByClient_Redux(contactObj, function (res) {
          setCustomersByClient(JSON.parse(res));
        })
      );

      //Filter data sections
      setClientCode(value.CCode);
      let filterclientObj = {
        ...filterObj,
        folder: folderSet,
        ccode: value.CCode,
      };
      console.log(`client obj=============${filterType} : `, filterclientObj);
      if (filterType == 1) {
        //Send Messages
        dispatch(
          GetAllSentMessagesByFolder_Json_Redux(
            filterclientObj,
            function (res) {
              setLoading(false);
            }
          )
        );
      } else if (filterType == 2) {
        //recieved messages
        dispatch(
          GetAllReceivedMessagesByFolder_Json_Redux(
            filterclientObj,
            function (res) {
              setLoading(false);
            }
          )
        );
      }
    } else {
      setCustomersByClient([]);
    }
    const contectData = {
      agrno: localStorage.getItem("agrno"),
      Email: localStorage.getItem("Email"),
      password: localStorage.getItem("password"),
      ProjectID: FolderObject,
      ClientID: value.ClientID
  };
  
  const clientCardData = {
      agrno: localStorage.getItem("agrno"),
      Email: localStorage.getItem("Email"),
      password: localStorage.getItem("password"),
      intProjectId: FolderObject,
      strOrignatorNumber: value.ClientID
  };
  
  const clientAddress = {
      agrno: localStorage.getItem("agrno"),
      Email: localStorage.getItem("Email"),
      password: localStorage.getItem("password"),
      clientId: value.ClientID
  };
  
  setTimeout(() => {
      dispatch(Json_GetAllContactsByClientID_Redux(contectData));
  }, 1000);
  
  setTimeout(() => {
      dispatch(Json_GetClientCardDetails_Redux(clientCardData));
  }, 1000);
  
  setTimeout(() => {
      console.log('setClientIdMethod', clientAddress);
      dispatch(Json_GetClientAddresses_Redux(clientAddress));
  }, 1000);
  
  };

  const getContactfilters = (event, value) => {
    dispatch(getContactEmailID_Redux(`${value.EmailId}`));
    // localStorage.setItem('getContactEmailID', `${value.EmailId}`);
    console.log('getContactEmailID', value);
    setIsVisible(true);
    setLoading(true);
    setContactLabel(value.ContactName);
    let filterContactObj = {
      accid: objReq.accid,
      email: objReq.email,
      password: objReq.password,
      emailAddress: value?.EmailId ? value.EmailId : objReq.EmailId,
      folder: folderSet,
      ccode: clientSet,
    };
    console.log(`Contact obj=============${filterType} : `, filterContactObj);
    if (filterType == 1) {
      dispatch(
        GetAllSentMessagesByFolder_Json_Redux(filterContactObj, function (res) {
          setLoading(false);
        })
      );
    } else if (filterType == 2) {
      //recieved messages
      dispatch(
        GetAllReceivedMessagesByFolder_Json_Redux(
          filterContactObj,
          function (res) {
            setLoading(false);
          }
        )
      );
    }
  };

  //Create contact and open modal by click contact
  const createContactModalOpen = () => {
    dispatch(handleOpenContactModal(true));
  };

const refreshData = ()=>{
  window.location.reload()
 }

  return (
    <Box className="d-flex justify-content-between flex-wrap">
      {/* {loading ? 'Loading...' : ''} */}
      <Box className="d-flex">
        <Box className="d-flex align-items-center me-1">
          <Typography className="poppins-medium font-14" variant="Body1">
            Folder:
          </Typography>

          <LinkDropDown
            label={folerLabelSet}
            ddType={"1"}
            dataOpt={getFolder}
            changeMethod={getFolderObject}
            isVisible={isVisible}
            setIsVisible={setIsVisible}
          />
        </Box>
        <Box className="d-flex align-items-center me-1">
          <Typography className="poppins-medium font-14" variant="Body1">
            Client:
          </Typography>
          <LinkDropDown
            label={clientLabelSet}
            ddType={"2"}
            dataOpt={getClientsByFolder}
            changeMethod={getClientIdMethod}
            isVisible={isVisible}
            setIsVisible={setIsVisible}
          />
        </Box>
        <Box className="d-flex align-items-center me-1">
          <Typography className="poppins-medium font-14" variant="Body1">
            Contact:
          </Typography>
          <LinkDropDown
            label={contactLabelSet}
            ddType={"3"}
            dataOpt={getCustomersByClient}
            changeMethod={getContactfilters}
            isVisible={isVisible}
            setIsVisible={setIsVisible}
          />
        </Box>
      </Box>

      <Box className="d-flex">
        {/* <Tooltip title="Contact Details" arrow><Button className='min-width-auto' onClick={() => dispatch(setOpenContactDetailModal(true))}><AssignmentIndIcon className='text-black' /></Button></Tooltip> */}

        <Tooltip title="Edit" arrow>
          <Button className="min-width-auto" onClick={EdithandleClick}>
            <CreateIcon className="text-black" />
          </Button>
        </Tooltip>

        <Menu
          id="basic-menu"
          anchorEl={EditanchorEl}
          open={Editopen}
          onClose={EdithandleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={hanleModalOpne}>
            <PersonIcon className="me-2" /> Client
          </MenuItem>
          <MenuItem onClick={hanleModalOpneContact}>
            <ApartmentIcon className="me-2" /> Contact
          </MenuItem>
        </Menu>

        <Tooltip title="Add" arrow>
          <Button className="min-width-auto" onClick={AddhandleClick}>
            <AddIcon className="text-black" />
          </Button>
        </Tooltip>
        <Menu
          id="basic-menu"
          anchorEl={AddanchorEl}
          open={Addopen}
          onClose={AddhandleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={AddhandleClose}>
            <PersonIcon className="me-2" /> Client
          </MenuItem>
          <MenuItem onClick={createContactModalOpen}>
            <ApartmentIcon className="me-2" /> Contact
          </MenuItem>
        </Menu>

        <Tooltip title="Refresh" arrow>
          <Button className="min-width-auto">
            <RefreshIcon className="text-black" onClick={refreshData}/>
          </Button>
        </Tooltip>

        {/* <Tooltip title="View Setting" arrow><Button className='min-width-auto'><TuneIcon className='text-black' /></Button></Tooltip> */}
      </Box>
    </Box>
  );
}

export default PortalMessagesFilters;
