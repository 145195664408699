import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Checkbox, Fab, Grid, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem';
import CheckboxesMultiSelectTags from './CheckBoxesMultiSelectTags';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import DownloadIcon from '@mui/icons-material/Download';
import FileUpload from './FileUpload';



const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const currencies = [
  {
    value: 'USD',
    label: 'Regular Request',
  },
  {
    value: 'EUR',
    label: 'Read Only Request',
  }
];

function CreateRequestModal() {
 
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

 

  return (

    <React.Fragment>
      <Button variant="contained" className='btn-blue me-3' onClick={handleClickOpen}>
      Create Request 
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='custom-modal'
      >
        <Box className="d-flex align-items-center justify-content-between modal-head">
          <Box className="clearfix">
            <DialogTitle>Create Request</DialogTitle>
          </Box>

          {/*  */}
          <Button onClick={handleClose} autoFocus>
            <CloseIcon />
          </Button>
        </Box>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">

            <Grid container spacing={3} className="mb-">
              <Grid item lg={7} xs={7} md={12}>
                <TextField
                  required
                  label=" Request title"
                  // defaultValue="Hello World"
                  className='w-100'
                  size='small'
                />
              </Grid>

              <Grid item lg={5} xs={5} md={12}>

                <Box component="form"
                  noValidate
                  autoComplete="off">
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Request type "
                    defaultValue="EUR"
                    // helperText="Please select your currency"
                    size='small'
                    className='w-100'
                  >
                    {currencies.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>

                {/* <TextField
                  id="outlined-select-currency"
                  select
                  label="Select"
                  defaultValue="EUR"
                  helperText="Please select your currency"
                  size='small'
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField> */}
              </Grid>

              <Grid item lg={12} xs={12} md={12}>
                {/* <CheckboxesMultiSelectTags /> */}
                <Box className='d-flex align-items-center'>
                  <Fab color="primary" aria-label="add" size='small' className='me-3'>
                    ss
                  </Fab>
                  <span className='pe-1'>Umair Khan</span>
                  <span>(umairkhansmurf4@gmail.com)</span>
                </Box>
              </Grid>

              <Grid item lg={12} xs={12} md={12}>
                <textarea className='textarea w-100'></textarea>
              </Grid>

              <Grid item lg={12} xs={12} md={12}>

                <FileUpload />

              </Grid>
            </Grid>

          </DialogContentText>

          <DialogActions className='pt-4 px-0'>
            <Button onClick={handleClose} className='btn-red' variant="outlined">Cancel</Button>
            <Button onClick={handleClose} autoFocus variant="outlined" className='btn-blue'>
              Save and create another
            </Button>
            <Button onClick={handleClose} autoFocus variant="outlined" className='btn-blue'>
              Save and close
            </Button>
          </DialogActions>
        </DialogContent>


      </Dialog>
    </React.Fragment>
  )
}

export default CreateRequestModal