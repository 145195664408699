import React, { useEffect, useState } from "react";
import CustomBreadCrumbs from "../components/CustomBreadCrumbs";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { handleOpenContactModal } from "../redux/docuSlice";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import ODataStore from "devextreme/data/odata/store";
import CreateIcon from '@mui/icons-material/Create';

import { GetAllContactsJsonRedux } from "../redux/api_helper";
import { useLocalStorage } from "../custom-hook/useLocalStorage";
import { Button, CircularProgress } from "@mui/material";
// import DiscountCell from './DiscountCell.tsx';
import "devextreme/dist/css/dx.light.css";
import ContactGrid from "./ContactGrid";
import CompanyGrid from "./CompanyGrid";
import { useNavigate } from "react-router-dom";
import CompaniesDetails from "./CompaniesDetails";
import { getContactsListByFolder } from "../redux/dev_chetan_api_helper";
import { practiceTest } from "../redux/AxiosInstance";
import { setOpenCompanyModal } from "../redux/modalSlice";

const breadcrumbItemsContact = [
  { label: "Connections", href: "/" },
  { label: "Contacts", href: "/material-ui/getting-started/installation/" },
  { label: "People" },
];
const breadcrumbItemsCompany = [
  { label: "Connections", href: "/" },
  { label: "Contacts", href: "/material-ui/getting-started/installation/" },
  { label: "Companies" },
];
function Contact({ pathurl, tab }) {
  let objReq = useLocalStorage("accid");
  let requiredObj = useLocalStorage("required");
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const allContacts = useSelector((state) => state.dev_chetan.allContacts);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState(tab);
  const [getContactsList, setGetContactsList] = React.useState([]);

  const [varName, funcName] = useState();

  React.useEffect(() => {
    setValue(tab);
  }, [tab]);

  React.useEffect(() => {
    // setLoading(true);
    // dispatch(GetAllContactsJsonRedux(objReq, function (res) {
    //   setLoading(false);
    //   setGetContactsList(res);
    // }))
  }, [dispatch]);

  // React.useEffect(() => {
  //   if (allContacts.length === 0) {
  //     dispatch(getContactsListByFolder(requiredObj.FolderId));
  //   }
  // }, []);

  const handleChange = (event, newValue) => {
    if (newValue == 2) {
      navigate("/user/Contact/Companies");
    }
    if (newValue == 1) {
      navigate("/user/Contact/Contacts");
    }
    setValue(newValue);
  };
  const handleClickOpen = () => {
    dispatch(handleOpenContactModal(true));
  };
  const handleOpenCompanymodal = () => {
    dispatch(setOpenCompanyModal(true));
  };

  return (
    <div>
      <Box className="container-fluid">
        <Box className="row">
          <Box className="col-xxl-12 m-auto">
            {/* <Box>
              <CustomBreadCrumbs
                items={
                  pathurl === "Contacts"
                    ? breadcrumbItemsContact
                    : breadcrumbItemsCompany
                }
              />
            </Box> */}
            <Box className="d-flex align-items-center justify-content-between mt-0 flex-wrap">
              <h2 className="title mb-0">
                {pathurl === "Contacts"
                  ? "Contacts | Directory of contacts"
                  : "Companies | Directory of companies"}
              </h2>

              {pathurl === "Contacts" ? (
                <Button
                  variant="outlined"
                  className="btn-blue"
                  onClick={handleClickOpen}
                >
                  Create Contact
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  className="btn-blue"
                  onClick={handleOpenCompanymodal}
                  startIcon={<CreateIcon />}
                >
                  Create Company 
                </Button>
              )}
            </Box>

            <hr />

            {pathurl === "Contacts" ? (
              <Box sx={{ width: "100%" }}>
                <Box className="mt-2">
                  <TabContext value={value}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                      className="custom-tabs mb-2"
                    >
                      <Tab label="CONTACTS" value="1" />
                      <Tab label="COMPANIES" value="2" />
                    </TabList>
                    <TabPanel
                      value={value}
                      // className="white-box"
                      // style={{ overflow: "auto" }}
                      className="p-0"
                    >
                      {loading ? (
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          height="100%"
                        >
                          <CircularProgress />
                        </Box>
                      ) : (
                        <ContactGrid dataSourceOptions={allContacts} />
                      )}
                    </TabPanel>
                  </TabContext>
                </Box>
              </Box>
            ) : (
              <Box sx={{ width: "100%" }}>
                <Box className="mt-0">
                  <TabContext value={value}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                      className="custom-tabs mb-2"
                    >
                      <Tab label="CONTACTS" value="1" />
                      <Tab label="COMPANIES" value="2" />
                    </TabList>
                    <TabPanel value={value} className="white-box p-0">
                      <CompaniesDetails />
                    </TabPanel>
                  </TabContext>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default Contact;
