import AllServiceParamerter from "./ParamService";

export default class AllService extends AllServiceParamerter {
    constructor(APIUrl,agrno, Email, password) {
        super(APIUrl,agrno, Email, password);
    }
    
    CallNewService(MethodName, callBack) {
       var ParamName = "Param_" + MethodName;
        var data = JSON.stringify(this[ParamName]);
        super.Call(data, MethodName, function (returnValue) {           
            var str = JSON.parse(JSON.stringify(returnValue));					
            if (str != "" && str.d != "0") {
                return callBack(true, str.d);
            } else {
                return callBack(false, []);
            }

        });
    }

}