import CloseIcon from "@mui/icons-material/Close";
import { Box, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCalendlyLink } from "../redux/akSlice";
import { setCalendryMeetingModal } from "../redux/modalSlice";

function CalendryMeetingModal({ open }) {
  const { getCalendlyLink } = useSelector((state) => state.ak.getCalendlyLink);

  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setCalendryMeetingModal(false));
    dispatch(setCalendlyLink(""));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="custom-modal"
      sx={{
        "& .MuiDialog-paper": {
          width: "50%",
          maxWidth: "50%",
          height: "80vh", // Adjust as needed
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        },
        "& .MuiDialogContent-root": {
          flex: 1,
          overflowY: "auto",
        },
      }}
    >
      <Box>
        {/* <Box className="clearfix">
            <DialogTitle>{"Calendly Meeting"}</DialogTitle>
          </Box> */}

        {/* <Button onClick={handleClose} autoFocus>
          <CloseIcon />
          </Button> */}
      </Box>

      <DialogContent>
        <div className="d-flex align-items-center justify-content-end">
          <Button onClick={handleClose} autoFocus>
            <CloseIcon />
          </Button>
        </div>
        <div style={{ height: "100%", overflow: "hidden" }}>
          <iframe
            src={getCalendlyLink}
            width="100%"
            height="100%"
            frameBorder="0"
            title="Calendly Scheduling"
            style={{ overflow: "hidden" }}
          ></iframe>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default CalendryMeetingModal;