import React from "react";
import CreateContactModal from "./CreateContactModal";
import { useSelector } from "react-redux";
import CreateNewInviteModal from "./CreateNewInviteModal";
import ContactModal from "./ContactModal";
import ContactDetailsModal from "./ContactDetailsModal";
import DocumentPreviewModal from "./DocumentPreviewModal";
import UploadFileModal from "./UploadFileModal";
import ApproveDateModal from "./ApproveDateModal";
import SignDocument from "./SignDocument";
import CamposeMessage from "../client/pages/CamposeMessage";
import { Box } from "@mui/material";
import CompanyModal from "./CompanyModal";
import BookMeeting from "./BookMeeting";
import WhatsAppValidModal from "./WhatsAppValidModal";
import CalendryMeetingModal from "./CalendlyMeetingModal";
import IntegrationModal from "./IntegrationModal";

function AllModals() {
  const { openCreateContactModal, openInviteModal, openContactModal } =
    useSelector((state) => state.docu);
  const {
    openContactDetailModal,
    openDocumentPreviewModal,
    openUploadDocument,
    openApproveModal,
    openSignDocument,
    openComposeMessageModal,
    openCompanyModal,
    openBookMeetingModal,
    openWhatsAppValidModal,
    getWhatappNumber,
    getAllUserFromBookingData ,
    getCalendryMeetingModal,
    openIntegrationModal
  } = useSelector((state) => state.modals);

  return (
    <>
      <CreateContactModal open={openCreateContactModal} />
      <CreateNewInviteModal open={openInviteModal} />
      <ContactModal open={openContactModal} />
      <ContactDetailsModal open={openContactDetailModal} />
      <DocumentPreviewModal open={openDocumentPreviewModal} />
      <UploadFileModal open={openUploadDocument} />
      <ApproveDateModal open={openApproveModal} />
      <SignDocument open={openSignDocument} />
      <CompanyModal open={openCompanyModal} />
      <BookMeeting open={openBookMeetingModal} />
      <WhatsAppValidModal open={openWhatsAppValidModal}/>
      <CalendryMeetingModal open={getCalendryMeetingModal} />
      <IntegrationModal open={openIntegrationModal} />

      {openComposeMessageModal && (
        <Box className="compose-message ">
          <CamposeMessage />
        </Box>
      )}
    </>
  );
}

export default AllModals;
