import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import SearchAutocomplete from './AutoComplete';
import AutoComplete from './AutoComplete';
import AddContact from "../assets/images/icons/add-contacts.svg";
import Button from '@mui/material/Button';
import CustomTable from './CustomTable';
import { useDispatch } from 'react-redux';
import { handleOpenCreateContactModal } from '../redux/docuSlice';
import { useLocalStorage } from '../custom-hook/useLocalStorage';
import { GetPortalDocReqTemplates_Json_Redux, Json_GetFolderData_Redux } from '../redux/api_helper';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

function StepContacts() {
  const dispatch = useDispatch();
  let obj = useLocalStorage("required");

  useEffect(() => {
    let o = { ProjectId: parseInt(localStorage.getItem("ProjectId")), SectionId: "-1", ClientId: "" };
    let parm = { ...obj, ...o };
    dispatch(Json_GetFolderData_Redux(parm, function (res) {
      console.log("response", res)
    }))



    let p = {
      "accId": "0003",
      "email": "patrick@docusoft.net",
      "password": "UGF0cmljazEyMy4=",
      "allTemplates": "true",
      "folder": parseInt(localStorage.getItem("ProjectId")),
      "forCompose": "true"
    };

    dispatch(GetPortalDocReqTemplates_Json_Redux(p, function (res) {

    }))
  }, [dispatch])


  return (
    <Box>
      <h2 className='title'>2. Add contacts</h2>

      <Box class="d-flex mb-3">

        <AutoComplete />
        <Box className="d-flex me-2">
          <Button variant="contained" className='btn-blue' onClick={() => dispatch(handleOpenCreateContactModal(true))} startIcon={<AddCircleOutlineIcon />}>Create Contact</Button>
          {/* <CreateContactModal /> */}
        </Box>

      </Box>


      {/* <Box className='text-center py-5 pointer card mt-20'>
        <img src={AddContact} className='d-block m-auto mb-3' />
        Click here to add the contacts you want to send to
      </Box> */}

      <CustomTable />

      <Box>

      </Box>

    </Box>
  )
}

export default StepContacts