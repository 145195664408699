import React from 'react'
import { setOpenSignDocument } from '../../redux/modalSlice';
import { GetSignature_Json_Redux } from '../../redux/ak_api_helper';
import { setfetchDocumentRequest } from '../../redux/docuSlice';
import { useDispatch } from 'react-redux';
import { Box, Chip, Grid, Tooltip, Typography, Button } from "@mui/material";
import DrawIcon from "@mui/icons-material/Draw";
import { clientBodyaccidData } from '../../utils/RequestsFunction';

const SignatureRequest = ({accordionItem}) => {

    const dispatch = useDispatch();
    const portalObj = {
        accid: localStorage.getItem("agrno")
          ? localStorage.getItem("agrno")
          : "0003",
        email: localStorage.getItem("Email")
          ? localStorage.getItem("Email")
          : "patrick@docusoft.net",
        password: localStorage.getItem("password")
          ? localStorage.getItem("password")
          : "UGF0cmljazEyMy4=",
        portalUserEmail: localStorage.getItem("EmailClient")
          ? localStorage.getItem("EmailClient")
          : "",
      };
    const handleSignClick = () => {
        {
            dispatch(setOpenSignDocument(true));
            dispatch(
              GetSignature_Json_Redux(clientBodyaccidData)
            );
            let uploadobj = {
              docTempId: accordionItem?.DocTempId,
              docID: accordionItem?.DocId,
              statusId: "3",
            };
            dispatch(
              setfetchDocumentRequest({
                DocId: accordionItem?.DocId,
                DocTempId: accordionItem?.DocTempId,
              })
            );
            // dispatch(
            //   UpdateRequestedDocStatusAction_Json_ReduxClient(
            //     uploadobj,
            //     function (res) {
            //       console.log(res, "UpdateRequestedDocStatusAction_Json");
            //     }
            //   )
            // );
          }
    }
  return (
    <Button
    component="label"
    variant="text"
    startIcon={<DrawIcon />}
    className="btn-blue"
    sx={{
      padding: "5px",

      fontSize: "12px",

      marginRight: "10px",
    }}
    onClick={handleSignClick}
  >
    Signature
  </Button>
  )
}

export default SignatureRequest