import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import GroupsIcon from "@mui/icons-material/Groups";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Logout from "@mui/icons-material/Logout";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Setting from "../../src/assets/images/icons/Setting.png";
import user from "../assets/images/user-2.svg";
import {
  setNewMessage_Redux,
  setNewRequest_Redux
} from "../redux/docuSlice";
import {
  setOpenBookMeetingModal,
  setOpenComposeMessageModal,
  setOpenIntegrationModal,
} from "../redux/modalSlice";
import { AppBar } from "../style-utils/sidebarNavUtils";

function CustomAppbar({ open, handleDrawerOpen }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = window.location;

  const [AddanchorEl, AddsetAnchorEl] = React.useState(null);
  const [txtType, setTxtType] = useState("Create New ");
  const Addopen = Boolean(AddanchorEl);
const {openComposeMessageModal} = useSelector((state) => state.modals)

  const AddhandleClick = (event) => {
    AddsetAnchorEl(event.currentTarget);
  };
  const AddhandleClose = () => {
    AddsetAnchorEl(null);
  };

  //
  const [NotificationanchorEl, setNotificationAnchorEl] = React.useState(null);
  const openNotification = Boolean(NotificationanchorEl);
  const NotificationhandleClick = (event) => {
    setNotificationAnchorEl(event.currentTarget);
  };
  const NotificationhandleClose = () => {
    setNotificationAnchorEl(null);
  };

  // dropdown
  const [NewRequestanchorEl, NewRequestsetAnchorEl] = React.useState(null);
  const NewRequestopen = Boolean(NewRequestanchorEl);
  const NewRequesthandleClick = (event) => {
    NewRequestsetAnchorEl(event.currentTarget);
  };

  const NewRequesthandleClose = (e) => {
    NewRequestsetAnchorEl(null);
    setTxtType(e.target.outerText);
    dispatch(setNewRequest_Redux(false));
    dispatch(setNewMessage_Redux(true));
  };

  const CreateNewRequest = (e) => {
    NewRequestsetAnchorEl(null);
    setTxtType(e.target.outerText);
    dispatch(setNewRequest_Redux(true));
    dispatch(setNewMessage_Redux(false));
  };

  return (
    <>
      <AppBar position="fixed" className="header" open={open} color="inherit">
        <Toolbar>
          {pathname !== "/user/portal-messages" &&
            pathname !== "/client/portalmessage/inboxes" && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                className="border me-4 btn-primary"
                size="small"
                onClick={() => navigate(-1)}
              >
                <ChevronLeftIcon />
              </IconButton>
            )}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
          >
            <MenuIcon />
          </IconButton>

          <Box className="w-100">
            <Box className="d-flex align-items-center justify-content-end w-100">
              {window.location.pathname &&
                window.location.pathname.split("/")[1] === "client" && (
                  <>
                    <Button
                      id="basic-button"
                      aria-controls={NewRequestopen ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={NewRequestopen ? "true" : undefined}
                      className="btn-blue me-2"
                      endIcon={<GroupsIcon />}
                      onClick={() => {
                        dispatch(setOpenBookMeetingModal(true));
                      }}
                    >
                      <span className="d-md-block d-none">Book Meeting</span>
                    </Button>

                    <Button
                      variant="contained"
                      className="btn-blue me-2"
                      onClick={() => dispatch(setOpenComposeMessageModal(!openComposeMessageModal))}
                      startIcon={<MarkUnreadChatAltIcon />}
                    >
                      <span className="d-md-block d-none">
                        {txtType ? txtType : "New Message"}
                      </span>
                    </Button>
                  </>
                )}

              {window.location.pathname &&
                window.location.pathname.split("/")[1] === "user" && (
                  <Box>
                    <Button
                      id="basic-button"
                      aria-controls={NewRequestopen ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={NewRequestopen ? "true" : undefined}
                      className="btn-blue me-2"
                      endIcon={<GroupsIcon />}
                      onClick={() => {
                        dispatch(setOpenIntegrationModal(true));
                      }}
                    >
                      <span className="d-none d-md-block">
                        Integration
                      </span>
                    </Button>

                    <Button
                      id="basic-button"
                      aria-controls={NewRequestopen ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={NewRequestopen ? "true" : undefined}
                      onClick={NewRequesthandleClick}
                      className="btn-blue"
                      endIcon={<KeyboardArrowDownIcon />}
                    >
                      <span className="d-none d-md-block">
                        {txtType}
                      </span>
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={NewRequestanchorEl}
                      open={NewRequestopen}
                      onClose={NewRequesthandleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                      className="custom-dropdown"
                    >
                      <MenuItem
                        onClick={(e) => {
                          navigate("/user/create-new-request");
                          CreateNewRequest(e);
                        }}
                      >
                        <ListItemIcon>
                          <MarkEmailReadIcon fontSize="small" />
                        </ListItemIcon>
                      Document Request
                      </MenuItem>

                      <MenuItem
                        onClick={(e) => {
                          navigate("/user/create-new-request");
                          NewRequesthandleClose(e);
                        }}
                      >
                        <ListItemIcon>
                          <AddCircleOutlineIcon fontSize="small" />
                        </ListItemIcon>
                      Portal Message
                      </MenuItem>
                    </Menu>
                  </Box>
                )}
              <Box className="ms-0">
                <Tooltip title="Notifications" arrow>
                  <IconButton
                    id="basic-button"
                    aria-controls={openNotification ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openNotification ? "true" : undefined}
                    onClick={NotificationhandleClick}
                  >
                    <NotificationsIcon />
                    <span className="notificaiton-icon"></span>
                  </IconButton>
                </Tooltip>
                <Menu
                  id="basic-menu"
                  anchorEl={NotificationanchorEl}
                  open={openNotification}
                  onClose={NotificationhandleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  className="custom-dropdown"
                  PaperProps={{ sx: { width: "280px" } }}
                >
                  {Array.from({ length: 5 }, (_, i) => (
                    <div key={i}>
                      <MenuItem className="py-2">
                        <ListItemIcon className="relative">
                          <InsertDriveFileIcon />
                        </ListItemIcon>
                        <Box>
                          <Typography
                            variant="body1"
                            className="font-13 text-wrap mb-2"
                          >
                            This is a subject line of message
                          </Typography>
                          <Typography variant="body1" className="font-10">
                            01/06/2024
                          </Typography>
                        </Box>
                      </MenuItem>
                    </div>
                  ))}
                </Menu>
              </Box>
              <Box>
                <Avatar
                  alt="Remy Sharp"
                  src={user}
                  sx={{ width: 30, height: 30, marginLeft: "0px" }}
                  onClick={AddhandleClick}
                  className="pointer"
                />
                <Menu
                  PaperProps={{ sx: { width: "200px" } }}
                  id="basic-menu"
                  anchorEl={AddanchorEl}
                  open={Addopen}
                  onClose={AddhandleClose}
                  className="custom-dropdown"
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {window.location.pathname &&
                    window.location.pathname.split("/")[1] === "client" && (
                      <MenuItem
                        onClick={() => {
                          navigate("/client/Clinet_Profile");
                          AddhandleClose();
                        }}
                      >
                        <Avatar
                          sx={{ width: 32, height: 32 }}
                          className="me-2"
                        />{" "}
                        Profile
                      </MenuItem>
                    )}

                  {window.location.pathname &&
                    window.location.pathname.split("/")[1] === "user" && (
                      <MenuItem
                        onClick={() => {
                          navigate("/user/user-setting");
                          AddhandleClose();
                        }}
                      >
                        {" "}
                        <img
                          src={Setting}
                          height={20}
                          width={20}
                          // sx={{ width: 20, height: 20 }}
                          className="me-2"
                        />{" "}
                        Setting{" "}
                      </MenuItem>
                    )}
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      localStorage.clear();
                      AddhandleClose();
                      if (window.location.pathname.includes("/client")) {
                        navigate("/client/ClientLogin");
                      } else if (window.location.pathname.includes("/user")) {
                        navigate("/user/Login");
                      }
                    }}
                  >
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default memo(CustomAppbar);
