import DrawIcon from "@mui/icons-material/Draw";
import GradingIcon from "@mui/icons-material/Grading";
import InfoIcon from "@mui/icons-material/Info";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocalStorage } from "../../../custom-hook/useLocalStorage";
import {
  DeleteRequestedDocumentFromClient_Json,
  DocusoftRequestedDocument_Json,
  PortalUsersRequestedDocs2_Json_Redux,
  RenewRequestedDocument_Json_Redux,
  RequestedDocumentAsByteArray_Json_Redux,
  RequestedDocumentCreated_Json_Redux,
  UpdateRequestedDocStatusAction_Json_Requested,
  sonamConfirmMessage
} from "../../../redux/api_helper";

import MessageIcon from "@mui/icons-material/Message";
import moment from "moment";
import csv from "../../../assets/images/files-icon/csv.png";
import doc from "../../../assets/images/files-icon/doc.png";
import jpg from "../../../assets/images/files-icon/jpg.png";
import pdf from "../../../assets/images/files-icon/pdf.png";
import png from "../../../assets/images/files-icon/png.png";
import powerpoint from "../../../assets/images/files-icon/powerpoint.png";
import text from "../../../assets/images/files-icon/text.png";
import xls from "../../../assets/images/files-icon/xls.png";

import CustomLoader from "../../../components/CustomLoader";
import NoData from "../../../components/NoData";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import SnippetFolderIcon from "@mui/icons-material/SnippetFolder";
import {
  Box,
  Button,
  Chip,
  Grid,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";


import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PersonIcon from '@mui/icons-material/Person';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { toast } from "react-toastify";
import { setOpenApproveModal } from "../../../redux/modalSlice";
import { useLocation } from "react-router-dom";
import { userBodyaccId } from "../../../utils/RequestsFunction";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function RequestDetails({ id, request }) {
  let dispatch = useDispatch();
  let objReq = useLocalStorage("accid");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search)
  
  const folder = searchParams.get('request'); 
  const emailId = searchParams.get('EmailId');

 const {
    PortalUsersRequestedDocs2: getRequestDetails,
    PortalUsersRequestedDocsLoding,
  } = useSelector((state) => state.docu);
  
  const [getRequestDetails_test, setRequestDetails] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  //Remove Object and add new with replace name
  let newObject = {
    ...userBodyaccId,
    username: emailId,
    sPassword: id ? id : 0,
    filterBy: "All",
  };
  

  delete newObject?.accid;

  React.useEffect(() => {
    dispatch(
      PortalUsersRequestedDocs2_Json_Redux(newObject, function (res) {
        setRequestDetails(res);
      })
    );
  }, [dispatch]);


  //Download files from docx added by 25 may
  const fileDownload = (data) => {
    let newObjectFile = {
      ...objReq,
      accId: objReq.accid,
      username: data.EmailId,
      template: data.DocTempId,
      docid: data.DocId,
    };
    delete newObjectFile.accid;

    dispatch(
      RequestedDocumentAsByteArray_Json_Redux(newObjectFile, function (res) {
        if (res) {
          let base64 = "data:text/plain;base64," + res;
          let filename = data.DocName + data.DocExtension;
          // Split the base64 string to get the mime type and data
          const [mimePart, dataPart] = base64.split(",");
          const mimeType = mimePart.match(/:(.*?);/)[1];

          // Convert the Base64 string to a binary string
          const byteCharacters = atob(dataPart);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          // Create a Blob from the byte array
          const blob = new Blob([byteArray], { type: mimeType });

          // Create a download link
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          document.body.appendChild(link);
          // Trigger the download
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
        } else {
          // Show error message
          console.log("File not found");
        }
        // setLoading(false);
      })
    );
  };

  const statusClass = (data) => {

    switch (true) {
      case data.RequestDocStatus === 1:
        return "approved";
      case data.RequestDocStatus === 2:
        return "pending";
      case data.RequestDocStatus === 3:
        return "approved";
      case data.RequestDocStatus === 4:
        return "approved";
      case data.RequestDocStatus === 5:
        return "rejected";
      case data.RequestDocStatus === 6:
        return "expiring";
      case data.RequestDocStatus === 7:
        return "expired";
      default:
        return "pending";
    }
  };

  //Documents upload files
  const handleFileChange = (e, data) => {
    const files = e.target.files;
    const file = files[0]; // Only allow one file to be selected

    if (!file) {
      return;
    }
    if (file) {
      dispatch(
        sonamConfirmMessage(
          "Are you sure you want to upload this file",
          async function (res) {
            if (res) {
              setLoading(true);
              let obj = {
                accId: objReq.accid,
                email: objReq.email,
                password: objReq.password,
                emailID: data.EmailId,
                docTempId: data.DocTempId,
                docID: data.DocId,
              };
              // Create a FormData object
              const formData = new FormData();
              formData.append("file", file);
              formData.append("fileName", file.name);
              formData.append("fileExtension", file.name.split(".").pop());
              // Convert file to Base64
              const base64File = await fileToBase64(file);
              formData.append("fileBase64", base64File);

              let newFilesObject = {
                ...obj,
                DocExtension: "." + file.name.split(".").pop(),
                attachment: base64File,
              };
              dispatch(
                RequestedDocumentCreated_Json_Redux(
                  newFilesObject,
                  function (res) {
                    if (res) {
                      setLoading(false);
                      //Update status ===
                      //Updated Status for request docu status added by 19 jun
                      let updateDocuObject = { accId: objReq.accid, email: objReq.email, password: objReq.password, emailid: data.EmailId, sPassword: '0', docTempId: data.DocTempId, docID: data.DocId, statusId: 3 }
                      dispatch(UpdateRequestedDocStatusAction_Json_Requested(updateDocuObject, function (response) { console.log("Status Updated for Upload Requested : ", response) }));
                      //ENd : Updated Status for request docu status added by 19 jun
                      // Show success message
                      dispatch(
                        PortalUsersRequestedDocs2_Json_Redux(
                          newObject,
                          function (res) { }
                        )
                      );
                      toast.success("Upload : Successfully.");

                    } else {
                      // Show error message
                      setLoading(false);
                      toast.error("Upload : something wrong.");

                    }
                  }
                )
              );
              //refresh data
              // setTimeout(() => {
              //   setLoading(false);
              //   dispatch(
              //     PortalUsersRequestedDocs2_Json_Redux(
              //       newObject,
              //       function (res) {}
              //     )
              //   );
              // }, "1000");
            }
          }
        )
      );
      //return;
    }
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  //Remove documents by doc id and template id
  const removeDocument = (data) => {
    dispatch(
      sonamConfirmMessage(
        "Are you sure you want to remove this document",
        function (res) {
          if (res) {
            setLoading(true);
            let removeObj = {
              accId: objReq.accid,
              email: objReq.email,
              password: objReq.password,
              emailid: data.EmailId,
              templateID: data.DocTempId,
              docid: data.DocId,
            };
            dispatch(
              DeleteRequestedDocumentFromClient_Json(removeObj, function (res) {
                if (res) {
                  setLoading(false);
                  // Show success message
                  dispatch(
                    PortalUsersRequestedDocs2_Json_Redux(
                      newObject,
                      function (res) { }
                    )
                  );
                  toast.success("Removed : Successfully.");

                } else {
                  // Show error message
                  setLoading(false);
                  toast.error("Removed : Something wrong.");
                }
                
              })
            );
            //refresh data
            // setTimeout(() => {
            //   dispatch(
            //     PortalUsersRequestedDocs2_Json_Redux(
            //       newObject,
            //       function (res) {}
            //     )
            //   );
            // }, "1000");
          }
        }
      )
    );
  };
  //save document on docusoft panel
  const docuSoftDocument = (data) => {
    setLoading(true);
    let moveDocuObject = {
      accId: objReq.accid,
      email: objReq.email,
      password: objReq.password,
      emailID: data.EmailId,
      docTempId: data.DocTempId,
      docID: data.DocId,
      folderid: data.Folder,
    };
    dispatch(
      DocusoftRequestedDocument_Json(moveDocuObject, function (res) {
        if (res) {
          // Show success message
          setLoading(false);
          toast.success("Document DocuSofted successfully!");
        } else {
          // Show error message
          setLoading(false);
          toast.error("something wrong.");
        }
        
      })
    );
  };

  //Rquest status icons for current rquest items
  const requestItemsStatus = (data) => {
    switch (true) {
      case data === 0:
        return (
          <Tooltip title="Request Document" arrow>
            <Button size="small" className="min-width-auto">
              <GradingIcon className="font-18 text-black" />
            </Button>
          </Tooltip>
        );
      case data === 1:
        return (
          <Tooltip title="Request Signature" arrow>
            <Button size="small" className="min-width-auto">
              <DrawIcon className="font-18 text-black" />
            </Button>
          </Tooltip>
        );
      case data === 2:
        return (
          <Tooltip title="Send Form" arrow>
            <Button size="small" className="min-width-auto">
              <MarkEmailReadIcon className="font-18 text-black" />
            </Button>
          </Tooltip>
        );
      case data === 3:
        return (
          <Tooltip title="For Info" arrow>
            <Button size="small" className="min-width-auto">
              <InfoIcon className="font-18 text-black" />
            </Button>
          </Tooltip>
        );
      default:
        return (
          <Tooltip title="Request Document" arrow>
            <Button size="small" className="min-width-auto">
              <GradingIcon className="font-18 text-black" />
            </Button>
          </Tooltip>
        );
    }
  };

  //Send renewal documents for new one
  const sendRenewRequestedDocument = (data) => {
    dispatch(
      sonamConfirmMessage(
        "Are you sure you want send to Renewal Document",
        function (res) {
          if (res) {
            setLoading(true);
            let renealObj = {
              accId: objReq.accid,
              email: objReq.email,
              password: objReq.password,
              emailid: data.EmailId,
              templateID: data.DocTempId,
              docid: data.DocId,
            };
            dispatch(
              RenewRequestedDocument_Json_Redux(renealObj, function (res) {
                if (res) {
                  // Show success message
                  setLoading(false);
                  toast.success("Renewal requested document Successfully.");
                } else {
                  // Show error message
                  setLoading(false);
                  toast.error("Something wrong to renewal requested document.");
                }
                
              })
            );
          }
        }
      )
    );
  };
  //Get status name by status id
  const getStatusChip = (res, requestStatus) => {
    res = String(res);
    switch (res) {
      case "1":
        return <Chip label={requestStatus} size="small" sx={{ background: "#0000FF" }} className="chips" />;
      case "2":
        return <Chip label={requestStatus} size="small" sx={{ background: "#eea706" }} className="chips" />;
      case "3":
        return <Chip label={requestStatus} size="small" sx={{ background: "#008000" }} className="chips" />;
      case "4":
        return <Chip label={requestStatus} size="small" sx={{ background: "#008000" }} className="chips" />;
      case "5":
        return <Chip label={requestStatus} size="small" sx={{ background: "#195e83" }} className="chips" />;
      case "6":
        return <Chip label={requestStatus} size="small" sx={{ background: "#FF0000" }} className="chips" />;
      case "7":
        return <Chip label={requestStatus} size="small" sx={{ background: "#A52A2A" }} className="chips" />;
      default:
        return (
          <Chip
            // label="No Document"
            label="Pending"
            size="small"
            className="chips ms-1"
            sx={{
              background: "#eea706",
            }}
          />
        );
    }
  };


  
  
  return (
    <>
      
      <Grid
        container
        spacing={2}
        className="mt-2"
      >
      </Grid>

      {PortalUsersRequestedDocsLoding ? (
        <>
          <CustomLoader />
        </>
      ) : getRequestDetails && getRequestDetails.length > 0 ? (
        <>
          <Grid
            container
            spacing={2}
            clas
          >
            {getRequestDetails.map((data, i) => (
              // pending, expired,

              <Grid item xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}                // item
                className={`d-flex ${statusClass(data)}`}
                key={i}
              >
              
                <Box className={`mb-0 white-box main-details-box w-100 ${statusClass(data)}`}>
                  <Box className='d-flex align-items-center justify-content-between'>
                    <h2 className="poppins-medium font-14">{data?.DocName}</h2>
                    <Box className='d-flex ms-2 align-items-center'>
                      {requestItemsStatus(data.Status)}
                      {getStatusChip(data.RequestDocStatus, data.StatusName)}
                    </Box>
                  </Box>

                 <hr />

                  <Box className='details-box-row d-flex flex-wrap mb-1'>
                    {/* <Typography className="title">Details</Typography> */}
                    {data?.Individual.length >1 && (

                      <Box className='details-box'>
                        <Typography className="poppins-medium mb-1">Uploaded by</Typography>
                        <Typography className="font-12 badge text-bg-info"><PersonIcon className="font-18" /> {data?.Individual} </Typography>
                      </Box>

                    )}

                    {data?.UploadedDate && (

                      <Box className='details-box'>
                        <Typography className="poppins-medium mb-1">Uploaded on</Typography>
                        <Typography className="font-12 badge text-bg-primary"><CalendarMonthIcon className="font-18" />
                          {data?.UploadedDate
                            ? moment(data.UploadedDate).format("DD/MM/YYYY")
                            : ""}
                        </Typography>
                      </Box>
                    )}
                    {data?.ApprovalDate && (

                      <Box className='details-box'>
                        <Typography className="poppins-medium mb-1">Approved date</Typography>
                        <Typography className="font-12 badge text-bg-success"> <CalendarMonthIcon className="font-18" />
                          {data?.ApprovalDate
                            ? moment(data.UploadedDate).format("DD/MM/YYYY")
                            : ""}
                        </Typography>
                      </Box>
                    )}
                    {data?.IsApproved && (

                      <Box className='details-box'>
                        <Typography className="poppins-medium mb-1">Approved</Typography>
                        <Typography className="font-12 badge text-bg-success"><CheckCircleIcon className="font-18" />
                          {data?.IsApproved === "True" ? "Yes" : "No"}
                        </Typography>
                      </Box>

                    )}
                    {data?.ApprovedByUser && (

                      <Box className='details-box'>
                        <Typography className="poppins-medium mb-1">Approved by</Typography>
                        <Typography className="font-12 badge text-bg-success"><VerifiedUserIcon className="font-18" /> {data?.ApprovedByUser}</Typography>
                      </Box>

                    )}
                    {data?.ExpiryDate && (
                      <Box className='details-box'>
                        <Typography className="poppins-medium mb-1">Expiry date</Typography>
                        <Typography className="font-12 badge text-bg-danger"><CalendarMonthIcon className="font-18" />
                          {data?.ExpiryDate
                            ? moment(data.ExpiryDate).format("DD/MM/YYYY")
                            : ""}
                        </Typography>
                      </Box>

                    )}
                  </Box>

                  <Box >
                    {loading ? "Loading..." : ""}
                    {data?.DocExtension && data?.DocName && (
                      <Box
                        sx={{
                          border: "1px solid #f1f1f1",
                          boxShadow: "none",
                        }}
                        className="white-box d-flex align-items-center justify-content-between p-2 mb-3"
                      >
                        <Box className='d-flex align-items-center'>
                          {data?.DocExtension === ".pdf" ||
                            data?.DocExtension === ".PDF" ? (
                            <img
                              src={pdf}
                              style={{ width: 30 }}
                              alt="documents"
                              className="me-2"
                            />
                          ) : data?.DocExtension === ".png" ||
                            data?.DocExtension === ".PNG" ? (
                            <img
                              src={png}
                              style={{ width: 30 }}
                              alt="documents"
                              className="me-2"
                            />
                          ) : data?.DocExtension === ".docx" ||
                            data?.DocExtension === ".DOCX" ? (
                            <img
                              src={doc}
                              style={{ width: 30 }}
                              alt="documents"
                              className="me-2"
                            />
                          ) : data?.DocExtension === ".xlsx" ||
                            data?.DocExtension === ".xls" ? (
                            <img
                              src={xls}
                              style={{ width: 30 }}
                              alt="documents"
                              className="me-2"
                            />
                          ) : data?.DocExtension === ".jpg" ||
                            data?.DocExtension === ".jpeg" ? (
                            <img
                              src={jpg}
                              style={{ width: 30 }}
                              alt="documents"
                              className="me-2"
                            />
                          ) : data?.DocExtension === ".text" ? (
                            <img
                              src={text}
                              style={{ width: 30 }}
                              alt="documents"
                              className="me-2"
                            />
                          ) : data?.DocExtension === ".csv" ||
                            data?.DocExtension === ".CSV" ? (
                            <img
                              src={csv}
                              style={{ width: 30 }}
                              alt="documents"
                              className="me-2"
                            />
                          ) : data?.DocExtension === ".pptx" ||
                            data?.DocExtension === ".PPTX" ? (
                            <img
                              src={powerpoint}
                              style={{ width: 30 }}
                              alt="documents"
                              className="me-2"
                            />
                          ) : (
                            <PictureAsPdfIcon
                              sx={{ marginRight: "10px" }}
                            />
                          )}
                          {/* <PictureAsPdfIcon sx={{ marginRight: "10px" }} /> */}
                          <Typography className="poppins-medium font-14">
                            {" "}
                            {data?.DocExtension
                              ? data.DocName + data.DocExtension
                              : ""}
                          </Typography>
                        </Box>

                        <Box className='d-flex'>
                          {data?.RequestDocStatus === 3 ||
                            data?.RequestDocStatus === 4 ||
                            data?.RequestDocStatus === 5 ||
                            data?.RequestDocStatus === 6 ||
                            data?.RequestDocStatus === 7 ? (
                            <Tooltip title="Download">
                              <Button
                                className="min-width-auto ms-2 text-bg-info"
                                size="small"
                                onClick={() => fileDownload(data)}
                              >
                                <DownloadIcon />
                              </Button>
                            </Tooltip>
                          ) : (
                            ""
                          )}

                          {data?.RequestDocStatus &&
                            data?.RequestDocStatus === 3 ? (
                            <Tooltip title="Approve">
                              <Button
                                className="min-width-auto ms-2 text-bg-primary"
                                size="small"
                                onClick={() =>
                                  dispatch(
                                    setOpenApproveModal({
                                      id: id,
                                      type: "a",
                                      data: data,
                                      isOpen: true,
                                    })
                                  )
                                }
                              >
                                <ThumbUpIcon className=" font-20" />
                              </Button>
                            </Tooltip>
                          ) : (
                            ""
                          )}
                          {data?.Status !== 3 &&
                            data?.RequestDocStatus === 3 ? (
                            <Tooltip title="Disapprove">
                              <Button
                                className="min-width-auto ms-2 text-bg-danger"
                                size="small"
                                onClick={() =>
                                  dispatch(
                                    setOpenApproveModal({
                                      id: id,
                                      type: "d",
                                      data: data,
                                      isOpen: true,
                                    })
                                  )
                                }
                              >
                                <ThumbDownAltIcon className="font-20" />
                              </Button>
                            </Tooltip>
                          ) : (
                            ""
                          )}
                        </Box>
                      </Box>
                    )}

                    {data?.notes && (
                      <Box container spacing={0}>
                        <Box
                          item
                          sm={12}
                          sx={{
                            background: "#fff",
                            borderRadius: "8px",
                            // padding: "20px",
                            // maxHeight:'100px'
                            maxHeight: "100px",
                            overflow: "auto",
                          }}
                        >
                          <Box>
                            <Typography className="poppins-medium font-14 border-bottom pb-1 mb-1">Reason for disapproval </Typography>
                            <Typography className="font-12 text-">{data?.notes}</Typography>
                          </Box>
                        </Box>
                      </Box>
                    )}


                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        // border: "1px solid red",
                      }}
                      className="mt-4"
                    >
                      {/* new conditoin added for docustatus */}
                      {data?.RequestDocStatus === 6 ||
                        data?.RequestDocStatus === 7 ? (
                        <Button
                          variant="contained"
                          className="btn-blue ms-2"
                          size="small"
                          onClick={() => sendRenewRequestedDocument(data)}
                          startIcon={<MessageIcon />}
                        >
                          Send renewal mail{" "}
                        </Button>
                      ) : (
                        ""
                      )}

                      {data?.RequestDocStatus === 3 ||
                        data?.RequestDocStatus === 4 ||
                        data?.RequestDocStatus === 5 ||
                        data?.RequestDocStatus === 6 ||
                        data?.RequestDocStatus === 7 ? (
                        <Button
                          variant="contained"
                          className="btn-blue ms-2"
                          size="small"
                          onClick={() => docuSoftDocument(data)}
                          startIcon={<SnippetFolderIcon />}
                        >
                          Docusoft{" "}
                        </Button>
                      ) : (
                        ""
                      )}
                      {(data?.Status === 0 && data?.RequestDocStatus === 2) ||
                        (data?.Status === 0 && data?.RequestDocStatus === 5) ||
                        (data?.Status === 0 && data?.RequestDocStatus === 6) ||
                        (data?.Status === 0 && data?.RequestDocStatus === 7) ? (
                        <Button
                          className="btn-blue ms-2"
                          component="label"
                          role={undefined}
                          variant="contained"
                          tabIndex={-1}
                          startIcon={<CloudUploadIcon />}
                          size="small"
                        // onClick={() => dispatch(setOpenUploadDocument({ data: data, isOpen: true }))}
                        >
                          Upload Document
                          <VisuallyHiddenInput
                            type="file"
                            onChange={(e) => handleFileChange(e, data)}
                          />
                        </Button>
                      ) : (
                        ""
                      )}
                      <Button
                        variant="contained"
                        className="btn-red ms-2"
                        size="small"
                        onClick={() => removeDocument(data)}
                        startIcon={<DeleteIcon />}
                      >
                        Delete{" "}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
            </Grid>
          </>
        ) : (
          <NoData message={'No Data Here'} />
        )}
        
    </>
  );
}
export default RequestDetails;
