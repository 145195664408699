import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setOpenDocumentPreviewModal,
  setOpenSignDocument,
} from "../redux/modalSlice";
import pdf from "../../src/assets/images/files-icon/pdf.png";
import CustomeSignature from "../components/CustomeSignature";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DownloadIcon from "@mui/icons-material/Download";
import FolderIcon from "@mui/icons-material/Folder";
import EditIcon from "@mui/icons-material/Edit";
import {
  GetSignature_Json_Redux,
  SignatureRemoved,
  Upload_Signature,
} from "../redux/ak_api_helper";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/system";
import {
  fetchdocuRequestData,
  RequestedDocumentAsByteArray_Json_ReduxData,
  SignRequestedDocument_Json,
} from "../redux/api_helper";
import { toast } from "react-toastify";
import { downloadShareboxDocument } from "../redux/dev_chetan_api_helper";
import { useLocalStorage } from "../custom-hook/useLocalStorage";
import {
  setOpenFromInbox,
  setshareboxDocForPreviewURL,
} from "../redux/dev_chetan_slice";
import HtmlEditorDX from "../user/pages/create-request/HtmlEditor";
import { clientBodyaccIdData } from "../utils/RequestsFunction";
import { useLocation } from "react-router-dom";

function SignDocument({ open }) {
  const { agrno } = useLocalStorage();
  const [signaturePreview, setSignaturePreview] = useState(true);
  const [signatureSetup, setSignatureSetup] = useState(false);
  const [commentHtml, setCommentHtml] = useState("");
  const [signatureSetupVisible, setSignatureSetupVisibleSetting] =
    useState(true);

    const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const customerId = searchParams.get("customerId");
  const EmailID = searchParams.get("EmailID");

  const [openSignatur, setOpenSignatur] = React.useState(false);
  const [AddanchorEl, AddsetAnchorEl] = React.useState(null);
  const Addopen = Boolean(AddanchorEl);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setOpenSignDocument(false));
    setSignaturePreview(true);
    setSignatureSetup(false);
  };

  const handleChangeSignature = () => {
    // dispatch(SignatureRemoved(obj12));
    setSignaturePreview(false);
    setSignatureSetup(true);
  };

  const handleChangeSignatureSaved_Json = () => {
    const signObj = {
      ...clientBodyaccIdData,
      DocTempId: portalAllRequest.DocTempId ? portalAllRequest.DocTempId : "",
      DocId: portalAllRequest.DocId ? portalAllRequest.DocId : "",
      sEmail: "patrick.docusoft@outlook.com",
    };
    dispatch(
      SignRequestedDocument_Json(signObj, function (res) {
        if (res.length > 0) {          
          toast.error(res);
        }else{
          toast.success("Document Signed");     
          dispatch(fetchdocuRequestData(EmailID));
          dispatch(setOpenSignDocument(false));
        }
      })
    );
  };

  const fileDownload = (data) => {
    let newObjectFile = {
      accId: "0003",
      email: "patrick@docusoft.net",
      password: "UGF0cmljazEyMy4=",
      username: data?.EmailId,
      template: data?.DocTempId,
      docid: data?.DocId,
    };
    dispatch(
      RequestedDocumentAsByteArray_Json_ReduxData(
        newObjectFile,
        function (res) {
          if (res) {
            let base64 = "data:text/plain;base64," + res;
            let filename = data.DocName + data.DocExtension;
            const [mimePart, dataPart] = base64.split(",");
            const mimeType = mimePart.match(/:(.*?);/)[1];
            const byteCharacters = atob(dataPart);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: mimeType });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          } else {
            console.log("File not found");
          }
        }
      )
    );
  };

  const AddhandleClick = (event) => {
    AddsetAnchorEl(event.currentTarget);
  };

  const AddhandleClose = () => {
    AddsetAnchorEl(null);
  };

  let portalAllRequest = useSelector(
    (state) => state.docu.fetchDocumentRequest
  );

  const { portalMessageDocuments, portalDocIdForSignDocModal, openFromInbox } =
    useSelector((state) => state.dev_chetan);

  //  Aakash Singh
  const { signature } = useSelector((state) => state.ak);
  const obj12 = {
    accid: localStorage.getItem("agrno"),
    email: localStorage.getItem("Email"),
    password: localStorage.getItem("password")
  };
  // React.useEffect(() => {
  //   dispatch(GetSignature_Json_Redux(obj12));
  // }, [dispatch]);


  useEffect(() => {
    return () => {
      dispatch(setOpenFromInbox(false));
    };
  }, []);

  const base64String = signature; // Replace with your Base64 string
  const format = "png";
  const ImageBox = styled(Box)({
    width: "100%",
    height: "auto",
  });
  
  const downloadFile = (e, doc) => {
    e.stopPropagation();
    dispatch(
      downloadShareboxDocument(
        portalDocIdForSignDocModal,
        doc.Attachid,
        doc.DocExtension,
        doc.PortalName
      )
    );
  };
  const handlePreview = (data) => {
    let url = `https://www.sharedocuments.co.uk/Preview.aspx?accid=${agrno}&attachment=${data.Attachid}&Id=${portalDocIdForSignDocModal}&extension=${data.DocExtension}`;
    dispatch(setOpenDocumentPreviewModal(true));
    dispatch(setshareboxDocForPreviewURL(url));
  };
  const handleCommentHtml = (e, editor) => {
    setCommentHtml(e);
  };
  const handleSendApprovalComment = () => {
    setCommentHtml("");
  };

  
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="custom-modal"
      sx={{ "& .MuiDialog-paper": { width: "60%", maxWidth: "600px" } }}
    >
      <Box className="d-flex align-items-center justify-content-between modal-head">
        <Box className="clearfix">
          <DialogTitle className="p-0">{"Sign & Approve Document"}</DialogTitle>
        </Box>

        {/*  */}
        <Button onClick={handleClose} autoFocus>
          <CloseIcon />
        </Button>
      </Box>

      <DialogContent>
        {openFromInbox ? (
          <Box>
            <Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="h3"
                  className="font-14 mb-1 poppins-semibold text-black "
                >
                  {"Attachment List"}
                </Typography>

                {signatureSetup && (
                  <>
                    <Button className="" onClick={AddhandleClick}>
                      <MoreVertIcon />
                    </Button>

                    <Menu
                      id="basic-menu"
                      anchorEl={AddanchorEl}
                      open={Addopen}
                      onClose={AddhandleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem onClick={AddhandleClose}>
                        <DownloadIcon className="" />
                        Download Selected
                      </MenuItem>
                      <MenuItem onClick={AddhandleClose}>
                        <FolderIcon className="" /> Download All
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </Box>

              {portalMessageDocuments.length > 0
                ? portalMessageDocuments.map((doc) => (
                    <Box
                      sx={{
                        border: "1px solid #f1f1f1",
                        boxShadow: "none",
                      }}
                      className="white-box d-flex align-items-center justify-content-between p-2"
                      onClick={() => handlePreview(doc)}
                    >
                      <Box className="d-flex">
                        <img
                          src={pdf}
                          style={{ width: 30 }}
                          alt="documents"
                          className="me-2"
                        />
                        {/* {console.log('test for priview for jay', portalAllRequest)} */}
                        <Typography className="d-flex align-items-center font-13 poppins-semibold">
                          {doc.PortalName ? doc.PortalName : "Not Available"}
                        </Typography>
                      </Box>

                      <Box>
                        <Tooltip title="Download">
                          <Button className="ms-2 min-width-auto" size="small">
                            <DownloadIcon
                              className="text-blue"
                              onClick={(e) => downloadFile(e, doc)}
                            />
                          </Button>
                        </Tooltip>
                      </Box>
                    </Box>
                  ))
                : "No Attachments"}

              {/* <Box 
                sx={{ 
                  border: "1px solid black", 
                  marginTop: "10px", 
                  borderRadius: "10px", 
                  background: "#7a7a7a", 
                }} 
              > 
                <Box 
                  sx={{ 
                    display: "flex", 
                    justifyContent: "space-between", 
                    alignItems: "center", 
 
                    fontSize: "12px", 
                    color: "#fff", 
                    fontWeight: "700", 
                    padding: "10px", 
                  }} 
                > 
                  <Box sx={{ display: "flex", alignItems: "center" }}> 
                    <img src={pdf} className=" m-auto" height={24} width={24} /> 
 
                    <Typography sx={{ marginLeft: "10px" }}> 
                      {portalAllRequest.DocName + portalAllRequest.DocExtension} 
                    </Typography> 
                  </Box> 
                </Box> 
              </Box> */}
            </Box>

            <hr />

            <HtmlEditorDX
              templateDataMarkup={commentHtml}
              handleEditorChange={handleCommentHtml}
            />

            <hr />

            {signaturePreview && (
              <>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    variant="h3"
                    // className="font-14 mb-1 poppins-semibold text-black "
                    sx={{ fontSize: "14px", fontWeight: "500", color: "black" }}
                  >
                    {"Signature Preview"}
                  </Typography>

                  <IconButton
                    sx={{ marginTop: "-15px" }}
                    onClick={handleChangeSignature}
                  >
                    <EditIcon />
                  </IconButton>
                </Box>
                <Box>
                  <Box
                    sx={{
                      border: "1px solid black",
                      borderRadius: "10px",
                      background: "#fff",
                      height: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ImageBox
                      component="img"
                      src={`data:image/${format};base64,${base64String}`}
                      alt="Base64 Image"
                      sx={{ width: 300, height: 95 }}
                    />
                  </Box>

                  <hr />

                  <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <Button
                      variant="contained"
                      sx={{
                        fontSize: "12px",
                        padding: "5px",
                        marginRight: "10px",
                      }}
                      className="btn-red mb-3"
                      onClick={handleChangeSignatureSaved_Json}
                    >
                      Disapprove{" "}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        fontSize: "12px",
                        padding: "5px",
                        marginRight: "10px",
                      }}
                      className="btn-blue mb-3"
                      onClick={handleChangeSignatureSaved_Json}
                    >
                      Sign & Approve{" "}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        fontSize: "12px",
                        padding: "5px",
                        marginRight: "10px",
                      }}
                      className="btn-blue mb-3"
                      onClick={handleSendApprovalComment}
                    >
                      Comment{" "}
                    </Button>

                    {/* <Button
                    variant="contained"
                    sx={{
                      fontSize: "12px",
                      padding: "5px",
                    }}
                    className="btn-blue"
                    onClick={handleChangeSignature}
                  >
                    <EditIcon sx={{ marginRight: "10px" }} /> Change Signature{" "}
                  </Button> */}
                  </Box>
                </Box>
              </>
            )}

            {signatureSetup && (
              <>
                <Box>
                  <CustomeSignature
                    setSignaturePreview={setSignaturePreview}
                    setSignatureSetup={setSignatureSetup}
                    setSignatureSetupVisibleSetting={
                      setSignatureSetupVisibleSetting
                    }
                    // setOpenSignatur={setOpenSignatur}
                  />
                </Box>
              </>
            )}
          </Box>
        ) : (
          <Box>
            {portalAllRequest && (
              <Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    variant="h3"
                    className="font-14 mb-1 poppins-semibold text-black "
                  >
                    {"Attachment List"}
                  </Typography>

                  {signatureSetup && (
                    <>
                      <Button className="" onClick={AddhandleClick}>
                        <MoreVertIcon />
                      </Button>

                      <Menu
                        id="basic-menu"
                        anchorEl={AddanchorEl}
                        open={Addopen}
                        onClose={AddhandleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem onClick={AddhandleClose}>
                          <DownloadIcon className="" />
                          Download Selected
                        </MenuItem>
                        <MenuItem onClick={AddhandleClose}>
                          <FolderIcon className="" /> Download All
                        </MenuItem>
                      </Menu>
                    </>
                  )}
                </Box>

                <Box
                  sx={{
                    border: "1px solid #f1f1f1",
                    boxShadow: "none",
                  }}
                  className="white-box d-flex align-items-center justify-content-between p-2"
                >
                  <Box className="d-flex">
                    <img
                      src={pdf}
                      style={{ width: 30 }}
                      alt="documents"
                      className="me-2"
                    />
                    {/* {console.log('test for priview for jay', portalAllRequest)} */}
                    <Typography className="d-flex align-items-center font-13 poppins-semibold">
                      {portalAllRequest.DocName + portalAllRequest.DocExtension}
                    </Typography>
                  </Box>

                  <Box>
                    <Tooltip title="Download">
                      <Button className="ms-2 min-width-auto" size="small">
                        <DownloadIcon
                          className="text-blue"
                          onClick={() => fileDownload(portalAllRequest)}
                        />
                      </Button>
                    </Tooltip>
                  </Box>
                </Box>

                {/* <Box 
                sx={{ 
                  border: "1px solid black", 
                  marginTop: "10px", 
                  borderRadius: "10px", 
                  background: "#7a7a7a", 
                }} 
              > 
                <Box 
                  sx={{ 
                    display: "flex", 
                    justifyContent: "space-between", 
                    alignItems: "center", 
 
                    fontSize: "12px", 
                    color: "#fff", 
                    fontWeight: "700", 
                    padding: "10px", 
                  }} 
                > 
                  <Box sx={{ display: "flex", alignItems: "center" }}> 
                    <img src={pdf} className=" m-auto" height={24} width={24} /> 
 
                    <Typography sx={{ marginLeft: "10px" }}> 
                      {portalAllRequest.DocName + portalAllRequest.DocExtension} 
                    </Typography> 
                  </Box> 
                </Box> 
              </Box> */}
              </Box>
            )}

            <hr />

            {signaturePreview && (
              <>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    variant="h3"
                    className="mb-2"
                    // className="font-14 mb-1 poppins-semibold text-black "
                    sx={{ fontSize: "14px", fontWeight: "500", color: "black" }}
                  >
                    {"Signature Preview"}
                  </Typography>

                 {base64String.length <= 1 && <IconButton
                    sx={{ marginTop: "-15px" }}
                    onClick={handleChangeSignature}
                  >
                    <EditIcon />
                  </IconButton>}
                </Box>
                <Box>
                  <Box
                    sx={{
                      border: "1px solid black",
                      borderRadius: "10px",
                      background: "#fff",
                      height: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                   {base64String.length > 0 ?
                     <ImageBox
                     component="img"
                     src={`data:image/${format};base64,${base64String}`}
                     alt="Base64 Image"
                     sx={{ width: 300, height: 95 }}
                   />: <h2 className="title font-18">No Signature</h2>
                   }
                  </Box>

                  <hr />

                  <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <Button
                      variant="contained"
                      sx={{
                        fontSize: "12px",
                        padding: "5px",
                        marginRight: "10px",
                      }}
                      className="btn-blue"
                      onClick={handleChangeSignatureSaved_Json}
                    >
                      Sign & Approve{" "}
                    </Button>

                    {/* <Button
                    variant="contained"
                    sx={{
                      fontSize: "12px",
                      padding: "5px",
                    }}
                    className="btn-blue"
                    onClick={handleChangeSignature}
                  >
                    <EditIcon sx={{ marginRight: "10px" }} /> Change Signature{" "}
                  </Button> */}
                  </Box>
                </Box>
              </>
            )}

            {signatureSetup && (
              <>
                <Box>
                  <CustomeSignature
                    setSignaturePreview={setSignaturePreview}
                    setSignatureSetup={setSignatureSetup}
                    setSignatureSetupVisibleSetting={
                      setSignatureSetupVisibleSetting
                    }
                  />
                </Box>
              </>
            )}
          </Box>
        )}
      </DialogContent>
      {/* <DialogActions> 
        <Button onClick={handleClose}>Close</Button> 
        <Button onClick={handleClose} autoFocus> 
          Agree 
        </Button> 
      </DialogActions> */}
    </Dialog>
  );
}

export default SignDocument;
