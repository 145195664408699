import React, { useState } from "react";
import { Box, Chip, Grid, Tooltip, Typography, Button } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
  fetchdocuRequestData,
  RequestedDocumentCreated_Json,
  RequestedDocumentCreated_Json_Redux,
  RequestedDocumentCreatedNew_Json,
} from "../../redux/api_helper";
import { UpdateRequestedDocStatusAction_Json_ReduxClient } from "../../redux/dev_sonam_api_helper";
import { setRequestDocumentData } from "../../redux/dev_sonam_slice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const DragAndDropRequestDoc = ({ accordionItem, accordionIndex }) => {
  const LoginClient = localStorage.getItem("LoginClient");
  const [preview, setPreview] = useState("");
  let portalAllRequestAll = useSelector(
    (state) => state.docu.docuRequestDataClientAll
  );

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const customerId = searchParams.get("customerId");
  const EmailID = searchParams.get("EmailID");

  const clientBody = {
    accId: localStorage.getItem("accIdClient"),
    username: localStorage.getItem("EmailClient"),
    sPassword: localStorage.getItem("passwordClient")
      ? localStorage.getItem("passwordClient")
      : "All",
  };

  const userBody = {
    accId: localStorage.getItem("accid"),
    email: localStorage.getItem("Email"),
    password: localStorage.getItem("password"),
  };

  const dispatch = useDispatch();
  const [dragOver, setDragOver] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleDrop = (e, index, accordionItem) => {
    e.preventDefault();
    setDragOver(false);
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      uploadImage(e, index, accordionItem);
    }
  };

  const uploadImage = async (e) => {
    console.log(accordionItem, "==========accordionItem");
    let file = e.target.files[0];
    console.log(file, "========file");
    if (LoginClient) {
      let body = {
        ...clientBody,
        emailID: localStorage.getItem("EmailClient"),
        docTempId: `${accordionItem.DocTempId}`,
        docID: `${accordionItem.DocId}`,
        DocExtension: "." + file.name.split(".").pop(),
      };
      const base64File = await fileToBase64(file);
      body.attachment = base64File;
      dispatch(RequestedDocumentCreatedNew_Json(body, (res) => {}));
    } else {
      let body = {
        ...userBody,
        emailID: localStorage.getItem("EmailId"),
        docTempId: accordionItem.DocTempId,
        DocExtension: "." + file.name.split(".").pop(),
        docID: accordionItem.DocId,
      };

      const base64File = await fileToBase64(file);
      body.attachment = base64File;
      dispatch(
        RequestedDocumentCreated_Json_Redux(body, function (res) {
          if (res) {
            let uploadobj = {
              docTempId: accordionItem?.DocTempId,
              docID: accordionItem?.DocId,
              statusId: "3",
            };

            dispatch(
              UpdateRequestedDocStatusAction_Json_ReduxClient(
                uploadobj,
                function (res) {
                  dispatch(fetchdocuRequestData(customerId || EmailID));
                }
              )
            );
          }
        })
      );
    }
  };

  const processFile = (file, index, accordionItem) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const extension = getFileExtension(file.name);
      const base64WithoutPrefix = reader.result.split(",")[1];

      const newFile = {
        name: file.name,
        size: file.size,
        base64: base64WithoutPrefix,
        extension,
      };
      if (newFile) {
        RequestedDocumentCreated_Json(accordionItem, extension, newFile.base64);

        const updatedAccordionData = portalAllRequestAll.map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              fileStore: [newFile],
              FileExtenson: extension,
              FileWithExtension: newFile.name,
              uploadStatus: "Document Uploaded",
              FileOnlyExtension: extension,
            };
          }
          return item;
        });
        dispatch(setRequestDocumentData(updatedAccordionData));
      }
    };

    reader.onerror = (error) => {
      console.error("Error reading file:", error);
    };
  };

  const getFileExtension = (filename) => {
    const extension = filename.slice(
      ((filename.lastIndexOf(".") - 1) >>> 0) + 1
    );
    return extension ? `${extension}` : "";
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  return (
    <Box
      className={`file-upload text-center ${dragOver ? "drag-over" : ""}`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={(e) => handleDrop(e, accordionIndex, accordionItem)}
    >
      {preview && <img src={preview} />}
      <label htmlFor="fileUpload">
        <UploadFileIcon className="font-32 text-gray mb-2" />
        <p>
          Drag and drop a file or <a href="#">click to upload</a>
        </p>
      </label>
      <input type="file" id="fileUpload" onChange={uploadImage} />
    </Box>
  );
};

export default DragAndDropRequestDoc;
