import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  roles: [],
  managers: [],
  CompanyReference: [],
  udf1: [],
  UdfNameId: [],
  My_profile: [],
  signature: [],
  conatactData: [],
  contactDataLoding: true,
  GetFolders:[],
  bussiness:[],
  sources:[],
  mangers:[],
 companyStatus: [],
 companyCardDitiels: [],
 companyCardDitielsAddresses: [], //
 allPortalSentMessages: [],
 companyAddress: [],
 allContact: [],
 getContactEmailID:[],
 getFolderObject:[],
 getClientIdMethod: [],
 getClientIdName: [],
 AgreementListDetails: [],
 ContectDetails1:[],
 CRMContactUDFValues:[],
 originFullDetails: {},
 getAllUserFromBookingData:[],
 getCalendlyLink: '',
 getCalendlyLinkLoding: false,
 getClientContactDetailas: [],
 getClientContactDetailasProfile: []
};

const akSlice = createSlice({
  name: "ak_Slice",
  initialState,
  reducers: {
    setConfigs_Redux: (state, action) => {
      const { roles, managers } = action.payload;
      state.roles = roles;
      state.managers = managers;
    },
    My_profile1: (state, action) => {
      const { My_profile } = action.payload;
      state.My_profile = My_profile;
    },
    My_signature1: (state, action) => {
      const { signature } = action.payload;
      state.signature = signature;
    },
    setConfigs_Redux1ComReference: (state, action) => {
      const { CompanyReference } = action.payload;
      state.CompanyReference = CompanyReference;
    },
    UDF_Add_Redux: (state, action) => {
      const { data,originFullDetails } = action.payload;
      state.data = data;
      state.originFullDetails = originFullDetails;
    },

    ContectDetails: (state, action) => {
      const { ContectDetails1 } = action.payload;
      state.ContectDetails1 = ContectDetails1;
    },
    UdfNameId_Redux: (state, action) => {
      const { UdfNameId } = action.payload;
      state.UdfNameId = UdfNameId;
    },

    // jayprakash To Get Sent Messages of Contact(Pub user) - PortalUsersOutbox_Json

    SentConatactData: (state, action) => {
      // const { conatactData } = action.payload;
      state.conatactData = action.payload.conatactData;
      state.contactDataLoding = false;
    },
    UDFClintData: (state, action) => {
      state.udf1 = [...action.payload];
    },
    Json_GetFolders: (state, action) => {
      const { GetFolders } = action.payload;
      state.GetFolders = GetFolders;
    },
    setJsonConfig_Redux: (state, action) => {
      const { bussiness , mangers ,sources,companyStatus} = action.payload;
      state.mangers = mangers;
      state.bussiness = bussiness;
      state.sources = sources;
      state.companyStatus = companyStatus;
    },
    setcompanyCardDitiels_Redux: (state, action) => {
      const { companyCardDitiels ,companyCardDitielsAddresses } = action.payload;
      state.companyCardDitiels = companyCardDitiels;
      state.companyCardDitielsAddresses = companyCardDitielsAddresses;
     
    },
    setAllPortalSendMessages: (state, action) => {
      // const { conatactData } = action.payload;
      state.allPortalSentMessages = action.payload.conatactData;
      state.contactDataLoding = false;
    },
    setcompanyAddress_Redux: (state, action) => {
      const { companyAddress } = action.payload;
      state.companyAddress = companyAddress;
     
    },
    setAllContact_Redux: (state, action) => {
      const { allContact } = action.payload;
      state.allContact = allContact;
     
    },
    getContactEmailID_Redux: (state, action) => {
      state.getContactEmailID = action.payload;
     
    },
    getFolderObject_Redux: (state, action) => {
    
      state.getFolderObject=action.payload;
    
    },
    getClientIdMethod_Redux: (state, action) => {
     
      state.getClientIdMethod=action.payload;
     
    },
    getClientIdName_Redux: (state, action) => {
     
      state.getClientIdName=action.payload;
    },
   
    CRMContactUDFValues_Redux: (state, action) => {
      const { CRMContactUDFValues } = action.payload;
      state.CRMContactUDFValues = CRMContactUDFValues;
    },
    AgreementListDetails_Redux: (state, action) => {
      const { AgreementListDetails } = action.payload;
      state.AgreementListDetails = AgreementListDetails;
    },
    setAllUserFromBookingData: (state, action) => {
      state.getAllUserFromBookingData = action.payload;
    },
    setCalendlyLink:  (state, action) => {
      state.getCalendlyLink = action.payload;
      state.getCalendlyLinkLoding = true
    },
    setCleintContactDetailas: (state, action) => {
      state.getClientContactDetailas = action.payload;
    },
    setClientContactDetailasProfile: (state, action) => {
      state.getClientContactDetailasProfile = action.payload;
    },
  },
});

export const {
  UDFClintData,
  CRMContactUDFValues_Redux,
  setcompanyCardDitiels_Redux,
  AgreementListDetails_Redux,
  setcompanyAddress_Redux,
  setAllContact_Redux,
  getContactEmailID_Redux,
  getFolderObject_Redux,
  getClientIdMethod_Redux,
  getClientIdName_Redux,
  Json_GetFolders,
  setJsonConfig_Redux,
  setConfigs_Redux,
  UdfNameId_Redux,
  My_signature1,
  My_profile1,
  ContectDetails,
  setConfigs_Redux1ComReference,
  setConfigs_ReduxAddContect1,
  UDF_Add_Redux,
  SentConatactData,
  setAllPortalSendMessages,
  setAllUserFromBookingData,
  setCalendlyLink,
  setCleintContactDetailas,
  setClientContactDetailasProfile
} = akSlice.actions;
export default akSlice.reducer;
