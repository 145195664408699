import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';



const ProSpan = styled('span')({
    display: 'inline-block',
    height: '1em',
    width: '1em',
    verticalAlign: 'middle',
    marginLeft: '0.3em',
    marginBottom: '0.08em',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'url(https://mui.com/static/x/pro.svg)',
});

function Label({
    componentName,
    valueType,
    isProOnly,
}) {
    const content = (
        <span>
            {/* <strong>{componentName}</strong> for {valueType} editing */}
        </span>
    );

    if (isProOnly) {
        return (
            <Stack direction="row" spacing={0.5} component="span">
                <Tooltip title="Included on Pro package">
                    <a
                        href="https://mui.com/x/introduction/licensing/#pro-plan"
                        aria-label="Included on Pro package"
                    >
                        <ProSpan />
                    </a>
                </Tooltip>
                {/* {content} */}
            </Stack>
        );
    }

    return content;
}

export default function CustomDatePicker({ value, onChange  }) {
    // Validate the value prop
    console.log("date check...",value)
    let formattedValue=null;
    if(value){
         formattedValue = dayjs(value).isValid() ? dayjs(value) : dayjs();
    }
   
    

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoItem label={<Label componentName="DatePicker" valueType="date" class="mt-0" />}>
                <DatePicker
                    value={formattedValue}
                    // onChange={(newValue) => comFun(newValue.format('YYYY-MM-DD'))}
                    onChange={onChange}
                    format="DD/MM/YYYY"
                    slotProps={{
                        textField: {
                            size: 'small',
                        }
                    }}
                    renderInput={(params) => (
                        <TextField {...params} />
                    )}
                    className="mt-0"
                />
            </DemoItem>
        </LocalizationProvider>
    );
}
