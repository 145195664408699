import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, FormControlLabel, Grid, Switch, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CountryDropdown from '../components/CountryDropdown'
import { useDispatch, useSelector } from 'react-redux';
import { handleOpenCreateContactModal,setContactModalData } from '../redux/docuSlice';

function CreateContactModal({ open }) {

    const dispatch = useDispatch();
    const contactData = useSelector(state => state.docu.contactModalData);
    const isEditData = Object.keys(contactData).length > 0 ? true: false;

    const [formData,setFormData] = React.useState({
        "First Name": isEditData ? contactData["First Name"]: '',
        "Last Name": isEditData ? contactData["Last Name"]: '',
        "Email": isEditData ? contactData["Email"]: '',
        "Email2": isEditData ? contactData["Email2"]: '',
    });

    const handleClose = React.useCallback(() => {
        dispatch(handleOpenCreateContactModal(false));
        dispatch(setContactModalData({}));
    }, [open]);

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='custom-modal'
            >
                <Box className="d-flex align-items-center justify-content-between modal-head">
                    <Box className="clearfix">
                        <DialogTitle>{"Create Contact"}</DialogTitle>
                    </Box>

                    {/*  */}
                    <Button onClick={handleClose} autoFocus>
                        <CloseIcon />
                    </Button>
                </Box>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                        <Typography variant="overline" display="block" gutterBottom>
                            How would you like to create your new invite?
                        </Typography>

                        <Grid container spacing={3} className="mb-">
                            <Grid item lg={6} xs={6} md={6}>
                                <TextField
                                    required
                                    label="First Name"
                                    name='First Name'
                                    // defaultValue="Hello World"
                                    value={isEditData ? contactData["First Name"]:formData["First Name"]}
                                    fullWidth
                                    size='small'
                                />
                            </Grid>

                            <Grid item lg={6} xs={6} md={6}>
                                <TextField
                                    required
                                    label="Last Name"
                                    name='Last Name'
                                    // defaultValue="Hello World"
                                    value={isEditData ? contactData["Last Name"]:formData["Last Name"]}
                                    fullWidth
                                    size='small'
                                />
                            </Grid>

                            <Grid item lg={12} xs={12} md={12}>
                                <TextField
                                    required
                                    label="Email"
                                    name='Email'
                                    value={isEditData ? contactData["Email"]:formData["Email"]}
                                    fullWidth
                                    size='small'
                                />
                            </Grid>

                            <Grid item lg={9} xs={9} md={9}>
                                <Box className='d-flex'>
                                    <CountryDropdown />

                                    <TextField
                                        required
                                        label="Email"
                                        name='Email2'
                                        value={isEditData ? contactData["Email2"]:formData["Email2"]}
                                        fullWidth
                                        size='small'
                                        className='ms-0'
                                    />
                                </Box>

                            </Grid>
                            <Grid item lg={3} xs={3} md={3}>
                                <FormControlLabel control={<Switch defaultChecked />} label="Label" />
                            </Grid>

                        </Grid>

                    </DialogContentText>

                    <DialogActions className='pt-4 px-0'>
                        <Button onClick={handleClose} className='btn-red' variant="outlined">Cancel</Button>
                        <Button onClick={handleClose} className='btn-blue' variant="outlined">Save and create another </Button>
                        <Button onClick={handleClose} className='btn-blue' autoFocus variant="outlined">
                            Save and close
                        </Button>
                    </DialogActions>
                </DialogContent>

            </Dialog>
        </React.Fragment>
    )
}

export default React.memo(CreateContactModal)