import React from 'react';
import logo from "../assets/images/docu-icon.svg";

function CustomLoader() {
    return <>
        <div id="preloader" className="" style={{ "display": "block" }}>
            <div className="ctn-preloader" id="ctn-preloader">
                <div className="round_spinner">
                    <div className="spinner"></div>
                    <div className="text">
                        <img src={logo} />
                        <h4>
                            <span>Docusoft</span>
                        </h4>
                    </div>
                </div>

            </div>
        </div>
    </>
}

export default CustomLoader;