import { Box } from '@mui/material'
import React from 'react'
import AddContact from "../assets/images/icons/01.png";

function NoData({url, message , sx , height}) {
    return (
        <Box className='text-center py-5 pointer card mt-4' sx={sx}>
            <img src={AddContact}  className='d-block m-auto mb-3' width={100} height={height}/>
            <p>{/* Click here to add the contacts you want to send to */}{message}</p>
        </Box>
    )
}

export default NoData