import { Box, Button, Tooltip, Typography } from "@mui/material";
import React from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { GetDocTypeIcon2Requests } from "../../utils/RequestsFunction";

const ShowRequestDocument = ({ DocumentData }) => {
  
  // Function to convert Base64 string to a Uint8Array
  const base64ToUint8Array = (base64) => {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  };

  // Function to handle preview
  const handlePreview = (doc) => {
    if (doc && doc.DocData) {
      // Convert Base64 to Uint8Array and then to Blob
      const byteArray = base64ToUint8Array(doc.DocData);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      // Open the PDF in a new tab
      window.open(url);
    } else {
      console.error("Document data is not available for preview");
    }
  };

  // Function to handle download
  const downloadFile = (e, doc) => {
    e.preventDefault();

    if (doc && (doc?.DocData || doc?.DocData2)) {
      // Convert Base64 to Uint8Array and then to Blob
      const byteArray = doc?.DocData ? base64ToUint8Array(doc.DocData) : base64ToUint8Array(doc.DocData2);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      // Create a link element and trigger download
      const link = document.createElement("a");
      link.href = url;
      link.download = doc.DocName + doc.DocExtension; // Set the download name
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } else {
      console.error("Document data is not available for download");
    }
  };

  return (
    <Box className="d-flex flex-wrap main-email-attachment w-100">
      <Box
        className="email-attachment mb-2"
        title={
          DocumentData && DocumentData.PortalName
            ? DocumentData.PortalName
            : "Not Available"
        }
        onDoubleClick={() => handlePreview(DocumentData)} // Preview on double click
      >
        <Box className="download">
          <Tooltip title="Download" arrow>
            <Button
              className="min-width-auto"
              size="small"
              onClick={(e) => downloadFile(e, DocumentData)}
            >
              <DownloadIcon className="text-blue font-20" />
            </Button>
          </Tooltip>
        </Box>

        <img
          src={GetDocTypeIcon2Requests(DocumentData.DocExtension)}
          style={{ width: 28 }}
          alt="documents"
        />
        <Typography
          sx={{
            fontSize: "13px",
            fontWeight: "550",
          }}
          className="d-flex align-items-center"
        >
          <span className="d-inline-block ms-2">
            {DocumentData && DocumentData.DocName
              ? DocumentData.DocName + DocumentData.DocExtension
              : "Not Available"}
          </span>
        </Typography>
      </Box>
    </Box>
  );
};

export default React.memo(ShowRequestDocument);
