/* eslint-disable no-loop-func */
import moment from "moment";
import { toast } from "react-toastify";
import { docuEngagerClient, docuSms, portalClient, portalUser, practiceTest, practiceTestOnlyForTesting } from "./AxiosInstance";
import {
  setShareBoxDocuments,
  setAllContacts,
  setCRMContactUDFValues_Redux,
  setAllContactsByClientID,
  setAllMessagesContactsDetails_Redux,
  setShareBoxMessageId,
  setPortalUserInboxMessage,
  setInboxMessageHtml,
  setPortalMessageDocuments,
  setPortalMessageComments,
  setApprovalCertificateBase64,
  setPortalApprovalCommentsHtml,
  setAllPortalUserInboxMessage,
  setInboxMessageHtmlLoading,
  setPortalMessageDocumentsLoading,
  setPortalSections,
  setContactRequestDocCount,
  setMessageReadUnreadCountDetails,
  setClientListByProjectId
} from "./dev_chetan_slice";
import axios from "axios";
import { clientBodyaccidData, userBodyaccId } from "../utils/RequestsFunction";
let rqObj = {
  agrno: localStorage.getItem("agrno") ? localStorage.getItem("agrno") : "0003",
  password: localStorage.getItem("password") ? localStorage.getItem("password") : "UGF0cmljazEyMy4=",
  Email: localStorage.getItem("Email") ? localStorage.getItem("Email") : "patrick@docusoft.net",
};
let portalObj = {
  accId: localStorage.getItem("agrno") ? localStorage.getItem("agrno") : "0003",
  email: localStorage.getItem("Email") ? localStorage.getItem("Email") : "patrick@docusoft.net",
  password: localStorage.getItem("password") ? localStorage.getItem("password") : "UGF0cmljazEyMy4=",
};
let portalUserObj = {
  accid: localStorage.getItem("agrno") ? localStorage.getItem("agrno") : "0003",
  email: localStorage.getItem("Email") ? localStorage.getItem("Email") : "patrick@docusoft.net",
  password: localStorage.getItem("password") ? localStorage.getItem("password") : "UGF0cmljazEyMy4=",
};
let objForClient = {
  accid: localStorage.getItem("accIdClient") ? localStorage.getItem("accIdClient") : "0003",
  username:localStorage.getItem("EmailClient") ? localStorage.getItem("EmailClient") : "patrick.docusoft@outlook.com",
  sPassword:localStorage.getItem("passwordClient") ? localStorage.getItem("passwordClient") : "UzBVQjFTS1Q=",
};
let clientEmail = "patrick.docusoft@outlook.com";

const userBody = { 
  accid: localStorage.getItem("accid") ? localStorage.getItem("accid") : "0003", 
  email: localStorage.getItem("Email"),
  EmailId: localStorage.getItem("EmailId"), 
  password: localStorage.getItem("password"), 
}; 
const userBody_2 = {
  accid: localStorage.getItem("accid") ? localStorage.getItem("accid") : "0003", 
  email: localStorage.getItem("Email"),
  password: localStorage.getItem("password"), 
}

const clientBody = { 
  accId: localStorage.getItem("accIdClient") ? localStorage.getItem("accIdClient") : "0003", 
  username: localStorage.getItem("EmailClient"), 
  sPassword: localStorage.getItem("passwordClient") ? localStorage.getItem("passwordClient") : "All", 
};

const LoginClient = localStorage.getItem("LoginClient")
const isClientLogin = Boolean(localStorage.getItem('LoginClient'));

export const getShareBoxMessageId = () => async (dispatch) => {
  try {
    // const res = await portalClient.post(
    //   "/PortalShareBoxMessageCreated_Json",
    //   portalObj
    // );       // Don't remove and use this commented line code without consulting with dev-chetan
    const res = await portalClient.post(
      "/ShareBoxMessageExists_Json",
      portalObj
    );
    if (res?.data?.d) {
      dispatch(setShareBoxMessageId(res?.data?.d));
    } else {
      dispatch(setShareBoxMessageId(""));
    }
  } catch (err) {
    console.log("Error while calling GetShareboxDocuments_Json", err);
  }
};

export const getShareBoxDocuments = () => async (dispatch) => {
  try {
    const res = await portalClient.post(
      "/GetShareboxDocuments_Json",
      portalObj
    );
    if (res?.data?.d) {
      const result = JSON.parse(res?.data?.d);
      const sortedDocs = result.sort((a, b) =>
        moment(b.Added).diff(moment(a.Added))
      );
      dispatch(setShareBoxDocuments(sortedDocs));
    } else {
      dispatch(setShareBoxDocuments([]));
    }
  } catch (err) {
    console.log("Error while calling GetShareboxDocuments_Json", err);
  }
};


export const saveShareBoxDocument = (allFiles, shareBoxMessageId) => async (dispatch) => {
  const results = await Promise.all(
    Object.values(allFiles).map(async (file) => {
      if (file instanceof File) {
        const byteArray = new Uint8Array(await file.arrayBuffer());
        return portalClient.post("/ShareboxDocumentCreated2_Json", {
          ...userBodyaccId,
          messageID: shareBoxMessageId || "",
          DocFileName: file.name,
          bytes: Array.from(byteArray),
        });
      }
    })
  );

  // Filter out undefined results (if any) and dispatch success or error messages
  const successfulResults = results.filter(Boolean);
  dispatch(getShareBoxDocuments());
  successfulResults.length
    ? toast.success(`${successfulResults.length} Document(s) uploaded successfully`)
    : toast.error("Unable to upload document(s), please try again");
};


export const removeShareboxDocument = (data) => async (dispatch) => {
  try {
    const res = await portalClient.post("/ShareboxDocumentDeleted_Json", {
      ...portalObj,
      messageID: data.PortalDocID,
      attachId: data.AttachID,
    });
    if (Boolean(res?.data?.d)) {
      toast.success("Document removed");
      return "Done";
    } else {
      toast.error("Something went wrong please try again");
      return "Done";
    }

  } catch (err) {
    console.log('Error while calling ShareboxDocumentDeleted_Json', err);
  }
};

export const downloadShareboxDocument = (PortalDocID, AttachID, DocExtension, docName) => async (dispatch) => {
  try {
    let res;
    if(isClientLogin){
      res = await portalUser.post("/GetAttachmentNew_Json", {
        ...objForClient,
        messageId: PortalDocID,
        attachid: AttachID,
        extension: DocExtension
      });
    }else{
      res = await portalClient.post("/GetAttachment_Json", {
        ...portalUserObj,
        messageId: PortalDocID,
        attachid: AttachID,
        extension: DocExtension
      });
    }
    if (res?.data?.d && res?.data?.d !== "No Data Exist") {
      let ankr = document.createElement("a");
      ankr.href = `data:application/octet-stream;base64,${res?.data?.d}`;
      ankr.download = docName;
      ankr.click();
    }
  } catch (err) {
    console.log('Error while calling ShareboxDocumentDeleted_Json', err);
  }
};

export const getContactsListByFolder = (selectedFolder) => async (dispatch) => {
  try {
    const res = await practiceTest.post("/Json_GetContactListByFolder", {
      ...rqObj,
      intFolderId: selectedFolder.FolderID,
    });
        
    if (res?.data?.d) {
      const contacts = JSON.parse(res?.data?.d)?.Table;
      dispatch(setAllContacts(contacts));
    }
  } catch (err) {
    console.log("Error while calling GetShareboxDocuments_Json", err);
  }
};

export const getCRMContactUDFValues = (obj) => async (dispatch) => {
  try {
    const res = await docuSms.post("/Json_GetCRMContactUDFValues", {
      ...rqObj,
      ...obj,
    });
    if (res?.data?.d) {
      const result = JSON.parse(res?.data?.d);
      dispatch(setCRMContactUDFValues_Redux(result));
    }
  } catch (err) {
    console.log("Error while calling GetShareboxDocuments_Json", err);
  }
};

export const getAllSentMessages = (obj) => async (dispatch) => {
  try {
    const res = await portalClient.post("/Json_GetAllSentMessages", {
      ...rqObj,
      ...obj,
    });


    if (res?.data?.d) {
      const result = JSON.parse(res?.data?.d);
      console.log('Data hase beem Restore', result)
      dispatch(setAllMessagesContactsDetails_Redux(result));
    }
  } catch (err) {
    console.log("Error while calling GetShareboxDocuments_Json", err);
  }
};

export const getAllReceivedMessages = () => async (dispatch) => {
  try {
    const res = await portalClient.post("/Json_GetAllReceivedMessages", {
      ...rqObj,
      emailAddress: "",
      ccode: "originatorNo",
      folder: "folderid",
    });
    if (res?.data?.d) {
      const res = JSON.parse(res?.data?.d);
      // dispatch(setCRMContactUDFValues_Redux(res));
      console.log("Json_GetAllReceivedMessages", res);
    }
  } catch (err) {
    console.log("Error while calling GetShareboxDocuments_Json", err);
  }
};

export const portalUserAccountCreated_Json = (body) => async (dispatch) => {
  try {
    const res = await portalClient.post("/PortalUserAccountCreated_Json", {
      ...userBody_2,
      PresetMemorableData: true,
      IssueReminders: false,
      ExcludeMessageLink: true,
      KeepSignedIn: true,
      AllowUpload: true,
      ChangeProfile: true,
      LoggedIn: false,
      Blocked: false,
      // emailAddress: "contact_email",
      // ccode: "originator_no",
      // clientName: "company_name",
      ...body
    });
    if (res?.data?.d) {
      const res = JSON.parse(res?.data?.d);
      toast.success("Portal Account Created Successfully !");
      // dispatch(setCRMContactUDFValues_Redux(res));
      console.log("PortalUserAccountCreated_Json", res);
      return res;
    }
  } catch (err) {
    console.log("Error while calling GetShareboxDocuments_Json", err);
  }
};

export const portalUserAccountUpdated_Json = (obj) => async (dispatch) => {
  try {
    const res = await portalClient.post("/PortalUserAccountUpdated_Json", {
      ...portalUserObj,
      ...obj
    });
    if (res?.data?.d) {
      // toast.success("Portal account Blocked successfully!");
      // dispatch(setCRMContactUDFValues_Redux(res));
      console.log("portal api from redux", res?.data?.d);
    }
  } catch (err) {
    console.log("Error while calling GetShareboxDocuments_Json", err);
  }
};

export const getAllContactsByClientID =
  (client_id, folder_id) => async (dispatch) => {
    try {
      const res = await docuSms.post("/Json_GetAllContactsByClientID", {
        ...rqObj,
        ClientID: client_id,
        ProjectID: folder_id,
      });
      if (res?.data?.d) {
        const parsedData = JSON.parse(res?.data?.d).Table;
        dispatch(setAllContactsByClientID(parsedData));
      }
    } catch (err) {
      console.log("Error while calling GetShareboxDocuments_Json", err);
    }
  };

export const getSupplierContact = () => async (dispatch) => {
  try {
    const res = await docuSms.post("/SupplierContact", {
      ...rqObj,
      ClientID: "originatorNo",
      ProjectID: "folder_id",
    });
    if (res?.data?.d) {
      const res = JSON.parse(res?.data?.d);
      // dispatch(setCRMContactUDFValues_Redux(res));
      // if (data == "Success") {
      //     toast.error("Contact deleted Successfully !");
      //     setTimeout(() => {
      //       navigate("/dashboard/Connections");
      //       dispatch(fetchSupplierListOrderByFavourite(folderId));
      //     }, 1000);
      //   } else {
      //     toast.error("Deleting this contact is not allowed because it has attached documents. Please remove the attached documents before proceeding with the deletion.");
      //   }
      console.log("SupplierContact", res);
    }
  } catch (err) {
    console.log("Error while calling GetShareboxDocuments_Json", err);
  }
};

export const updateContactVerify = () => async (dispatch) => {
  try {
    const res = await docuSms.post("/Json_UpdateContactVerify", {
      ...rqObj,
      Contactemail: "",
      BnkAccNumber: "",
      BnkSrCode: "",
      DrvLicNumber: "",
      NatInsNumber: "",
      PassportNumber: "",
    });
    if (res?.data?.d) {
      const res = JSON.parse(res?.data?.d);
      console.log("Json_UpdateContactVerify", res);
    }
  } catch (err) {
    console.log("Error while calling GetShareboxDocuments_Json", err);
  }
};

export const getPortalUserInboxMessages = () => async (dispatch) => {
  try {

    if(LoginClient){
      let body = {
        ...clientBody,
        filterBy:"All"
      }
      const response = await portalUser.post('/PortalUsersInbox_Json',body)
      if (response?.data?.d) {
        const result = JSON.parse(response?.data?.d);
        dispatch(setPortalUserInboxMessage(result));
        dispatch(setAllPortalUserInboxMessage(result));
      } else {
        dispatch(setPortalUserInboxMessage([]));
        dispatch(setAllPortalUserInboxMessage([]));
      }
    }
    
    const response = await portalUser.post('/PortalUsersInbox_Json', {
      accId: localStorage.getItem("agrno") ? localStorage.getItem("agrno") : "0003",
      email: localStorage.getItem("Email") ? localStorage.getItem("Email") : "patrick@docusoft.net",
      password: localStorage.getItem("password") ? localStorage.getItem("password") : "UGF0cmljazEyMy4=",
      username: localStorage.getItem("EmailClient") ? localStorage.getItem("EmailClient") : "",
      sPassword: "0",
      filterBy: "all"
    });
    if (response?.data?.d) {
      const result = JSON.parse(response?.data?.d);
      dispatch(setPortalUserInboxMessage(result));
      dispatch(setAllPortalUserInboxMessage(result));
    } else {
      dispatch(setPortalUserInboxMessage([]));
      dispatch(setAllPortalUserInboxMessage([]));
    }
  } catch (err) {
    console.log("Error while calling PortalUsersInbox_Json", err);
  }
};


export const getPortalUsersInboxNew_Json = () => async (dispatch) => {
  try {
    const response = await portalUser.post('/PortalUsersInboxNew_Json', {
      accId: localStorage.getItem("accIdClient") ? localStorage.getItem("accIdClient") : "0003",
      username: localStorage.getItem("EmailClient") ? localStorage.getItem("EmailClient") : "patrick.docusoft@outlook.com",
      sPassword: localStorage.getItem("passwordClient") ? localStorage.getItem("passwordClient") : "UzBVQjFTS1Q=",
      filterBy: "All"
    });
    if (response?.data?.d) {
      const result = JSON.parse(response?.data?.d);
      dispatch(setPortalUserInboxMessage(result));
      dispatch(setAllPortalUserInboxMessage(result));
    } else {
      dispatch(setPortalUserInboxMessage([]));
      dispatch(setAllPortalUserInboxMessage([]));
    }
  } catch (err) {
    console.log("Error while calling PortalUsersInbox_Json", err);
  }
};


export const getMessageHtml = (portalDocId) => async (dispatch) => {
  try {
    let response;
    if(Boolean(localStorage.getItem('LoginClient'))){
      response = await portalUser.post('/GetMessageHtmlNew_Json', {
        ...objForClient,
        messageId: portalDocId
      });
    }else{
      response = await portalClient.post('/GetMessageHtml_Json', {
        ...portalUserObj,
        messageId: portalDocId
      });
    }
    if (response?.data?.d) {
      const result = response?.data?.d;
      dispatch(setInboxMessageHtml(result));
      dispatch(setInboxMessageHtmlLoading(false));
    }
  } catch (err) {
    console.log("Error while calling GetMessageHtml_Json", err);
  }
}

export const markMessageAsViewed = (portalDocId) => async (dispatch) => {
  try {
    const ipResponse = await axios.get('https://api.ipify.org/?format=json');

    if(ipResponse && ipResponse?.data && ipResponse.data?.ip){
      const response = await portalUser.post('/MarkMessageAsViewed_Json', {
        ...portalObj,
        username: localStorage.getItem("EmailClient") ? localStorage.getItem("EmailClient") : clientEmail,
        sPassword: localStorage.getItem("passwordClient") ? localStorage.getItem("passwordClient") : "",
        messageid: portalDocId ? portalDocId: "",
        ipAddress: ipResponse.data?.ip ? ipResponse.data?.ip : ""
      });
      if (response?.data?.d) {
        if (Boolean(response?.data?.d)) dispatch(getPortalUserInboxMessages());
      }
    }
  } catch (err) {
    console.log("Error while calling GetMessageHtml_Json", err);
  }
}

export const savePortalMessgeComment = (portalDocId, comment) => async (dispatch) => {
  try {
    let response = {data:{d:""}};
    if(Boolean(localStorage.getItem('LoginClient'))){
      response = await portalUser.post('/ResponseAddedNew_Json', {
        ...objForClient,
        messageID:portalDocId,
        response:comment
      });
    }else{
      response = await portalUser.post('/ResponseAdded_Json', {
        ...portalUserObj,
        messageID: portalDocId,
        response: comment
      });
    }
    if (Boolean(response?.data?.d)) {
      toast.success("Comment added successfully");
      dispatch(getPortalMessageComments(portalDocId));
      return;
    } else {
      toast.error("Something went wrong please try again");
    }
  } catch (err) {
    console.log("Error while calling ResponseAdded_Json", err);
  }
}

export const getPortalMessageDocuments = (portalDocId) => async (dispatch) => {
  try {
    let response;
    if(isClientLogin){
      response = await portalUser.post('/GetMessageDocumentsNew_Json', {
        ...objForClient,
        messageId: portalDocId
      });
    }else{
      response = await portalUser.post('/GetMessageDocuments_Json', {
        ...portalUserObj,
        messageId: portalDocId
      });
    }
    if (response?.data?.d) {
      let result = JSON.parse(response?.data?.d);
      dispatch(setPortalMessageDocuments(result));
      dispatch(setPortalMessageDocumentsLoading(false));
    } else {
      dispatch(setPortalMessageDocumentsLoading(false));
      dispatch(setPortalMessageDocuments([]));
    }
  } catch (err) {
    console.log("Error while calling ResponseAdded_Json", err);
  }
}

export const getPortalMessageComments = (portalDocId) => async (dispatch) => {
  try {
    let response;
    if(isClientLogin){
      response = await portalUser.post('/GetCommentsHtmlNew_Json', {
        ...objForClient,
        messageId: portalDocId
      });
    }else{
      response = await portalUser.post('/GetCommentsHtml_Json', {
        ...portalUserObj,
        messageId: portalDocId
      });
    }
    if (response?.data?.d) {
      dispatch(setPortalMessageComments(response?.data?.d));
    } else {
      dispatch(setPortalMessageComments(""));
    }
  } catch (err) {
    console.log("Error while calling GetCommentsHtml_Json", err);
  }
}

export const getApprovalCertificate = (portalDocId) => async (dispatch) => {
  try {
    const response = await portalUser.post('/GetCertificate_Json', {
      ...portalUserObj,
      messageId: portalDocId
    });
    if (response?.data?.d) {
      dispatch(setApprovalCertificateBase64(response?.data?.d));
    } else {
      dispatch(setApprovalCertificateBase64("undefined"));
    }
  } catch (err) {
    console.log("Error while calling GetCertificate_Json", err);
  }
}

export const getApprovalCommentsHtml = (portalDocId) => async (dispatch) => {
  try {
    let response;
    if(isClientLogin){
      response = await portalUser.post('/GetApprovalsAndCommentsHtmlNew_Json', {
        ...objForClient,
        messageId: portalDocId
      });
    }else{
      response = await portalUser.post('/GetApprovalsAndCommentsHtml_Json', {
        ...portalUserObj,
        messageId: portalDocId
      });
    }
    if (response?.data?.d) {
      dispatch(setPortalApprovalCommentsHtml(response?.data?.d));
    } else {
      dispatch(setPortalApprovalCommentsHtml(""));
    }
  } catch (err) {
    console.log("Error while calling GetCertificate_Json", err);
  }
}

export const getPortalSections = () => async (dispatch) => {
  try {
    const response = await portalUser.post('/GetPortalSections_Json', portalUserObj);
    if (response?.data?.d) {
      const result = JSON.parse(response?.data?.d);
      dispatch(setPortalSections(result));
    } else {
      dispatch(setPortalSections([]));
    }
  } catch (err) {
    console.log("Error while calling GetCertificate_Json", err);
  }
}

export const RequestStatusTotalCountByContact_Json = (username) => async (dispatch) => {
  try {
    // const response = await docuEngagerClient.post('/RequestStatusTotalCountByContact_Json',{
    //   ...portalObj,
    //   username: username ? username : "patrick.docusoft@outlook.com"
    // });
    const response = await portalUser.post('/RequestStatusTotalCountByContact_Json', {
      ...portalObj,
      username: username ? username : "patrick.docusoft@outlook.com"
    });
    if (response?.data?.d) {
      const result = JSON.parse(response?.data?.d);
      dispatch(setContactRequestDocCount(result));
    } else {
      dispatch(setContactRequestDocCount([]));
    }
  } catch (err) {
    console.log("Error while calling GetCertificate_Json", err);
  }
}

export const GetPortalInformationTotalByContact_Json = (username) => async (dispatch) => {
  try {
    const response = await docuEngagerClient.post('/GetPortalInformationTotalByContact_Json', {
      ...portalObj,
      username: username ? username : "patrick.docusoft@outlook.com"
    });
    if (response?.data?.d) {
      const result = JSON.parse(response?.data?.d);
      dispatch(setMessageReadUnreadCountDetails(result));
    } else {
      dispatch(setMessageReadUnreadCountDetails([]));
    }
  } catch (err) {
    console.log("Error while calling GetCertificate_Json", err);
  }
}

export const RequestStatusTotalCount_Json = () => async (dispatch) => {
  try {
    // const response = await docuEngagerClient.post('/RequestStatusTotalCount_Json',{
    //   ...portalObj
    // });
    const response = await portalUser.post('/RequestStatusTotalCount_Json', {
      ...portalObj
    });
    if (response?.data?.d) {
      const result = JSON.parse(response?.data?.d);
      console.log('RequestStatusTotalCount_Json', result);
      // dispatch(setMessageReadUnreadCountDetails(result));
    } else {
      // dispatch(setMessageReadUnreadCountDetails([]));
    }
  } catch (err) {
    console.log("Error while calling GetCertificate_Json", err);
  }
}

export const Json_Portal_GetClientListByEmail = () => async (dispatch) => {
  try {
    const response = await practiceTest.post('/Json_Portal_GetClientListByEmail', {
      agrno: "0003",
      ContactEmail: "dbeckhamportal@gmail.com"
    });
    if (response?.data?.d) {
      const result = JSON.parse(response?.data?.d);
      console.log('Json_Portal_GetClientListByEmail', result);
      // dispatch(setMessageReadUnreadCountDetails(result));
    } else {
      // dispatch(setMessageReadUnreadCountDetails([]));
    }
  } catch (err) {
    console.log("Error while calling GetCertificate_Json", err);
  }
}

export const Json_GetSupplierListByProject = (selectedFolder) => async (dispatch) => {
  try {
    const response = await practiceTestOnlyForTesting.post('/Json_GetSupplierListByProject', {
      ...rqObj,
      ProjectId: selectedFolder ? selectedFolder.toString() : "15"
    });
    if (response?.data?.d) {
      const result = JSON.parse(response?.data?.d);
      // console.log('Json_GetSupplierListByProject', result.Table);
      dispatch(setClientListByProjectId(result.Table));
      // dispatch(setMessageReadUnreadCountDetails(result));
    } else {
      // dispatch(setMessageReadUnreadCountDetails([]));
    }
  } catch (err) {
    console.log("Error while calling GetCertificate_Json", err);
  }
}

