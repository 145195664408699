import React, { useCallback, useState } from 'react'
import DataGrid, {
  Column,
  DataGridTypes,
  Grouping,
  GroupPanel,
  Pager,
  Paging,
  Scrolling,
  SearchPanel,
} from 'devextreme-react/data-grid';
import { useNavigate } from 'react-router-dom';
const pageSizes = [10, 25, 50, 100];

export default function CompanyGrid({ dataSourceOptions })
{
  const navigate = useNavigate();
 
  const [collapsed, setCollapsed] = useState(true);
  const onContentReady = useCallback((e) =>
  {
    if (collapsed) {
      e.component.expandRow(['EnviroCare']);
      setCollapsed(false);
    }
  }, [collapsed]);

  const handleRowDoubleClick = (target) => {
    navigate(`/user/client-details?OrgNo=${target.data.OriginatorNo}`);
  }

 
  return (
    <div>
      <DataGrid
      dataSource={(dataSourceOptions && dataSourceOptions.length > 0 ) ? dataSourceOptions:[] }
      allowColumnReordering={true}
      rowAlternationEnabled={true}
      showBorders={true}
      className='table-grid table-width'
      columnAutoWidth={true}
      // width="75vw"
      onRowDblClick={handleRowDoubleClick}
    // onContentReady={onContentReady}
    >
      <Scrolling columnRenderingMode="virtual" />
      {/* <GroupPanel visible={true} /> */}
      <SearchPanel visible={true} highlightCaseSensitive={true} />
      {/* <Grouping autoExpandAll={false} /> */}

      {/* <Column
        dataField="Company Name"
        caption="Company Name"
        dataType="string"
      />
      <Column
        dataField="First Name"
        caption="First Name"
        dataType="string"
      />
      <Column
        dataField="Last Name"
        caption="Last Name"
        dataType="string"
      />
      <Column
        dataField="E-Mail"
        caption="E-Mail"
        dataType="string"
      /> */}

      <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
      <Paging defaultPageSize={10} />
    </DataGrid>
    </div>
  )
}
