import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    viewHistoryData: [],
    GetUserDetails:[],
    RequestDocumentData:[],
    RecentRequests: [],
    AllPendingRequest: {}
};

const dev_sonam_slice = createSlice({
    name: 'Docu_History',
    initialState,
    reducers: {
        setviewHistoryData: (state,action)=>{
            state.viewHistoryData = action.payload;
        },
        setGetUserDetails: (state,action)=>{
            state.GetUserDetails = action.payload;
        },
        setRequestDocumentData: (state,action)=>{
            state.RequestDocumentData = action.payload;
        },
        setRecentRequests: (state,action)=>{
            state.RecentRequests = action.payload;
        },
        setAllPendingRequest: (state,action)=>{
            state.AllPendingRequest = action.payload;
        }
    }
});

export const { setviewHistoryData,setGetUserDetails,setRequestDocumentData ,setAllPendingRequest} = dev_sonam_slice.actions

export default dev_sonam_slice.reducer