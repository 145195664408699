import { useSelector } from "react-redux"
import DataNotFound from "../../components/DataNotFound";
import DataGrid, {
    Column,
    Pager,
    Paging,
    SearchPanel,
} from 'devextreme-react/data-grid';
import { NavLink } from "react-router-dom";
import { Button } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';

const pageSizes = [10, 25, 50, 100];

export default function RequestedDocs({Email}){
    console.log("tested email", Email)  ;
    
    const allRequests = useSelector(state=>state.docu.documentRequests);
    const filteredReqs = allRequests.length>0 ? allRequests.filter(itm => itm.EmailId === Email) : [];
    
    return <>
     {filteredReqs.length>0 ? 
        (<DataGrid
        dataSource={filteredReqs}
        allowColumnReordering={true}
        rowAlternationEnabled={true}
        showBorders={true}
        className='table-grid'

    >
        <SearchPanel visible={true} highlightCaseSensitive={true} />
        <Column
            dataField="Request"
            caption="Request"
            dataType="string"
        />
        <Column
            dataField="EmailId"
            caption="Email"
            dataType="string"

        />
        <Column
            dataField="Individual"
            caption="Individual"
            dataType="string"
        />
        <Column
            dataField="DocTempId"
            caption="Action"
            cellRender={(data) => {
                return <NavLink to={`/user/all-invites?id=${data.data.DocTempId}&request=${data.data.Request}&EmailId=${data.data.EmailId}`}><Button className="btn-blue" startIcon={<VisibilityIcon />}>View Detail</Button></NavLink>
            }}
        />
        <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
        <Paging defaultPageSize={10} />
    </DataGrid>)
     : <DataNotFound/>}
    </>
}