import { configureStore } from "@reduxjs/toolkit";

import docuReducer from './docuSlice'
import modalReducer from './modalSlice'
import dev_chetan_reducer from './dev_chetan_slice';
import dev_sonam_reducer from './dev_sonam_slice';
import akReducer from './akSlice'
import adityaReducer from "./adityaReducer"

export default configureStore({
    reducer: {
        docu: docuReducer,
        modals: modalReducer,
        dev_chetan: dev_chetan_reducer,
        dev_sonam: dev_sonam_reducer,
        ak: akReducer,
        aditya:adityaReducer
    }
})